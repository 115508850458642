.is-ready {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.5s $ease;
}

.is-active {
  opacity: 1;
  transform: translateY(0);
}

.u-headline-lv1 {
  position: relative;
  margin-bottom: 80px;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  @include onlyMobile {
    font-size: 23px;
    margin-bottom: 60px;
  }
  &:before {
    position: absolute;
    bottom: -35px;
    left: 50%;
    display: block;
    content: "";
    width:  100px;
    height: 5px;
    margin-left: -50px;
    border-radius: 10px;
    background: $green;
    @include onlyMobile {
      width:  60px;
      margin-left: -30px;
      bottom: -30px;
    }
  }
  &-small {
    font-size: 30px;
    @include onlyMobile {
      font-size: 20px;
    }
  }
}

.u-headline-voice {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  @include onlyMobile {
    flex-direction: column;
    margin-bottom: 30px;
    font-size: 23px;
  }
  &-image {
    width: 150px;
    line-height: 0;
    @include onlyMobile {
      margin-bottom: 10px;
    }
    img {
      position: relative;
      border-radius: 50%;
    }
    span {
      position: relative;
      display: inline-block;
      padding: 8px 20px;
      margin-top: -10px;
      font-size: 13px;
      background: $green;
      color: #fff;
      border-radius: 20px;
      line-height: 1;
      z-index: 1;
      strong {
        font-size: 17px;
      }
    }
  }
  &-text {
    padding-left: 20px;
    text-align: left;
    @include onlyMobile {
      padding-left: 0;
    }
  }
}

.u-headline-lv2 {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  @include onlyMobile {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.u-headline-lv3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

.u-list-icon {
  ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include onlyMobile {
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
    li {
      position: relative;
      width:  16%;
      @include onlyMobile {
        width:  46%;
        margin-bottom: 30px;
      }
      &:nth-child(1) {
        margin-top: -60px;
        @include onlyMobile {
          margin-top: 0;
        }
      }
      &:nth-child(3) {
        margin-top: 25px;
        @include onlyMobile {
          margin-top: 0;
        }
      }
      &:nth-child(5) {
        margin-top: -60px;
        @include onlyMobile {
          margin-top: 0;
        }
      }
      span {
        position: relative;
        display: block;
        width: 120px;
        padding: 8px 20px;
        margin: -20px auto 0;
        text-align: center;
        background: $greenlight;
        border-radius: 50px;
        line-height: 1.4;
        font-weight: 700;
        z-index: 10;
        &.large {
          width: 100%;
          font-size: 13px;
        }
      }
    }
  }
  &-image {
    img {
      border-radius: 50%;
    }
  }
  &-text {
    margin-top: 10px;
    font-size: 13px;
  }
}

.u-box-greenlight {
  max-width: 1000px;
  padding: 45px;
  margin: 0 auto;
  border: solid 5px $greenlight;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0,0,0,0.12);
  z-index: -1;
  @include onlyMobile {
    padding: 30px;
  }
}

.u-box-white {
  position: relative;
  max-width: 1000px;
  padding: 45px;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0,0,0,0.12);
  overflow: hidden;
  z-index: -1;
  @include onlyMobile {
    padding: 30px;
  }
}

.u-figure-01 {
  position: absolute;
  top: -10vw;
  right: 0;
  display: block;
  width: 50Vw;
  height: 50vw;
  z-index: -10;
  overflow-x: hidden;
  @include onlyMobile {
    width: 90Vw;
    height: 90vw;
  }
  &:before {
    position: absolute;
    top: 0;
    right: -10vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: $greenlight4;
    border-radius: 50%;
    filter: blur(15px);
  }
}

.u-figure-02 {
  position: absolute;
  bottom: -10vw;
  left: 0;
  display: block;
  width: 55Vw;
  height: 55vw;
  z-index: -10;
  overflow-x: hidden;
  @include onlyMobile {
    width: 95Vw;
    height: 95vw;
    bottom: -20vw;
  }
  &:before {
    position: absolute;
    bottom: 0;
    left: -25vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: $greenlight3;
    border-radius: 50%;
    filter: blur(5px);
  }
}

.u-figure-03 {
  position: absolute;
  top: -10vw;
  left: 0;
  display: block;
  width: 50Vw;
  height: 50vw;
  z-index: -10;
  overflow-x: hidden;
  @include onlyMobile {
    width: 90Vw;
    height: 90vw;
    left: -10vw;
  }
  &:before {
    position: absolute;
    top: 0;
    left: -10vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: $greenlight4;
    border-radius: 50%;
    filter: blur(15px);
  }
}

.u-figure-04 {
  position: absolute;
  bottom: -15vw;
  right: 0;
  display: block;
  width: 55Vw;
  height: 55vw;
  z-index: -10;
  overflow-x: hidden;
  @include onlyMobile {
    width: 95Vw;
    height: 95vw;
    bottom: -20vw;
  }
  &:before {
    position: absolute;
    bottom: 0;
    right: -10vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: $greenlight3;
    border-radius: 50%;
    filter: blur(5px);
  }
}

.u-figure-05 {
  position: absolute;
  top: -45vw;
  left: 40%;
  display: block;
  width: 80vw;
  height: 80vw;
  background: $redlight;
  border-radius: 50%;
  filter: blur(12px);
  z-index: -10;
  @include onlyMobile {
    width: 95Vw;
    height: 95vw;
    top: -25vw;
  }
}

.u-box-image-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include onlyMobile {
    flex-direction: column;
  }
  &-detail {
    width: 30%;
    @include onlyMobile {
      width: 100%;
      margin-bottom: 15px;
    }
    &-icons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%;
        i {
          font-size: 120px;
          color: $green;
          line-height: 1;
        }
        span {
          font-size: 20px;
          font-weight: bold;
          color: $green;
          @include onlyMobile {
            font-size: 17px;
          }
        }
      }
    }
    &-note {
      position: relative;
      margin-top: 20px;
      padding: 20px;
      background: $green;
      color: #fff;
      border-radius: 15px;
      font-weight: 700;
      &:before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        margin-left: -10px;
        border: 10px solid transparent;
        border-bottom: 10px solid $green;    
      }
    }
  }
  &-text {
    width: 67%;
    @include onlyMobile {
      width: 100%;
    }
  }
}

.u-box-image-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include onlyMobile {
    flex-direction: column;
  }
  &-detail {
    width: 30%;
    @include onlyMobile {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  &-text {
    width: 67%;
    @include onlyMobile {
      width: 100%;
    }
  }
  &-small {
    .u-box-image-right-text {
      width: 80%;
      @include onlyMobile {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .u-box-image-right-detail {
      width: 14%;
      @include onlyMobile {
        width: 100%;
        text-align: center;
      }
      img {
        @include onlyMobile {
          max-width: 140px;
          margin: 0 auto;
        }
      }
    }
  }
}

.u-box-col2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include onlyMobile {
    flex-direction: column;
  }
  &-item {
    width: 47%;
    @include onlyMobile {
      width: 100%;
    }
    &:first-of-type {
      @include onlyMobile {
        margin-bottom: 15px;
      }
    }
  }
  &-reverse {
    flex-direction: row-reverse;
    @include onlyMobile {
      flex-direction: column;
    }
  }
}

.u-image-around {
  margin: 0;
  img {
    border-radius: 20px;
  }
}

.u-image-caption {
  display: block;
  margin-top: 10px;
  text-align: right;
  font-size: 13px;
}

.u-align-center {
  text-align: center;
}

.u-flow-list {
  ul {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      content: "";
      display: block;
      width: 10px;
      height: 100%;
      margin-left: -5px;
      background: #f1eb9b;
    }
    li {
      position: relative;
      margin-bottom: 30px;
      text-align: center;
      z-index: 10;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-item {
    display: inline-block;
    padding: 10px 30px;
    background: $green;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    border-radius: 30px;
    @include onlyMobile {
      font-size: 15px;
      border-radius: 50px;
    }
    span {
      position: relative;
      top: -2px;
      display: inline-block;
      text-align: center;
      width: 17px;
      height: 17px;
      margin-right: 5px;
      font-size: 13px;
      color: $green;
      background: #fff;
      border-radius: 50%;
      line-height: 17px;
    }
    &-red {
      background: $red;
      span {
        color: $red;
      }
    }
  }
}

.u-box-origin {
  display: flex;
  justify-content: space-between;
  @include onlyMobile {
    flex-direction: column;
    align-items: center;
  }
  &-image {
    width: 30%;
    justify-content: space-between;
    @include onlyMobile {
      width: 60%;
      margin-bottom: 15px;
    }
    &-photo {
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      &-name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background: $green;
        text-align: center;
        font-weight: 700;
        color: $white;
        small {
          display: inline-block;
          margin-left: 10px;
          font-weight: 400;
        }
      }
    }
  }
  &-text {
    width: 66%;
    @include onlyMobile {
      width: 100%;
    }
  }
}

.u-button {
  position: relative;
  display: inline-block;
  min-width: 240px;
  padding: 15px 40px;
  background: $red;
  color: $white;
  font-weight: 700;
  border-radius: 30px;
  transition: all 0.5s $ease;
  cursor: pointer;
  @include onlyMobile {
    border-radius: 60px;
    font-size: 13px;
  }
  &:hover {
    text-decoration: none;
    background: $redhover;
  }
  &:before {
    position: absolute;
    top: 50%;
    right: 20px;
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    margin-top: -3.5px;
    border-top: solid 2px $white;
    border-right: solid 2px $white;
    transform: rotate(45deg);
  }
  &-small {
    padding: 10px 20px;
    min-width: 140px;
  }
  &-disable {
    background: $gray;
    pointer-events: none;
  }
}

.u-list-voice {
  max-width: 1080px;
  margin: 0 auto;
  &-item {
    display: flex;
    align-items: flex-start;
    width: 83%;
    margin-top: 30px;
    margin-bottom: 20px;
    @include onlyMobile {
      width: 100%;
    }
    &-photo {
      width: 14%;
      line-height: 1;
      text-align: center;
      @include onlyMobile {
        width: 25%;
      }
      img {
        border-radius: 50%;
      }
      &-name {
        position: relative;
        display: block;
        width: 100%;
        padding: 8px 15px;
        margin: -20px auto 0;
        text-align: center;
        background: #f1eb9b;
        border-radius: 50px;
        font-size: 12px;
        line-height: 1.4;
        font-weight: 700;
        z-index: 10;
      }
    }
    &-text {
      position: relative;
      flex: 1;
      padding: 20px 30px;
      margin-left: 30px;
      background: $green;
      color: $white;
      border-radius: 20px;
      font-weight: 700;
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.15);
      font-size: 17px;
      @include onlyMobile {
        padding: 15px;
        font-size: 15px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 30px;
        left: -20px;
        margin-top: -10px;
        border: 10px solid transparent;
        border-right: 10px solid $green;
        @include onlyMobile {
          top: 35px;
          margin-top: -15px;
        }
      }
    }
    &-reverse {
      flex-direction: row-reverse;
      margin-left: 17%;
      @include onlyMobile {
        margin-left: 0;
      }
      .u-list-voice-item-text {
        margin-left: 0;
        margin-right: 20px;
        background: $white;
        color: $black;
        font-weight: 400;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -10px;
          border: 10px solid transparent;
          border-left: 10px solid $white;
          @include onlyMobile {
            top: 35px;
          }
        }
      }
    }
  }
}

.u-box-point {
  ul {
    display: flex;
    justify-content: space-between;
    @include onlyMobile {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23%;
      padding: 20px;
      background: #f1eb9b;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      border-radius: 20px;
      @include onlyMobile {
        width: 48%;
        padding: 15px;
        margin-bottom: 15px;
        font-size: 15px;
      }
    }
  }
}

.u-box-treatment {
  &-item {
    position: relative;
    margin-bottom: 60px;
    padding-bottom: 60px;
    @include onlyMobile {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 5px;
      background: $graylight;
    }
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
    &-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      @include onlyMobile {
        flex-direction: column;
        margin-bottom: 15px;
      }
      &-image {
        position: relative;
        width: 32%;
        line-height: 1;
        border-radius: 20px;
        overflow: hidden;
        @include onlyMobile {
          width: 100%;
          margin-bottom: 15px;
        }
        img {
          border-radius: 20px;
        }
        &-label {
          position: absolute;
          top: 0;
          left: 0;
          padding: 15px 30px;
          background: $green;
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          border-bottom-right-radius: 10px;
        }
      }
      &-detail {
        width: 64%;
        @include onlyMobile {
            width: 100%;
        }
      }
    }
    &-answer {
      position: relative;
      max-width: 1000px;
      margin: 0 auto;
      &-image {
        margin-bottom: -50px;
        text-align: center;
        img {
          display: inline;
          width: 130px;
          height: 130px;
          border-radius: 50%;
          border: solid 10px $green;
          @include onlyMobile {
            width: 100px;
            height: 100px;
            border: solid 7px $green;
          }
        }
      }
      &-detail {
        padding: 80px 40px 40px 40px;
        background: $green;
        color: $white;
        border-radius: 20px;
        font-size: 17px;
        font-weight: 700;
        @include onlyMobile {
          padding: 50px 20px 20px 20px;
          font-size: 15px;
        }
      }
    }
  }
}

.u-table {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  line-height: 1.4;
  @include onlyMobile {
    font-size: 13px;
  }
  th {
    padding: 15px 15px;
    background: $greenlight;
    border-top: none !important;
    border-left: none !important;
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff;
    @include onlyMobile {
      padding: 10px;
    }
    &.u-table-th-width140 {
      width: 140px;
    }
    &.u-table-th-width160 {
      width: 160px;
    }
    &.u-table-th-width-per30 {
      width: 30%;
    }
    &.u-table-th-green {
      background: $green;
      color: $white;
    }
  }
  td {
    padding: 10px 15px;
    border-top: none !important;
    border-left: none !important;
    @include onlyMobile {
      padding: 10px;
    }
    background: $graylight;
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff;
    &.u-table-td-greenlight {
      background: $greenlight;
      font-weight: bold;
    }
    &.u-table-td-gray {
      background: $gray;
      font-weight: bold;
      color: $white;
    }
    &.u-table-td-noborder-bottom {
      border-bottom: none;
    }
    &.u-table-td-border-right {
      border-right: 2px solid #fff !important;
    }
  }
  tr {
    th {
      &:last-of-type {
        border-right: none;
      }
    }
    td {
      &:last-of-type {
        border-right: none;
      }
    }
    &:last-of-type {
      th {
        border-bottom: none;
      }
      td {
        border-bottom: none;
      }
    }
  }
  .u-button-small {
    min-width: 160px;
  }
}

.u-flow {
  &-head {
    position: sticky;
    top: 96px;
    z-index: 20;
    ul {
      position: relative;
      display: flex;
      background: $white;
      border-radius: 20px;
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
      &:after {
        position: absolute;
        top: 0;
        left: 50%;
        content: "";
        display: block;
        width: 10px;
        height: 100%;
        margin-left: -5px;
        background: $greenlight;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding: 20px;
        font-weight: 700;
        @include onlyMobile {
          padding: 10px;
        }
      }
    }
    &-image {
      width: 15%;
      @include onlyMobile {
        width: 35%;
        text-align: center;
        img {
          margin: 0 auto;
        }
      }
    }
    &-detail {
      padding-left: 20px;
      @include onlyMobile {
        padding-left: 5px;
      }
      span {
        padding: 7px 20px;
        background: $green;
        color: $white;
        font-size: 17px;
        font-weight: 700;
        border-radius: 10px;
        @include onlyMobile {
          padding: 3px 10px;
          font-size: 12px;
        }
      }
      h3 {
        margin-top: 5px;
        font-size: 25px;
        font-weight: 700;
        @include onlyMobile {
          margin-top: 0;
          font-size: 12px;
          line-height: 1.4;
        }
        br {
          display: none;
          @include onlyMobile {
            display: inline;
          }
        }
      }
    }
  }
  &-body {
    position: relative;
    padding-top: 40px;
    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      content: "";
      display: block;
      width: 10px;
      height: 100%;
      margin-left: -5px;
      background: $greenlight;
    }
    &-item {
      position: relative;
      max-width: 960px;
      margin: 0 auto 40px;
      z-index: 10;
      &:last-of-type {
        margin-bottom: 0;
      }
      &-split {
        &:after {
          position: absolute;
          top: 0;
          left: 50%;
          content: "";
          display: block;
          width: 10px;
          height: 100%;
          margin-left: -5px;
          background: $greenlight;
        }
      }
      &-time {
        position: absolute;
        top: 0;
        right: 20px;
        font-size: 60px;
        font-weight: 700;
        z-index: 10;
        color: $greenlight;
        line-height: 1;
        @include onlyMobile {
          top: 5px;
          right: 0;
          font-size: 30px;
        }
        small {
          font-size: 20px;
        }
        &-small {
          top: 18px;
          font-size: 23px;
          @include onlyMobile {
            width: 100%;
            top: 55px;
            font-size: 17px;
            text-align: center;
          }
          br {
            display: none;
            @include onlyMobile {
              display: inline;
            }
          }
        }
      }
      &-time-left {
        position: absolute;
        top: 0;
        left: 20px;
        font-size: 60px;
        font-weight: 700;
        z-index: 10;
        color: $greenlight;
        line-height: 1;
        @include onlyMobile {
          top: 5px;
          left: 0;
          font-size: 30px;
        }
        small {
          font-size: 20px;
        }
      }
      &-head {
        position: relative;
        margin-bottom: -24px;
        text-align: center;
        z-index: 10;
        span {
          display: inline-block;
          padding: 10px 20px;
          background: $green;
          color: $white;
          font-size: 20px;
          font-weight: bold;
          border-radius: 30px;
          @include onlyMobile {
            padding: 7px 15px;
            font-size: 15px;
          }
        }
      }
      &-body {
        position: relative;
        padding: 40px;
        background: $white;
        border-radius: 20px;
        @include onlyMobile {
          padding: 40px 15px 15px;
        }
        .u-mb-xsmall {
          @include onlyMobile {
            font-size: 13px;
          }
        }
        .u-font-middle {
          @include onlyMobile {
              font-size: 17px;
              line-height: 1.4;
          }
        }
        &-col2 {
          display: flex;
          justify-content: space-between;
          &-item {
            width: 44%;  
          }
        }
      }
      &-option {
        text-align: center;
        span {
          display: inline-block;
          width: 100px;
          height: 100px;
          padding-top: 15px;
          border-radius: 50%;
          background: $green;
          color: $white;
          line-height: 1.2;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          strong {
            display: block;
            font-size: 40px;
          }
        }
      }
    }
  }
}

.u-flow-body-item-time-small + .u-flow-body-item-body {
  padding-top: 65px;
}

.u-image-col2 {
  display: flex;
  justify-content: space-between;
  &-item {
    width: 48%;
  }
}

.u-list-video {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px;
    li {
      width: 31%;
      margin: 0 3.5% 40px 0;    
      &:nth-child(3n) {
        margin-right: 0;
      }
      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        border-radius: 15px;
        box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
      }
    }
  }
  &-wrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
}

.u-voice {
  display: flex;
  flex-wrap: wrap;
  @include onlyMobile {
    flex-direction: column;
  }
  &-item {
    position: relative;
    width: 48%;
    margin: 0 4% 30px 0;
    color: #515151;
    @include onlyMobile {
      width: 100%;
      margin-bottom: 30px;
    }
    &:before {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      content: "";
      width: 80px;
      height: 80px;
      background: url(/assets/img/voice-detail.png) no-repeat;
      background-size: cover;
      z-index: 10;
    }
    &:last-of-type {
      @include onlyMobile {
        margin-bottom: 0;
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:hover {
      text-decoration: none;
    }
    &-detail {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background: $white;
      border-radius: 20px;
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
      z-index: 0;
      @include onlyMobile {
        flex-direction: column;
      }
      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -15px;
        border: 15px solid transparent;
        border-top: 15px solid $white;
      }
      &-image {
        position: relative;
        width: 48%;
        padding: 0;
        border-radius: 10px;
        line-height: 1;
        overflow: hidden;
        border: none;
        @include onlyMobile {
          width: 100%;
          margin-bottom: 15px;
        }
        span {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          padding: 10px;
          background: rgba(111,185,155,0.85);
          color: $white;
          text-align: center;
          font-weight: 700;
          transition: all 0.3s $ease;
          text-decoration: none;
          cursor: pointer;
          i {
            position: relative;
            top: 2px;
            margin-right: 5px;
            font-size: 20px;
          }
          &:hover {
            background: rgba(111,185,155,1);
          }
        }
      }
      &-body {
        width: 48%;
        @include onlyMobile {
          width: 100%;
        }
        &-name {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px $graylight;
          &-photo {
            width: 50px;
            height: 50px;
          }
          span {
            flex: 1;
            padding-left: 10px;
          }
        }
        &-pregnancy {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px $graylight;
        }
        &-list {
          &-box {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px $graylight;
            &:last-of-type {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
            dt {
              width: 80px;
              padding: 3px 5px;
              background: $green;
              color: $white;
              border-radius: 5px;
              text-align: center;
              font-size: 13px;
              font-weight: 700;
            }
            dd {
              flex: 1;
              padding-left: 10px;
              line-height: 1.4;
            }
            &-category {
              span {
                &:after {
                  content: "、";
                }
                &:last-of-type {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .u-list-voice-item {
      width: 100%;
      &-photo {
        width: 20%;
        @include onlyMobile {
          width: 25%;
        }
      }
    }
  }
  &-lower {
    .u-voice-item-detail {
      &:before {
        display: none;
      }
    }
  }
  &-single {
    display: block;
    .u-voice-item {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      &:before {
        display: none;
      }
      &-detail {
        flex-direction: column;
        &-image {
          width: 100%;
          margin-bottom: 30px;
          padding-bottom: 30px;
        }
        &-body {
          width: 100%;
          &-list {
            display: flex;
            flex-wrap: wrap;
            @include onlyMobile {
              flex-direction: column;
            }
            &-box {
              width: 50%;
              padding-right: 15px;
              @include onlyMobile {
                width: 100%;
                padding-right: 0;
              }
              &:last-of-type {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #f1f1f1;
              }
            }
          }
        }
      }
    }
    .u-list-voice-item {
      @include onlyMobile {
        flex-direction: column;
      }
      &-photo {
        @include onlyMobile {
          width: 50%;
          margin: 0 auto 20px;
        }
      }
      &-text {
        @include onlyMobile {
          margin-left: 0;
        }
        &:before {
          @include onlyMobile {
            top: -5px;
            left: 50%;
            margin-left: -10px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  &-scroll {
    @include onlyDesktop {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch; 
      margin-bottom: 60px;
      @include onlyTablet {
        flex-direction: column;
      }
    }
    .u-voice-item {
      @include onlyDesktop {
        display: inline-block;
        width: 580px;
        vertical-align: top;
        margin: 0 30px 0 0 ;
      }
      @include onlyTablet {
        flex-direction: column;
      }
    }
    .u-voice-item-detail {
      @include onlyDesktop {
        margin-bottom: 0;
      }
      @include onlyTablet {
        margin-bottom: 0;
        align-items:flex-start;
      }
      &:before {
        display: none;
      }
    }
  }
}

.u-box-col3-border {
  display: flex;
  @include onlyMobile {
    flex-direction: column;
  }
  &-item {
    width: 33.33333333%;
    padding: 0 30px;
    border-right: solid 2px $graylight;
    @include onlyMobile {
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-right: none;
      border-bottom: solid 2px $graylight;
    }
    &:last-of-type {
      border-right: none;
      @include onlyMobile {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.u-list-ranking {
  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  &-number {
    width: 24px;
    height: 24px;
    background: $green;
    color: $white;
    border-radius: 50%;
    line-height: 24px;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
  }
  &-text {
    flex: 1;
    padding-left: 5px;
    line-height: 1.4;
  }
}

.u-box-price {
  display: flex;
  justify-content: space-between;
  @include onlyMobile {
    flex-direction: column;
  }
  &-detail {
    width: 65%;
    @include onlyMobile {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  &-image {
    width: 31%;
    @include onlyMobile {
      width: 100%;
    }
  }
}

.u-list-plan {
  &-item {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: solid 3px $graylight;
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    &-name {
      @include onlyMobile {
        margin-bottom: 15px;
        text-align: center;
      }
      span {
        display: inline-block;
        padding: 7px 30px;
        font-size: 20px;
        font-weight: bold;
        background: $green;
        color: $white;
        border-radius: 30px;
        @include onlyMobile {
          padding: 5px 20px;
          font-size: 17px;
        }
      }
    }
  }
}

.u-list-faq {
  ul {
    li {
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: solid 3px $white;
      @include onlyMobile {
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &-question {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &-icon {
      position: relative;
      top: -2px;
      width: 40px;
      font-size: 35px;
      font-weight: 700;
      color: $green;
      line-height: 1;
    }
    &-text {
      flex: 1;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
    }
  }
  &-answer {
    padding-left: 40px;
  }
  &-archive {
    ul {
      li {
        border-bottom: solid 3px $graylight;
      }
    }
  }
}

.u-email {
  font-size: 17px;
  @include onlyMobile {
    font-size: 13px;
  }
  i {
    position: relative;
    top: 5px;
    font-size: 30px;
    @include onlyMobile {
      font-size: 25px;
    }
  }
}

.u-box-caution {
  padding: 20px;
  border-radius: 10px;
  background: $redlight;
}

.u-list-circle {
  li {
    position: relative;
    padding-left: 15px;
    &:before {
      position: absolute;
      top: 0.7em;
      left: 3px;
      display: block;
      content: "";
      width: 4px;
      height: 4px;
      background: $black;
      border-radius: 50%;
    }
  }
}

.u-map {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
}

.u-video-map {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px;
    box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
  }
}

.u-list-image {
  ul {
    display: flex;
    justify-content: space-between;
    @include onlyMobile {
      flex-direction: column;
    }
    li {
      width: 31%;
      @include onlyMobile {
        width: 100%;
        margin-bottom: 15px;
      }
      &:last-of-type {
        @include onlyMobile {
          margin-bottom: 0;
        }
      }
      img {
        border-radius: 20px;
        box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
      }
    }
  }
}

.u-list-news {
  ul {
    li {
      border-bottom: solid 2px $white;
      a {
        display: flex;
        padding: 20px;
        line-height: 1.6;
        transition: all 0.3s $ease;
        @include onlyMobile {
          padding: 15px 0;
          flex-direction: column;
        }
        &:hover {
          background: $greenlight3;
          text-decoration: none;
        }
      }
    }
  }
  &-date {
    width: 150px;
    color: $black;
  }
  &-title {
    flex: 1;
    font-weight: 700;
  }
  &-lower {
    ul {
      li {
        border-bottom: solid 2px $graylight;
      }
    }
  }
}

.u-box-image {
  display: inline-block;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
}

.u-table-scroll {
  width: 100%;
  overflow-x: auto;
}

.u-banner-fixed {
  position: sticky;
  bottom: 0;
  z-index: 500;
  @include onlyTablet {
    display: none;
  }
  &-box {
    display: flex;
    justify-content: center;
    font-size: 13px;
    &-left {
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
      ul {
        display: flex;
        height: 100%;
        background: rgba(255,255,255,0.9);
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 10px 20px;
          border-right: dotted 2px #fff;
          background: $green;
          color: $white;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.4;
          text-align: center;
          strong {
            font-size: 17px;
          }
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      background: rgba(255,255,255,0.9);
      font-weight: bold;
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
      p {
        line-height: 1.6;
      }
      .u-font-small {
        font-size: 11px;
      }
      .u-font-large {
        font-size: 15px;
      }
      &-button {
        margin-left: 10px;
        padding-left: 10px;
        border-left: solid 2px $graylight;
        a {
          display: inline-flex;
          align-items: center;
          padding: 8px;
          background: $red;
          border-radius: 5px;
          line-height: 1;
          color: $white;
          transition: all 0.5s $ease;
          &:hover {
            text-decoration: none;
            background: $redhover;
          }
          img {
            width: 30px;
          }
        }
        &-image {
          padding: 5px;
          border-radius: 5px;
          background: $white;
        }
        &-text {
          padding: 0 0 0 5px;
        }
      }
    }
  }
  &-ranking {
    position: absolute;
    bottom: 10px;
    left: 15px;
    z-index: 500;
    a {
      display: block;
      width: 90px;
      height: 60px;
      padding-top: 7px;
      background: $green;
      color: $white;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.3);
      transition: all 0.5s $ease;
      &:hover {
        box-shadow: 0 0 5px -2px rgba(0,0,0,0.12);
        background: $greenhover;
        text-decoration: none;
      }
    }
    &-icon {
      display: block;
      margin-bottom: 10px;
      text-align: center;
      font-size: 25px;
      line-height: 0;
    }
    &-text {
      display: block;
      font-size: 10px;
      line-height: 0;
    }
  }
}

.u-pagetop {
  position: absolute;
  bottom: 10px;
  right: 15px;
  z-index: 500;
  a {
    display: block;
    width: 60px;
    height: 60px;
    background: $green;
    color: $white;
    border-radius: 50%;
    box-shadow: 0 0 20px -5px rgba(0,0,0,0.3);
    transition: all 0.5s $ease;
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      content: "";
      width: 15px;
      height: 15px;
      border-top: solid 2px $white;
      border-right: solid 2px $white;
      transform: rotate(-45deg) translateX(-3px) translateY(-10px);
    }
    &:hover {
      box-shadow: 0 0 5px -2px rgba(0,0,0,0.12);
      background: $greenhover;
    }
  }
}

.u-banner-fixed-sp {
  display: none;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 500;
  @include onlyTablet {
    display: block;
  }
  &-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 170px;
    background: $red;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    &-image {
      width: 45px;
      padding: 5px;
      background: $white;
      border-radius: 5px;
    }
    &-text {
      flex: 1;
      padding-left: 5px;
    }
  }
  .u-pagetop-sp {
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 500;
    a {
      display: block;
      width: 50px;
      height: 50px;
      background: $green;
      color: $white;
      border-radius: 50%;
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.3);
      transition: all 0.5s $ease;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        border-top: solid 2px $white;
        border-right: solid 2px $white;
        transform: rotate(-45deg) translateX(-2px) translateY(-7px);
      }
      &:hover {
        box-shadow: 0 0 5px -2px rgba(0,0,0,0.12);
        background: $greenhover;
      }
    }
  }
}

.u-list-entries {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 31%;
      margin: 0 3.5% 30px 0;
      @include onlyMobile {
        width: 100%;
        margin: 0 0 20px 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      a {
        &:hover {
          text-decoration: none;
          img {
            transform: scale(1.07);
          }
        }
      }
    }
  }
  &-image {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    img {
      transition: all 0.5s $ease;
    }
  }
  &-title {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.6;
  }
  &-category {
    font-size: 12px;
    text-align: right;
    span {
      margin-left: 15px;
    }
  }
  &-ranking {
    ul {
      counter-reset: number 0;
      li {
        a {
          .u-list-entries-image {
            position: relative;
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              counter-increment: number 1;
              content: counter(number) " ";
              width: 40px;
              height: 40px;
              background: #ea5656;
              color: #fff;
              text-align: center;
              line-height: 40px;
              border-bottom-right-radius: 15px;
              z-index: 10;
            }
          }
        }
      }
    }
  }
}

.u-pager {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 0 5px;
      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        line-height: 40px;
        color: #fff;
        background: #6fb99b;
        border-radius: 50%;
        transition: all 0.5s $ease;
        &:hover {
          background: #91cab3;
          text-decoration: none;
        }
      }
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        line-height: 40px;
        background: #eef7f3;
        border-radius: 50%;
      }
    }
  }
}

.u-tab {
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  li {
    flex: 1;
    padding: 5px 10px;
    cursor: pointer;
    background: #91cab3;
    text-align: center;
    color: #fff;
    border-right: solid 1px #eef7f3;
    &:last-of-type {
      border-right: none;
    }
    &.is-active {
      background: #eef7f3;
      color: #515151;
      font-weight: 700;
    }
  }
  &-body-item {
    display: none;
    padding: 15px;
    background: #eef7f3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &.is-show {
      display: block;
    }
  }
}

.u-list-ranking-side {
  ul {
    counter-reset: number 0; 
    li {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: solid 1px #91cab3;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        display: block;
        font-size: 10px;
        background: #ea5656;
        color: #fff;
        text-align: center;
        line-height: 20px;
        counter-increment: number 1;
        content: counter(number) " ";
      }
      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
      a {
        display: flex;
        line-height: 1.6;
      }
    }
  }
  &-image {
    width: 80px;
  }
  &-title {
    flex: 1;
    padding-left: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  &-recent {
    ul {
      li {
        &:before {
          display: none;
        }
      }
    }
  }
}

.u-entry {
  &-title {
    position: relative;
    margin-bottom: 60px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    @include onlyMobile {
      margin-bottom: 40px;
      font-size: 23px;
    }
    &:before {
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 100%;
      height: 5px;
      display: block;
      content: "";
      border-radius: 5px;
      background: #eef7f3;
      @include onlyMobile {
        bottom: -20px;
      }
    }
  }
  &-social {
    margin-bottom: 30px;
    @include onlyMobile {
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &-body {
    margin-bottom: 60px;
    h2 {
      padding-left: 20px;
      margin-bottom: 30px;
      font-size: 23px;
      font-weight: 700;
      line-height: 1.4;
      border-left: solid 5px #6fb99b;
      @include onlyMobile {
        padding-left: 15px;
        margin-bottom: 20px;
        font-size: 20px;
        border-left: solid 3px #6fb99b;
      }
    }
    h3 {
      padding: 10px 20px;
      margin-bottom: 30px;
      font-size: 20px;
      background: #eef7f3;
      font-weight: 700;
      line-height: 1.4;
      @include onlyMobile {
        padding: 10px 15px;
        margin-bottom: 20px;
        font-size: 17px;
      }
    }
    h4 {
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
      @include onlyMobile {
        margin-bottom: 20px;
        font-size: 17px;
      }
    }
    p {
      margin-bottom: 30px;
      @include onlyMobile {
        margin-bottom: 20px;
      }
    }
    ul {
      margin-bottom: 30px;
      @include onlyMobile {
        margin-bottom: 20px;
      }
      li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 15px;
        line-height: 1.6;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:before {
          position: absolute;
          top: 11px;
          left: 0;
          display: block;
          content: "";
          width: 7px;
          height: 7px;
          background: #6fb99b;
          border-radius: 50%;
        }
      }
    }
    ol {
      margin-bottom: 30px;
      padding-left: 1.2em;
      list-style: decimal;
      list-style-position: outside;
      @include onlyMobile {
        margin-bottom: 20px;
      }
      li {
        margin-bottom: 10px;
        line-height: 1.6;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    blockquote {
      padding: 30px;
      margin: 0 0 30px 0;
      background: #eef7f3;
      @include onlyMobile {
        padding: 20px;
        margin: 0 0 20px 0;
      }
    }
    .wp-block-gallery {
      ul  {
        li {
          padding: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.u-entry-author {
  display: flex;
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 20px;
  background: #eef7f3;
  @include onlyTablet {
    display: block;
    padding: 0 15px 15px;
    margin-bottom: 30px;
  }
  &-image {
    width: 150px;
    @include onlyTablet {
      width: 100%;
      margin-bottom: -15px;
      text-align: center;
    }
    img {
      border-radius: 50%;
      @include onlyTablet {
        display: inline-block;
        width: 100px;
        border: solid 5px #fff;
        margin-top: -30px;
      }
    }
  }
  &-detail {
    flex: 1;
    padding-left: 30px;
    @include onlyTablet {
      padding-left: 0;
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-name {
        font-size: 20px;
        small {
          display: inline-block;
          margin-right: 10px;
          font-size: 13px;
        }
      }
      &-sns {
        ul {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 0;
          li {
            padding-left: 0;
            margin-left: 5px;
            font-size: 30px;
            a {
              color: #6fb99b;
              transition: all 0.5s $ease;
              &:hover {
                color: #91cab3;
              }
            }
            &:before {
              display: none;
            }
          }
        }
      }
    }
    &-body {
      p {
        margin-bottom: 15px;
        font-size: 13px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.u-entry-related {
  &-title {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.4;
  }
  &-list {
    ul {
      display: flex;
      @include onlyTablet {
        flex-wrap: wrap;
      }
      li {
        width: 18%;
        margin: 0 2.5% 0 0;
        @include onlyTablet {
          width: 48%;
          margin: 0 4% 15px 0;
        }
        &:nth-child(5n) {
          margin-right: 0;
          @include onlyTablet {
            margin-right: 5%;
          }
        }
        &:nth-child(2n) {
          @include onlyTablet {
            margin-right: 0;
          }
        }
        a {
          img {
            transition: all 0.5s $ease;
          }
          &:hover {
            text-decoration: none;
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    &-image {
      margin-bottom: 5px;
      border-radius: 10px;
      overflow: hidden;
    }
    &-title {
      font-size: 13px;
      line-height: 1.5;
    }
  }
}

.u-list-categories {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 0 10px 10px 0;
      .u-button {
        min-width: auto;
        padding: 10px 35px 10px 20px;
        text-align: center;
        background: #f1eb9b;
        color: #515151;
        @include onlyTablet {
          font-size: 13px;
        }
        &:before {
          border-top: 2px solid #515151;
          border-right: 2px solid #515151;
        }
        &:hover {
          background: #ede364;
        }
      }
      &.is-current {
        .u-button {
          background: #6fb99b;
          color: #fff;
          &:before {
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
          }
          &:hover {
            background: #91cab3;
          }
        }
      }
    }
  }
}







.u-br-pc {
  @include onlyMobile {
    display: none;
  }
}

.u-mb {
  margin-bottom: 60px;
  @include onlyMobile {
    margin-bottom: 30px;
  }
}

.u-mb-small {
  margin-bottom: 40px;
  @include onlyMobile {
    margin-bottom: 15px;
  }
}

.u-mb-xsmall {
  margin-bottom: 20px;
  @include onlyMobile {
    margin-bottom: 10px;
  }
}

.u-align-center {
  text-align: center;
}

.u-align-right {
  text-align: right;
}

.u-font-small {
  font-size: 13px;
  @include onlyMobile {
    font-size: 12px;
  }
}

.u-font-middle {
  font-size: 20px;
  @include onlyMobile {
    font-size: 17px;
  }
}

.u-font-large {
  font-size: 25px;
  @include onlyMobile {
    font-size: 23px;
    line-height: 1.6;
  }
}

.u-font-bold {
  font-weight: 700;
}

.u-font-red {
  color: $red;
}

.u-font-green {
  color: $green;
}

.u-font-linethrough {
  text-decoration: line-through;
}

.u-image-width900 {
  display: inline;
  max-width: 900px;
}

.u-image-width800 {
  display: inline;
  max-width: 800px;
}

.u-image-width600 {
  display: inline;
  max-width: 600px;
}

.u-image-width400 {
  display: inline;
  max-width: 400px;
}

.u-image-width300 {
  display: inline;
  max-width: 300px;
}

.u-image-width240 {
  display: inline;
  max-width: 240px;
}

.u-image-width200 {
  display: inline;
  max-width: 200px;
}

.u-image-width45p {
  width: 45%;
}

.u-group:after {
  content: "";
  display: block;
  clear: both;
}

.u-float-left {
  float: left;
  margin: 0 45px 45px 0;
  @include onlyMobile {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.u-float-right {
  float: right;
  margin: 0 0 45px 45px;
  @include onlyMobile {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.u-text-border {
  text-decoration: underline;
}

.u-image-padding {
  padding: 20px;
  background: #fff;
}

.u-modal-inline {
  display: none;
}

.u-form {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px;
  background: $greenlight2;
  border-radius: 30px;
  @include onlyMobile {
    padding: 30px;
  }
  .screen-reader-response {
    margin-bottom: 30px;
    color: $red;
    @include onlyMobile {
      line-height: 1.5;
    }
    p {
      font-weight: bold;
      @include onlyMobile {
        margin-bottom: 5px;
        line-height: 1.5;
      }
    }
    ul {
      li {
        position: relative;
        padding-left: 12px;
        &:before {
          position: absolute;
          top: 0.8em;
          left: 0;
          display: block;
          content: "";
          width: 5px;
          height: 5px;
          background: $red;
          border-radius: 50%;
          @include onlyMobile {
            top: 0.5em;
          }
        }
      }
    }
  }
  &-item {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: solid 2px $white;
    @include onlyMobile {
      flex-direction: column;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
    &-required {
      color: $red;
    }
    &-label {
      width: 200px;
      padding-top: 3px;
      text-align: right;
      font-size: 17px;
      @include onlyMobile {
        width: 100%;
        padding-top: 0;
        margin-bottom: 10px;
        text-align: left;
      }
    }
    &-input {
      flex: 1;
      padding-left: 15px;
      @include onlyMobile {
        padding-left: 0;
      }
      select {
        font-size: 17px;
        padding: 5px 15px;
        @include onlyMobile {
          width: 100%;
        }
      }
      input[type="text"],input[type="email"] {
        width: 100%;
        border: solid 1px #e1e1e1;
        font-size: 17px;
        padding: 7px 10px;
        border-radius: 5px;
      }
      textarea {
        width: 100%;
        height: 200px;
        border: solid 1px #e1e1e1;
        font-size: 17px;
        padding: 7px 10px;
        border-radius: 5px;
      }
      input + span, textarea + span {
        display: block;
        margin-top: 5px;
        color: $red;
        @include onlyMobile {
          line-height: 1.5;
        }
      }
    }
  }
  &-submit {
    text-align: center;
    input[type="submit"] {
      position: relative;
      display: inline-block;
      min-width: 240px;
      padding: 15px 40px;
      background: $green;
      color: $white;
      font-weight: 700;
      border-radius: 30px;
      border: none;
      font-size: 17px;
      transition: all 0.5s $ease;
      cursor: pointer;
      @include onlyMobile {
        border-radius: 60px;
        font-size: 13px;
      }
      &:hover {
        text-decoration: none;
        background: $greenhover;
      }
      &:before {
        position: absolute;
        top: 50%;
        right: 20px;
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        margin-top: -3.5px;
        border-top: solid 2px $white;
        border-right: solid 2px $white;
        transform: rotate(45deg);
      }
    }
    + .wpcf7-response-output {
      margin-top: 10px;
      text-align: center;
      color: $red;
      @include onlyMobile {
        line-height: 1.5;
      }
    }
  }
}

.u-movie-single {
  max-width: 800px;
  margin: 0 auto;
  &-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 20px;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}