.l-globalheader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0 15px 30px;
  z-index: 2000;
  @include onlyTablet {
    padding: 15px 30px;
  }
  @include onlyMobile {
    padding: 15px;
  }
  &-logo {
    width: 160px;
    transition: all 0.5s $ease;
    @include onlyMobile {
      width: 100px;
    }
  }
  &-main {
    flex: 1;
    display: flex;
    align-items: center;    
    &-navi {
      flex: 1;
      padding-left: 20px;
      line-height: 1.4;
      @include onlyTablet {
        display: none;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
      &-primary {
        display: flex;
        font-size: 18px;
        font-weight: 700;     
        transition: all 0.5s $ease;   
        li {
          margin-right: 30px;
          &:last-of-type {
            margin-right: 0;
          }
          a {
            position: relative;
            display: block;
            &:before {
              position: absolute;
              bottom: -7px;
              left: 0;
              display: block;
              content: "";
              width: 0;
              height: 4px;
              background: $green;
              border-radius: 5px;
              transition: all 0.5s $ease;
              transform-origin: center;
            }
            &:hover {
              &:before {
                width: 100%;
              }
            }
          }
          &.is-active {
            a {
              &:before {
                width: 100%;
              }
            }
          }
          
        }
      }      
      &-secondary {
        display: flex;
        margin-bottom: 20px;
        font-size: 12px; 
        font-weight: 700;   
        transition: all 0.3s $ease;
        li {
          margin-right: 20px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  &-information {
    padding: 10px 15px 10px 30px;
    background: $green;
    color: $white;
    text-align: right;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-weight: 700;
    @include onlyTablet {
      display: none;
    }
    &-businesshours {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      &-time {
        margin-right: 10px;
      }
      &-closed {
        display: flex;
        border: solid 2px $white;
        border-radius: 6px;
        line-height: 1;
        span {
          padding: 4px 6px;
          background: $white;
          color: $green;
        }
        strong {
          padding: 4px 6px;    
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          color: $white;
        }
      }
    }
    &-tel {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1;
      i {
        position: relative;
        top: 2px;
        margin-right: 3px;
        font-size: 25px;
      }
    }
    &-reserve {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      padding: 5px 10px;
      background: $red;
      border-radius: 20px;
      color: #fff;
      transition: all 0.5s $ease;
      &:hover {
        text-decoration: none;
        background: $redhover;
      }
      img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border-radius: 3px;
      }
    }
  }
  
  &.is-sticky {
    padding-top: 5px;
    padding-bottom: 5px;
    @include onlyMobile {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .l-globalheader-logo {
      width: 120px;
      @include onlyMobile {
        width: 100px;
      }
    }
    .l-globalheader-main-navi-primary {
      transform: translateY(-20px);
    } 
    .l-globalheader-main-navi-secondary {
      transform: translateY(-40px);
      opacity: 0;
    }  
  }
}