// Default
$ease: cubic-bezier(.23, .76, .37, .99);
$green: #6fb99b;
$greenlight: #f1eb9b;
$greenlight2: lighten($green, 37%);
$greenlight3: lighten($greenlight, 14%);
$greenlight4: lighten($greenlight, 18%);
$greenhover: lighten($green, 10%);
$black: #515151;
$gray: #aaa; 
$graylight: #f1f1f1; 
$white: #fff; 
$red: #ea5656; 
$redhover: #f18282; 
$redlight: #fdeeee; 
