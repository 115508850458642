
.block_sp {
  display: block !important;

  @include onlyDesktop {
    display: none !important;
  }
}

.block_pc {
  display: none !important;

  @include onlyDesktop {
    display: block !important;
  }
}

.inline_sp {
  display: inline !important;

  @include onlyDesktop {
    display: none !important;
  }
}

.inline_pc {
  display: none !important;

  @include onlyDesktop {
    display: inline !important;
  }
}

img {
	max-width: 100%;
}

html {
	width: 100%;
	height: 100%;
}

html.fix {
  overflow: hidden;
}

body {
	width: 100%;
	height: 100%;
	min-height: 100%;
	font-family: 'Kiwi Maru','Lucida Grande','Hiragino Kaku Gothic ProN', Meiryo, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
	color: $black;
	line-height: 1.8;
  letter-spacing: 0.01em;
}

body * {
	box-sizing: border-box;
}

a {
	color: $green;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.group,
.clear {
	zoom:1;
}

.group:after,
.clear:after {
  content: "";
  display: block;
  clear: both;
}
