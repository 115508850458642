.p-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0 0 0 30px;
  @include onlyTablet {
    height: 720px;
  }
  @include onlyMobile {
    padding: 0 0 0 15px;
  }
  &-body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    background: url(/assets/img/hero01.jpg) no-repeat center center;
    background-size: cover;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    text-align: center;
    @include onlyTablet {
      justify-content: center;
    }
    @include onlyMobile {
      padding: 0 15px;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
    }
    &-text {
      width: 48%;
      max-width: 680px;
      min-width: 600px;
      @include onlyTablet {
        width: 80%;
        max-width: inherit;
        min-width: inherit;
      }
      @include onlyMobile {
        width: 100%;
      }
      color: $green;
      text-align: center;
      &-title {
        font-weight: 700;
        font-size: 42px;
        letter-spacing: 0.05em;
        text-shadow: 0 0 15px rgba(255,255,255,.8),0 0 10px rgba(255,255,255,.8);
        @include onlyTablet {
          font-size: 4vw;
        }
        @include onlyMobile {
          font-size: 6.55555555vw;
        }
      }
      &-lead {
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.1em;
        text-shadow: 0 0 15px rgba(255,255,255,.8),0 0 10px rgba(255,255,255,.8);
        @include onlyTablet {
          font-size: 3.33333333vw;
        }
        @include onlyMobile {
          font-size: 4.55555555vw;
        }
      }
      &-excerpt {
        padding: 20px 30px;
        background: rgba(111,185,155,0.9);
        color: $white;
        border-radius: 20px;
        box-shadow: 0 0 20px rgba(0,0,0,0.12);
        &-title {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: dotted 1px $white;
          font-size: 18px;
          font-weight: 700;
        }
        &-body {
          font-size: 13px;
          text-align: left;
        }
      }
    }
  }
  &-interview {
    height: 460px;
    @include onlyMobile {
      height: auto;
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 60px;
      background: url(/assets/img/hero02.jpg) no-repeat center center;
      background-size: cover;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      text-align: center;
      @include onlyMobile {
        padding: 60px 30px 90px;
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
      }
      &-title {
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 0.05em;
        text-shadow: 0 0 15px rgba(255,255,255,.8),0 0 10px rgba(255,255,255,.8);
        color: $green;
        @include onlyTablet {
          font-size: 4vw;
        }
        @include onlyMobile {
          font-size: 5.55555555vw;
          text-align: left;
        }
        br {
          @include onlyMobile {
            display: none;
          }
        }
      }
    }
  }
}

.p-hero-small {
  width: 100%;
  height: 400px;
  display: flex;
  padding: 0 0 0 30px;
  @include onlyMobile {
    height: 200px;
  }
  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    background: url(/assets/img/hero02.jpg) no-repeat center center;
    background-size: cover;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    text-align: center;
    @include onlyMobile {
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
    }
    &-text {
      color: $green;
      &-title {
        font-weight: 700;
        font-size: 42px;
        letter-spacing: 0.05em;
        line-height: 1.4;
        text-shadow: 0 0 15px rgba(255,255,255,.8),0 0 10px rgba(255,255,255,.8);
        @include onlyTablet {
          font-size: 4vw;
        }
        @include onlyMobile {
          font-size: 6.55555555vw;
        }
        &-en {
          font-weight: 700;
          font-size: 15px;
          letter-spacing: 0.05em;
          @include onlyTablet {
            font-size: 2vw;
          }
          @include onlyMobile {
            font-size: 3.55555555vw;
          }
        }
      }
    }
  }
}

.p-breadcrumb {
  width: 100%;
  padding: 0 0 0 30px;
  &-body {
    padding: 60px 60px 30px 60px;
    background: #eef7f3;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    text-align: center;
    @include onlyMobile {
      padding: 45px 0 15px 30px;
      background: #eef7f3;
      border-top-left-radius: 45px;
      border-bottom-left-radius: 45px;
    }
    ul {
      display: flex;
      justify-content: flex-end;
      @include onlyMobile {
        width: 100%;
        justify-content: flex-start;
        overflow-x: scroll;
        white-space: nowrap;
      }
      li {
        margin-right: 30px;
        font-size: 12px;
        &:last-of-type {
          margin-right: 0;
        }
        a {
          position: relative;
          &:before {
            position: absolute;
            top: 50%;
            right: -18px;
            display: block;
            content: "";
            width: 5px;
            height: 5px;
            margin-top: -2.5px;
            border-top: solid 1px #515151;
            border-right: solid 1px #515151;
            transform: rotate(45deg);
          }
        }
        span {
          display: inline-block;
          margin-left: 5px;
          &:before {
            display: inline-block;
            margin-right: 5px;
            content: "/";
          }
          &:first-of-type {
            margin-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.p-profile {
  position: relative;
  padding: 0 60px;
  margin-top: -60px;
  z-index: 10;
  @include onlyMobile {
    padding: 0 30px;
  }
  &-inner {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,0.12);
    overflow: hidden;
    @include onlyMobile {
      flex-direction: column;
    }
  }
  &-image {
    position: relative;
    width: 35%;
    @include onlyMobile {
      width: 100%;
    }
    &-name {
      position: absolute;
      bottom: 10px;
      left: 10%;
      width: 80%;
      text-align: center;
      span {
        display: inline-block;
        padding: 5px 30px 5px;
        background: #fff;
        border-radius: 50px;
        font-size: 13px;
        line-height: 1.4;
        strong {
          font-size: 16px;
        }
        ruby {
          display: table-cell;
          rt {
            margin-top: 0;
            display: block;
          }
        }
        em {
          display: block;
          font-size: 10px;
          font-weight: 700;
          font-style: normal;
          line-height: 1.4;
          text-align: right;
        }
      }
    }
  }
  &-detail {
    width: 60%;
    padding: 30px;
    font-size: 18px;
    font-weight: 700;
    @include onlyMobile {
      width: 100%;
      padding: 15px;
      font-size: 15px;
    }
    ul {
      li {
        position: relative;
        padding-left: 15px;
        &:before {
          position: absolute;
          top: 0.8em;
          left: 0;
          display: block;
          content: "";
          width: 5px;
          height: 5px;
          background: $black;
          border-radius: 50%;
        }
      }
    }
  }
}

.p-interview-box {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  img {
    border-radius: 20px;
  }
  &-title {
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    color: $green;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    @include onlyMobile {
      font-size: 17px;
    }
    &:before {
      position: absolute;
      top: 0.9em;
      left: 0;
      display: block;
      content: "";
      width: 20px;
      height: 2px;
      background: $green;
    }
  }
  &-image01 {
    max-width: 700px;
    padding: 20px;
    margin: 0 auto;
    background: #fff;
  }
  .u-headline-lv1 {
    @include onlyMobile {
      text-align: left;
    }
    br {
      @include onlyMobile {
        display: none;
      }
    }
  }
  .u-image-width200 {
    @include onlyMobile {
      display: none;
    }
  }
}

.p-about-saloncard {
  padding: 60px 30px 0 30px;
  border-top: solid 5px $graylight;
  @include onlyMobile {
    padding: 30px 0 0 0;
  }
  &-top {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    @include onlyMobile {
      flex-direction: column;
    }
    &-logo {
      width: 150px;
      @include onlyMobile {
        margin: 0 auto 15px auto;
      }
    }
    &-text {
      flex: 1;
      padding-left: 15px;
      @include onlyMobile {
        padding-left: 0;
      }
    }
  }
  &-images {
    ul {
      display: flex;
      justify-content: space-between;
      max-width: 1000px;
      margin: 0 auto;
      @include onlyMobile {
        flex-direction: column;
      }
      li {
        width: 31%;
        @include onlyMobile {
          width: 100%;
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 5px;
          color: $green;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}

.p-about-reserve {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include onlyMobile {
    flex-direction: column;
  }
  &-image {
    width: 52%;
    @include onlyMobile {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  &-detail {
    width: 44%;
    @include onlyMobile {
      width: 100%;
    }
  }
}

.p-use-reservia {
  display: flex;
  justify-content: center;
  @include onlyMobile {
    flex-direction: column;
  }
  &-item {
    width: 30%;
    padding: 15px;
    margin: 0 15px;
    background: $greenlight2;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 0 20px -5px rgba(0,0,0,.12);
    overflow: hidden;
    transition: all 0.5s $ease;
    @include onlyMobile {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 15px auto;
    }
    &:hover {
      background: $greenlight3;
      box-shadow: 0 0 10px -3px rgba(0,0,0,.2);
      text-decoration: none;
    }
  }
  &-title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  &-image {
    margin-bottom: 15px;
  }
  &-button {
    display: block;
    padding: 10px;
    margin: 0 -15px -15px -15px;
    background: $green;
    color: $white;
    font-weight: bold;
    i {
      position: relative;
      top: 2px;
      margin-right: 5px;
      font-size: 20px;
    }
  }
}

.p-modal-reservia {
  &-inner {
    width: 100%;
    display: flex;
    overflow: auto;
  }
  &-item {
    position: relative;
    min-width: 240px;
    margin-right: 50px;
    font-size: 13px;
    @include onlyMobile {
      min-width: 160px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 40%;
      left: calc( 100% + 15px );
      margin-top: -20px;
      border: 20px solid transparent;
      border-left: 20px solid $green;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:last-of-type:before {
      display: none;
    }
    &-image {
      margin-bottom: 15px;
    }
  }
}