@mixin onlyMobile {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin onlyTablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin onlyDesktop {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}

@mixin fontFamily($fontName) {
  font-family: $fontName, sans-serif;
}

@mixin fix() {
  &:after {
    display: block;
    content: '';
    visibility: hidden;
    clear: both;
    height: 0;
  }
}
