.l-globalfooter {
  padding: 0 30px 30px;
  margin-top: 60px;
  @include onlyTablet {
    margin-top: 15px;
  }
  @include onlyMobile {
    padding: 0 15px 15px;
  }
  &-navi {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    @include onlyMobile {
      flex-direction: column-reverse;
    }
    &-menu {
      ul {
        display: flex;
        margin-bottom: 10px;
        @include onlyMobile {
          display: block;
          text-align: center;
        }
        li {
          margin-right: 20px;
          @include onlyMobile {
            display: inline-block;
            margin-right: 0;
            padding: 0 10px;
          }
          a {
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
      &-copyright {
        font-size: 13px;
        @include onlyMobile {
          font-size: 13px;
          text-align: center;
        }
      }
    }
    &-social {
      @include onlyMobile {
        width: 100%;
        margin-bottom: 15px;
      }
      ul {
        display: flex;
        justify-content: flex-end;
        @include onlyMobile {
          justify-content: center;
        }
        li {
          vertical-align: baseline;
          line-height: 1;
          height: 20px;
        }
      }
    }
  }
  &-copyright {
    text-align: center;
    font-size: 13px;
  }
}