@charset "UTF-8";
/** unmounting in html5 */
/**************************************/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700&display=swap");
address, article, aside, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

[hidden], command, datalist, menu[type=context], rp, source {
  display: none; }

/** document */
/**************************************/
body {
  background: #fff;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
  text-align: left;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased; }

/** h1 h2 h3 h4 h5 */
/**************************************/
h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
  padding: 0; }

/** ul ol dl */
/**************************************/
ul, ol, dl {
  margin: 0;
  padding: 0;
  list-style: none; }

dd {
  margin: 0; }

/** p */
/**************************************/
p {
  line-height: 2;
  margin: 0;
  padding: 0; }

/** tables */
/**************************************/
table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit; }
  table > caption {
    text-align: left; }
  table th {
    text-align: left;
    margin: 0;
    padding: 0; }
  table td {
    margin: 0;
    padding: 0; }

/** form */
/**************************************/
form {
  margin: 0;
  padding: 0; }

input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0; }

/** images */
/**************************************/
img, picture {
  border: none;
  display: block;
  vertical-align: bottom;
  width: 100%;
  height: auto;
  backface-visibility: hidden; }

/** anchor */
/**************************************/
a {
  outline: none; }

/** pre */
/**************************************/
pre {
  border: 1px solid #bfbfbf;
  padding: 5px; }

/** .gist */
/**************************************/
.gist {
  width: 100%; }

.block_sp {
  display: block !important; }
  @media only screen and (min-width: 1025px) {
    .block_sp {
      display: none !important; } }

.block_pc {
  display: none !important; }
  @media only screen and (min-width: 1025px) {
    .block_pc {
      display: block !important; } }

.inline_sp {
  display: inline !important; }
  @media only screen and (min-width: 1025px) {
    .inline_sp {
      display: none !important; } }

.inline_pc {
  display: none !important; }
  @media only screen and (min-width: 1025px) {
    .inline_pc {
      display: inline !important; } }

img {
  max-width: 100%; }

html {
  width: 100%;
  height: 100%; }

html.fix {
  overflow: hidden; }

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: 'Kiwi Maru','Lucida Grande','Hiragino Kaku Gothic ProN', Meiryo, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  line-height: 1.8;
  letter-spacing: 0.01em; }

body * {
  box-sizing: border-box; }

a {
  color: #6fb99b;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

.group,
.clear {
  zoom: 1; }

.group:after,
.clear:after {
  content: "";
  display: block;
  clear: both; }

@keyframes hoverTextA {
  0% {
    left: 0;
    width: 100%; }
  100% {
    left: 100%;
    width: 0; } }

@keyframes hoverTextB {
  0% {
    left: 0;
    width: 0; }
  100% {
    left: 0;
    width: 100%; } }

@keyframes openHeader {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

.la, .lab, .lad, .lal, .lar, .las {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.la-lg {
  font-size: 1.33333em;
  line-height: .75em;
  vertical-align: -.0667em; }

.la-xs {
  font-size: .75em; }

.la-sm {
  font-size: .875em; }

.la-1x {
  font-size: 1em; }

.la-2x {
  font-size: 2em; }

.la-3x {
  font-size: 3em; }

.la-4x {
  font-size: 4em; }

.la-5x {
  font-size: 5em; }

.la-6x {
  font-size: 6em; }

.la-7x {
  font-size: 7em; }

.la-8x {
  font-size: 8em; }

.la-9x {
  font-size: 9em; }

.la-10x {
  font-size: 10em; }

.la-fw {
  text-align: center;
  width: 1.25em; }

.la-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }

.la-ul > li {
  position: relative; }

.la-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.la-border {
  border: solid .08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.la-pull-left {
  float: left; }

.la-pull-right {
  float: right; }

.la.la-pull-left, .lab.la-pull-left, .lal.la-pull-left, .lar.la-pull-left, .las.la-pull-left {
  margin-right: .3em; }

.la.la-pull-right, .lab.la-pull-right, .lal.la-pull-right, .lar.la-pull-right, .las.la-pull-right {
  margin-left: .3em; }

.la-spin {
  -webkit-animation: la-spin 2s infinite linear;
  animation: la-spin 2s infinite linear; }

.la-pulse {
  -webkit-animation: la-spin 1s infinite steps(8);
  animation: la-spin 1s infinite steps(8); }

@-webkit-keyframes la-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes la-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.la-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.la-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.la-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.la-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.la-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1); }

.la-flip-both, .la-flip-horizontal.la-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1); }

:root .la-flip-both, :root .la-flip-horizontal, :root .la-flip-vertical, :root .la-rotate-180, :root .la-rotate-270, :root .la-rotate-90 {
  -webkit-filter: none;
  filter: none; }

.la-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.la-stack-1x, .la-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.la-stack-1x {
  line-height: inherit; }

.la-stack-2x {
  font-size: 2em; }

.la-inverse {
  color: #fff; }

.la-500px:before {
  content: "\f26e"; }

.la-accessible-icon:before {
  content: "\f368"; }

.la-accusoft:before {
  content: "\f369"; }

.la-acquisitions-incorporated:before {
  content: "\f6af"; }

.la-ad:before {
  content: "\f641"; }

.la-address-book:before {
  content: "\f2b9"; }

.la-address-card:before {
  content: "\f2bb"; }

.la-adjust:before {
  content: "\f042"; }

.la-adn:before {
  content: "\f170"; }

.la-adobe:before {
  content: "\f778"; }

.la-adversal:before {
  content: "\f36a"; }

.la-affiliatetheme:before {
  content: "\f36b"; }

.la-air-freshener:before {
  content: "\f5d0"; }

.la-airbnb:before {
  content: "\f834"; }

.la-algolia:before {
  content: "\f36c"; }

.la-align-center:before {
  content: "\f037"; }

.la-align-justify:before {
  content: "\f039"; }

.la-align-left:before {
  content: "\f036"; }

.la-align-right:before {
  content: "\f038"; }

.la-alipay:before {
  content: "\f642"; }

.la-allergies:before {
  content: "\f461"; }

.la-amazon:before {
  content: "\f270"; }

.la-amazon-pay:before {
  content: "\f42c"; }

.la-ambulance:before {
  content: "\f0f9"; }

.la-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.la-amilia:before {
  content: "\f36d"; }

.la-anchor:before {
  content: "\f13d"; }

.la-android:before {
  content: "\f17b"; }

.la-angellist:before {
  content: "\f209"; }

.la-angle-double-down:before {
  content: "\f103"; }

.la-angle-double-left:before {
  content: "\f100"; }

.la-angle-double-right:before {
  content: "\f101"; }

.la-angle-double-up:before {
  content: "\f102"; }

.la-angle-down:before {
  content: "\f107"; }

.la-angle-left:before {
  content: "\f104"; }

.la-angle-right:before {
  content: "\f105"; }

.la-angle-up:before {
  content: "\f106"; }

.la-angry:before {
  content: "\f556"; }

.la-angrycreative:before {
  content: "\f36e"; }

.la-angular:before {
  content: "\f420"; }

.la-ankh:before {
  content: "\f644"; }

.la-app-store:before {
  content: "\f36f"; }

.la-app-store-ios:before {
  content: "\f370"; }

.la-apper:before {
  content: "\f371"; }

.la-apple:before {
  content: "\f179"; }

.la-apple-alt:before {
  content: "\f5d1"; }

.la-apple-pay:before {
  content: "\f415"; }

.la-archive:before {
  content: "\f187"; }

.la-archway:before {
  content: "\f557"; }

.la-arrow-alt-circle-down:before {
  content: "\f358"; }

.la-arrow-alt-circle-left:before {
  content: "\f359"; }

.la-arrow-alt-circle-right:before {
  content: "\f35a"; }

.la-arrow-alt-circle-up:before {
  content: "\f35b"; }

.la-arrow-circle-down:before {
  content: "\f0ab"; }

.la-arrow-circle-left:before {
  content: "\f0a8"; }

.la-arrow-circle-right:before {
  content: "\f0a9"; }

.la-arrow-circle-up:before {
  content: "\f0aa"; }

.la-arrow-down:before {
  content: "\f063"; }

.la-arrow-left:before {
  content: "\f060"; }

.la-arrow-right:before {
  content: "\f061"; }

.la-arrow-up:before {
  content: "\f062"; }

.la-arrows-alt:before {
  content: "\f0b2"; }

.la-arrows-alt-h:before {
  content: "\f337"; }

.la-arrows-alt-v:before {
  content: "\f338"; }

.la-artstation:before {
  content: "\f77a"; }

.la-assistive-listening-systems:before {
  content: "\f2a2"; }

.la-asterisk:before {
  content: "\f069"; }

.la-asymmetrik:before {
  content: "\f372"; }

.la-at:before {
  content: "\f1fa"; }

.la-atlas:before {
  content: "\f558"; }

.la-atlassian:before {
  content: "\f77b"; }

.la-atom:before {
  content: "\f5d2"; }

.la-audible:before {
  content: "\f373"; }

.la-audio-description:before {
  content: "\f29e"; }

.la-autoprefixer:before {
  content: "\f41c"; }

.la-avianex:before {
  content: "\f374"; }

.la-aviato:before {
  content: "\f421"; }

.la-award:before {
  content: "\f559"; }

.la-aws:before {
  content: "\f375"; }

.la-baby:before {
  content: "\f77c"; }

.la-baby-carriage:before {
  content: "\f77d"; }

.la-backspace:before {
  content: "\f55a"; }

.la-backward:before {
  content: "\f04a"; }

.la-bacon:before {
  content: "\f7e5"; }

.la-balance-scale:before {
  content: "\f24e"; }

.la-balance-scale-left:before {
  content: "\f515"; }

.la-balance-scale-right:before {
  content: "\f516"; }

.la-ban:before {
  content: "\f05e"; }

.la-band-aid:before {
  content: "\f462"; }

.la-bandcamp:before {
  content: "\f2d5"; }

.la-barcode:before {
  content: "\f02a"; }

.la-bars:before {
  content: "\f0c9"; }

.la-baseball-ball:before {
  content: "\f433"; }

.la-basketball-ball:before {
  content: "\f434"; }

.la-bath:before {
  content: "\f2cd"; }

.la-battery-empty:before {
  content: "\f244"; }

.la-battery-full:before {
  content: "\f240"; }

.la-battery-half:before {
  content: "\f242"; }

.la-battery-quarter:before {
  content: "\f243"; }

.la-battery-three-quarters:before {
  content: "\f241"; }

.la-battle-net:before {
  content: "\f835"; }

.la-bed:before {
  content: "\f236"; }

.la-beer:before {
  content: "\f0fc"; }

.la-behance:before {
  content: "\f1b4"; }

.la-behance-square:before {
  content: "\f1b5"; }

.la-bell:before {
  content: "\f0f3"; }

.la-bell-slash:before {
  content: "\f1f6"; }

.la-bezier-curve:before {
  content: "\f55b"; }

.la-bible:before {
  content: "\f647"; }

.la-bicycle:before {
  content: "\f206"; }

.la-biking:before {
  content: "\f84a"; }

.la-bimobject:before {
  content: "\f378"; }

.la-binoculars:before {
  content: "\f1e5"; }

.la-biohazard:before {
  content: "\f780"; }

.la-birthday-cake:before {
  content: "\f1fd"; }

.la-bitbucket:before {
  content: "\f171"; }

.la-bitcoin:before {
  content: "\f379"; }

.la-bity:before {
  content: "\f37a"; }

.la-black-tie:before {
  content: "\f27e"; }

.la-blackberry:before {
  content: "\f37b"; }

.la-blender:before {
  content: "\f517"; }

.la-blender-phone:before {
  content: "\f6b6"; }

.la-blind:before {
  content: "\f29d"; }

.la-blog:before {
  content: "\f781"; }

.la-blogger:before {
  content: "\f37c"; }

.la-blogger-b:before {
  content: "\f37d"; }

.la-bluetooth:before {
  content: "\f293"; }

.la-bluetooth-b:before {
  content: "\f294"; }

.la-bold:before {
  content: "\f032"; }

.la-bolt:before {
  content: "\f0e7"; }

.la-bomb:before {
  content: "\f1e2"; }

.la-bone:before {
  content: "\f5d7"; }

.la-bong:before {
  content: "\f55c"; }

.la-book:before {
  content: "\f02d"; }

.la-book-dead:before {
  content: "\f6b7"; }

.la-book-medical:before {
  content: "\f7e6"; }

.la-book-open:before {
  content: "\f518"; }

.la-book-reader:before {
  content: "\f5da"; }

.la-bookmark:before {
  content: "\f02e"; }

.la-bootstrap:before {
  content: "\f836"; }

.la-border-all:before {
  content: "\f84c"; }

.la-border-none:before {
  content: "\f850"; }

.la-border-style:before {
  content: "\f853"; }

.la-bowling-ball:before {
  content: "\f436"; }

.la-box:before {
  content: "\f466"; }

.la-box-open:before {
  content: "\f49e"; }

.la-boxes:before {
  content: "\f468"; }

.la-braille:before {
  content: "\f2a1"; }

.la-brain:before {
  content: "\f5dc"; }

.la-bread-slice:before {
  content: "\f7ec"; }

.la-briefcase:before {
  content: "\f0b1"; }

.la-briefcase-medical:before {
  content: "\f469"; }

.la-broadcast-tower:before {
  content: "\f519"; }

.la-broom:before {
  content: "\f51a"; }

.la-brush:before {
  content: "\f55d"; }

.la-btc:before {
  content: "\f15a"; }

.la-buffer:before {
  content: "\f837"; }

.la-bug:before {
  content: "\f188"; }

.la-building:before {
  content: "\f1ad"; }

.la-bullhorn:before {
  content: "\f0a1"; }

.la-bullseye:before {
  content: "\f140"; }

.la-burn:before {
  content: "\f46a"; }

.la-buromobelexperte:before {
  content: "\f37f"; }

.la-bus:before {
  content: "\f207"; }

.la-bus-alt:before {
  content: "\f55e"; }

.la-business-time:before {
  content: "\f64a"; }

.la-buy-n-large:before {
  content: "\f8a6"; }

.la-buysellads:before {
  content: "\f20d"; }

.la-calculator:before {
  content: "\f1ec"; }

.la-calendar:before {
  content: "\f133"; }

.la-calendar-alt:before {
  content: "\f073"; }

.la-calendar-check:before {
  content: "\f274"; }

.la-calendar-day:before {
  content: "\f783"; }

.la-calendar-minus:before {
  content: "\f272"; }

.la-calendar-plus:before {
  content: "\f271"; }

.la-calendar-times:before {
  content: "\f273"; }

.la-calendar-week:before {
  content: "\f784"; }

.la-camera:before {
  content: "\f030"; }

.la-camera-retro:before {
  content: "\f083"; }

.la-campground:before {
  content: "\f6bb"; }

.la-canadian-maple-leaf:before {
  content: "\f785"; }

.la-candy-cane:before {
  content: "\f786"; }

.la-cannabis:before {
  content: "\f55f"; }

.la-capsules:before {
  content: "\f46b"; }

.la-car:before {
  content: "\f1b9"; }

.la-car-alt:before {
  content: "\f5de"; }

.la-car-battery:before {
  content: "\f5df"; }

.la-car-crash:before {
  content: "\f5e1"; }

.la-car-side:before {
  content: "\f5e4"; }

.la-caret-down:before {
  content: "\f0d7"; }

.la-caret-left:before {
  content: "\f0d9"; }

.la-caret-right:before {
  content: "\f0da"; }

.la-caret-square-down:before {
  content: "\f150"; }

.la-caret-square-left:before {
  content: "\f191"; }

.la-caret-square-right:before {
  content: "\f152"; }

.la-caret-square-up:before {
  content: "\f151"; }

.la-caret-up:before {
  content: "\f0d8"; }

.la-carrot:before {
  content: "\f787"; }

.la-cart-arrow-down:before {
  content: "\f218"; }

.la-cart-plus:before {
  content: "\f217"; }

.la-cash-register:before {
  content: "\f788"; }

.la-cat:before {
  content: "\f6be"; }

.la-cc-amazon-pay:before {
  content: "\f42d"; }

.la-cc-amex:before {
  content: "\f1f3"; }

.la-cc-apple-pay:before {
  content: "\f416"; }

.la-cc-diners-club:before {
  content: "\f24c"; }

.la-cc-discover:before {
  content: "\f1f2"; }

.la-cc-jcb:before {
  content: "\f24b"; }

.la-cc-mastercard:before {
  content: "\f1f1"; }

.la-cc-paypal:before {
  content: "\f1f4"; }

.la-cc-stripe:before {
  content: "\f1f5"; }

.la-cc-visa:before {
  content: "\f1f0"; }

.la-centercode:before {
  content: "\f380"; }

.la-centos:before {
  content: "\f789"; }

.la-certificate:before {
  content: "\f0a3"; }

.la-chair:before {
  content: "\f6c0"; }

.la-chalkboard:before {
  content: "\f51b"; }

.la-chalkboard-teacher:before {
  content: "\f51c"; }

.la-charging-station:before {
  content: "\f5e7"; }

.la-chart-area:before {
  content: "\f1fe"; }

.la-chart-bar:before {
  content: "\f080"; }

.la-chart-line:before {
  content: "\f201"; }

.la-chart-pie:before {
  content: "\f200"; }

.la-check:before {
  content: "\f00c"; }

.la-check-circle:before {
  content: "\f058"; }

.la-check-double:before {
  content: "\f560"; }

.la-check-square:before {
  content: "\f14a"; }

.la-cheese:before {
  content: "\f7ef"; }

.la-chess:before {
  content: "\f439"; }

.la-chess-bishop:before {
  content: "\f43a"; }

.la-chess-board:before {
  content: "\f43c"; }

.la-chess-king:before {
  content: "\f43f"; }

.la-chess-knight:before {
  content: "\f441"; }

.la-chess-pawn:before {
  content: "\f443"; }

.la-chess-queen:before {
  content: "\f445"; }

.la-chess-rook:before {
  content: "\f447"; }

.la-chevron-circle-down:before {
  content: "\f13a"; }

.la-chevron-circle-left:before {
  content: "\f137"; }

.la-chevron-circle-right:before {
  content: "\f138"; }

.la-chevron-circle-up:before {
  content: "\f139"; }

.la-chevron-down:before {
  content: "\f078"; }

.la-chevron-left:before {
  content: "\f053"; }

.la-chevron-right:before {
  content: "\f054"; }

.la-chevron-up:before {
  content: "\f077"; }

.la-child:before {
  content: "\f1ae"; }

.la-chrome:before {
  content: "\f268"; }

.la-chromecast:before {
  content: "\f838"; }

.la-church:before {
  content: "\f51d"; }

.la-circle:before {
  content: "\f111"; }

.la-circle-notch:before {
  content: "\f1ce"; }

.la-city:before {
  content: "\f64f"; }

.la-clinic-medical:before {
  content: "\f7f2"; }

.la-clipboard:before {
  content: "\f328"; }

.la-clipboard-check:before {
  content: "\f46c"; }

.la-clipboard-list:before {
  content: "\f46d"; }

.la-clock:before {
  content: "\f017"; }

.la-clone:before {
  content: "\f24d"; }

.la-closed-captioning:before {
  content: "\f20a"; }

.la-cloud:before {
  content: "\f0c2"; }

.la-cloud-download-alt:before {
  content: "\f381"; }

.la-cloud-meatball:before {
  content: "\f73b"; }

.la-cloud-moon:before {
  content: "\f6c3"; }

.la-cloud-moon-rain:before {
  content: "\f73c"; }

.la-cloud-rain:before {
  content: "\f73d"; }

.la-cloud-showers-heavy:before {
  content: "\f740"; }

.la-cloud-sun:before {
  content: "\f6c4"; }

.la-cloud-sun-rain:before {
  content: "\f743"; }

.la-cloud-upload-alt:before {
  content: "\f382"; }

.la-cloudscale:before {
  content: "\f383"; }

.la-cloudsmith:before {
  content: "\f384"; }

.la-cloudversify:before {
  content: "\f385"; }

.la-cocktail:before {
  content: "\f561"; }

.la-code:before {
  content: "\f121"; }

.la-code-branch:before {
  content: "\f126"; }

.la-codepen:before {
  content: "\f1cb"; }

.la-codiepie:before {
  content: "\f284"; }

.la-coffee:before {
  content: "\f0f4"; }

.la-cog:before {
  content: "\f013"; }

.la-cogs:before {
  content: "\f085"; }

.la-coins:before {
  content: "\f51e"; }

.la-columns:before {
  content: "\f0db"; }

.la-comment:before {
  content: "\f075"; }

.la-comment-alt:before {
  content: "\f27a"; }

.la-comment-dollar:before {
  content: "\f651"; }

.la-comment-dots:before {
  content: "\f4ad"; }

.la-comment-medical:before {
  content: "\f7f5"; }

.la-comment-slash:before {
  content: "\f4b3"; }

.la-comments:before {
  content: "\f086"; }

.la-comments-dollar:before {
  content: "\f653"; }

.la-compact-disc:before {
  content: "\f51f"; }

.la-compass:before {
  content: "\f14e"; }

.la-compress:before {
  content: "\f066"; }

.la-compress-arrows-alt:before {
  content: "\f78c"; }

.la-concierge-bell:before {
  content: "\f562"; }

.la-confluence:before {
  content: "\f78d"; }

.la-connectdevelop:before {
  content: "\f20e"; }

.la-contao:before {
  content: "\f26d"; }

.la-cookie:before {
  content: "\f563"; }

.la-cookie-bite:before {
  content: "\f564"; }

.la-copy:before {
  content: "\f0c5"; }

.la-copyright:before {
  content: "\f1f9"; }

.la-cotton-bureau:before {
  content: "\f89e"; }

.la-couch:before {
  content: "\f4b8"; }

.la-cpanel:before {
  content: "\f388"; }

.la-creative-commons:before {
  content: "\f25e"; }

.la-creative-commons-by:before {
  content: "\f4e7"; }

.la-creative-commons-nc:before {
  content: "\f4e8"; }

.la-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.la-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.la-creative-commons-nd:before {
  content: "\f4eb"; }

.la-creative-commons-pd:before {
  content: "\f4ec"; }

.la-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.la-creative-commons-remix:before {
  content: "\f4ee"; }

.la-creative-commons-sa:before {
  content: "\f4ef"; }

.la-creative-commons-sampling:before {
  content: "\f4f0"; }

.la-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.la-creative-commons-share:before {
  content: "\f4f2"; }

.la-creative-commons-zero:before {
  content: "\f4f3"; }

.la-credit-card:before {
  content: "\f09d"; }

.la-critical-role:before {
  content: "\f6c9"; }

.la-crop:before {
  content: "\f125"; }

.la-crop-alt:before {
  content: "\f565"; }

.la-cross:before {
  content: "\f654"; }

.la-crosshairs:before {
  content: "\f05b"; }

.la-crow:before {
  content: "\f520"; }

.la-crown:before {
  content: "\f521"; }

.la-crutch:before {
  content: "\f7f7"; }

.la-css3:before {
  content: "\f13c"; }

.la-css3-alt:before {
  content: "\f38b"; }

.la-cube:before {
  content: "\f1b2"; }

.la-cubes:before {
  content: "\f1b3"; }

.la-cut:before {
  content: "\f0c4"; }

.la-cuttlefish:before {
  content: "\f38c"; }

.la-d-and-d:before {
  content: "\f38d"; }

.la-d-and-d-beyond:before {
  content: "\f6ca"; }

.la-dashcube:before {
  content: "\f210"; }

.la-database:before {
  content: "\f1c0"; }

.la-deaf:before {
  content: "\f2a4"; }

.la-delicious:before {
  content: "\f1a5"; }

.la-democrat:before {
  content: "\f747"; }

.la-deploydog:before {
  content: "\f38e"; }

.la-deskpro:before {
  content: "\f38f"; }

.la-desktop:before {
  content: "\f108"; }

.la-dev:before {
  content: "\f6cc"; }

.la-deviantart:before {
  content: "\f1bd"; }

.la-dharmachakra:before {
  content: "\f655"; }

.la-dhl:before {
  content: "\f790"; }

.la-diagnoses:before {
  content: "\f470"; }

.la-diaspora:before {
  content: "\f791"; }

.la-dice:before {
  content: "\f522"; }

.la-dice-d20:before {
  content: "\f6cf"; }

.la-dice-d6:before {
  content: "\f6d1"; }

.la-dice-five:before {
  content: "\f523"; }

.la-dice-four:before {
  content: "\f524"; }

.la-dice-one:before {
  content: "\f525"; }

.la-dice-six:before {
  content: "\f526"; }

.la-dice-three:before {
  content: "\f527"; }

.la-dice-two:before {
  content: "\f528"; }

.la-digg:before {
  content: "\f1a6"; }

.la-digital-ocean:before {
  content: "\f391"; }

.la-digital-tachograph:before {
  content: "\f566"; }

.la-directions:before {
  content: "\f5eb"; }

.la-discord:before {
  content: "\f392"; }

.la-discourse:before {
  content: "\f393"; }

.la-divide:before {
  content: "\f529"; }

.la-dizzy:before {
  content: "\f567"; }

.la-dna:before {
  content: "\f471"; }

.la-dochub:before {
  content: "\f394"; }

.la-docker:before {
  content: "\f395"; }

.la-dog:before {
  content: "\f6d3"; }

.la-dollar-sign:before {
  content: "\f155"; }

.la-dolly:before {
  content: "\f472"; }

.la-dolly-flatbed:before {
  content: "\f474"; }

.la-donate:before {
  content: "\f4b9"; }

.la-door-closed:before {
  content: "\f52a"; }

.la-door-open:before {
  content: "\f52b"; }

.la-dot-circle:before {
  content: "\f192"; }

.la-dove:before {
  content: "\f4ba"; }

.la-download:before {
  content: "\f019"; }

.la-draft2digital:before {
  content: "\f396"; }

.la-drafting-compass:before {
  content: "\f568"; }

.la-dragon:before {
  content: "\f6d5"; }

.la-draw-polygon:before {
  content: "\f5ee"; }

.la-dribbble:before {
  content: "\f17d"; }

.la-dribbble-square:before {
  content: "\f397"; }

.la-dropbox:before {
  content: "\f16b"; }

.la-drum:before {
  content: "\f569"; }

.la-drum-steelpan:before {
  content: "\f56a"; }

.la-drumstick-bite:before {
  content: "\f6d7"; }

.la-drupal:before {
  content: "\f1a9"; }

.la-dumbbell:before {
  content: "\f44b"; }

.la-dumpster:before {
  content: "\f793"; }

.la-dumpster-fire:before {
  content: "\f794"; }

.la-dungeon:before {
  content: "\f6d9"; }

.la-dyalog:before {
  content: "\f399"; }

.la-earlybirds:before {
  content: "\f39a"; }

.la-ebay:before {
  content: "\f4f4"; }

.la-edge:before {
  content: "\f282"; }

.la-edit:before {
  content: "\f044"; }

.la-egg:before {
  content: "\f7fb"; }

.la-eject:before {
  content: "\f052"; }

.la-elementor:before {
  content: "\f430"; }

.la-ellipsis-h:before {
  content: "\f141"; }

.la-ellipsis-v:before {
  content: "\f142"; }

.la-ello:before {
  content: "\f5f1"; }

.la-ember:before {
  content: "\f423"; }

.la-empire:before {
  content: "\f1d1"; }

.la-envelope:before {
  content: "\f0e0"; }

.la-envelope-open:before {
  content: "\f2b6"; }

.la-envelope-open-text:before {
  content: "\f658"; }

.la-envelope-square:before {
  content: "\f199"; }

.la-envira:before {
  content: "\f299"; }

.la-equals:before {
  content: "\f52c"; }

.la-eraser:before {
  content: "\f12d"; }

.la-erlang:before {
  content: "\f39d"; }

.la-ethereum:before {
  content: "\f42e"; }

.la-ethernet:before {
  content: "\f796"; }

.la-etsy:before {
  content: "\f2d7"; }

.la-euro-sign:before {
  content: "\f153"; }

.la-evernote:before {
  content: "\f839"; }

.la-exchange-alt:before {
  content: "\f362"; }

.la-exclamation:before {
  content: "\f12a"; }

.la-exclamation-circle:before {
  content: "\f06a"; }

.la-exclamation-triangle:before {
  content: "\f071"; }

.la-expand:before {
  content: "\f065"; }

.la-expand-arrows-alt:before {
  content: "\f31e"; }

.la-expeditedssl:before {
  content: "\f23e"; }

.la-external-link-alt:before {
  content: "\f35d"; }

.la-external-link-square-alt:before {
  content: "\f360"; }

.la-eye:before {
  content: "\f06e"; }

.la-eye-dropper:before {
  content: "\f1fb"; }

.la-eye-slash:before {
  content: "\f070"; }

.la-facebook:before {
  content: "\f09a"; }

.la-facebook-f:before {
  content: "\f39e"; }

.la-facebook-messenger:before {
  content: "\f39f"; }

.la-facebook-square:before {
  content: "\f082"; }

.la-fan:before {
  content: "\f863"; }

.la-fantasy-flight-games:before {
  content: "\f6dc"; }

.la-fast-backward:before {
  content: "\f049"; }

.la-fast-forward:before {
  content: "\f050"; }

.la-fax:before {
  content: "\f1ac"; }

.la-feather:before {
  content: "\f52d"; }

.la-feather-alt:before {
  content: "\f56b"; }

.la-fedex:before {
  content: "\f797"; }

.la-fedora:before {
  content: "\f798"; }

.la-female:before {
  content: "\f182"; }

.la-fighter-jet:before {
  content: "\f0fb"; }

.la-figma:before {
  content: "\f799"; }

.la-file:before {
  content: "\f15b"; }

.la-file-alt:before {
  content: "\f15c"; }

.la-file-archive:before {
  content: "\f1c6"; }

.la-file-audio:before {
  content: "\f1c7"; }

.la-file-code:before {
  content: "\f1c9"; }

.la-file-contract:before {
  content: "\f56c"; }

.la-file-csv:before {
  content: "\f6dd"; }

.la-file-download:before {
  content: "\f56d"; }

.la-file-excel:before {
  content: "\f1c3"; }

.la-file-export:before {
  content: "\f56e"; }

.la-file-image:before {
  content: "\f1c5"; }

.la-file-import:before {
  content: "\f56f"; }

.la-file-invoice:before {
  content: "\f570"; }

.la-file-invoice-dollar:before {
  content: "\f571"; }

.la-file-medical:before {
  content: "\f477"; }

.la-file-medical-alt:before {
  content: "\f478"; }

.la-file-pdf:before {
  content: "\f1c1"; }

.la-file-powerpoint:before {
  content: "\f1c4"; }

.la-file-prescription:before {
  content: "\f572"; }

.la-file-signature:before {
  content: "\f573"; }

.la-file-upload:before {
  content: "\f574"; }

.la-file-video:before {
  content: "\f1c8"; }

.la-file-word:before {
  content: "\f1c2"; }

.la-fill:before {
  content: "\f575"; }

.la-fill-drip:before {
  content: "\f576"; }

.la-film:before {
  content: "\f008"; }

.la-filter:before {
  content: "\f0b0"; }

.la-fingerprint:before {
  content: "\f577"; }

.la-fire:before {
  content: "\f06d"; }

.la-fire-alt:before {
  content: "\f7e4"; }

.la-fire-extinguisher:before {
  content: "\f134"; }

.la-firefox:before {
  content: "\f269"; }

.la-first-aid:before {
  content: "\f479"; }

.la-first-order:before {
  content: "\f2b0"; }

.la-first-order-alt:before {
  content: "\f50a"; }

.la-firstdraft:before {
  content: "\f3a1"; }

.la-fish:before {
  content: "\f578"; }

.la-fist-raised:before {
  content: "\f6de"; }

.la-flag:before {
  content: "\f024"; }

.la-flag-checkered:before {
  content: "\f11e"; }

.la-flag-usa:before {
  content: "\f74d"; }

.la-flask:before {
  content: "\f0c3"; }

.la-flickr:before {
  content: "\f16e"; }

.la-flipboard:before {
  content: "\f44d"; }

.la-flushed:before {
  content: "\f579"; }

.la-fly:before {
  content: "\f417"; }

.la-folder:before {
  content: "\f07b"; }

.la-folder-minus:before {
  content: "\f65d"; }

.la-folder-open:before {
  content: "\f07c"; }

.la-folder-plus:before {
  content: "\f65e"; }

.la-font:before {
  content: "\f031"; }

.la-font-awesome:before {
  content: "\f2b4"; }

.la-font-awesome-alt:before {
  content: "\f35c"; }

.la-font-awesome-flag:before {
  content: "\f425"; }

.la-font-awesome-logo-full:before {
  content: "\f4e6"; }

.la-fonticons:before {
  content: "\f280"; }

.la-fonticons-fi:before {
  content: "\f3a2"; }

.la-football-ball:before {
  content: "\f44e"; }

.la-fort-awesome:before {
  content: "\f286"; }

.la-fort-awesome-alt:before {
  content: "\f3a3"; }

.la-forumbee:before {
  content: "\f211"; }

.la-forward:before {
  content: "\f04e"; }

.la-foursquare:before {
  content: "\f180"; }

.la-free-code-camp:before {
  content: "\f2c5"; }

.la-freebsd:before {
  content: "\f3a4"; }

.la-frog:before {
  content: "\f52e"; }

.la-frown:before {
  content: "\f119"; }

.la-frown-open:before {
  content: "\f57a"; }

.la-fulcrum:before {
  content: "\f50b"; }

.la-funnel-dollar:before {
  content: "\f662"; }

.la-futbol:before {
  content: "\f1e3"; }

.la-galactic-republic:before {
  content: "\f50c"; }

.la-galactic-senate:before {
  content: "\f50d"; }

.la-gamepad:before {
  content: "\f11b"; }

.la-gas-pump:before {
  content: "\f52f"; }

.la-gavel:before {
  content: "\f0e3"; }

.la-gem:before {
  content: "\f3a5"; }

.la-genderless:before {
  content: "\f22d"; }

.la-get-pocket:before {
  content: "\f265"; }

.la-gg:before {
  content: "\f260"; }

.la-gg-circle:before {
  content: "\f261"; }

.la-ghost:before {
  content: "\f6e2"; }

.la-gift:before {
  content: "\f06b"; }

.la-gifts:before {
  content: "\f79c"; }

.la-git:before {
  content: "\f1d3"; }

.la-git-alt:before {
  content: "\f841"; }

.la-git-square:before {
  content: "\f1d2"; }

.la-github:before {
  content: "\f09b"; }

.la-github-alt:before {
  content: "\f113"; }

.la-github-square:before {
  content: "\f092"; }

.la-gitkraken:before {
  content: "\f3a6"; }

.la-gitlab:before {
  content: "\f296"; }

.la-gitter:before {
  content: "\f426"; }

.la-glass-cheers:before {
  content: "\f79f"; }

.la-glass-martini:before {
  content: "\f000"; }

.la-glass-martini-alt:before {
  content: "\f57b"; }

.la-glass-whiskey:before {
  content: "\f7a0"; }

.la-glasses:before {
  content: "\f530"; }

.la-glide:before {
  content: "\f2a5"; }

.la-glide-g:before {
  content: "\f2a6"; }

.la-globe:before {
  content: "\f0ac"; }

.la-globe-africa:before {
  content: "\f57c"; }

.la-globe-americas:before {
  content: "\f57d"; }

.la-globe-asia:before {
  content: "\f57e"; }

.la-globe-europe:before {
  content: "\f7a2"; }

.la-gofore:before {
  content: "\f3a7"; }

.la-golf-ball:before {
  content: "\f450"; }

.la-goodreads:before {
  content: "\f3a8"; }

.la-goodreads-g:before {
  content: "\f3a9"; }

.la-google:before {
  content: "\f1a0"; }

.la-google-drive:before {
  content: "\f3aa"; }

.la-google-play:before {
  content: "\f3ab"; }

.la-google-plus:before {
  content: "\f2b3"; }

.la-google-plus-g:before {
  content: "\f0d5"; }

.la-google-plus-square:before {
  content: "\f0d4"; }

.la-google-wallet:before {
  content: "\f1ee"; }

.la-gopuram:before {
  content: "\f664"; }

.la-graduation-cap:before {
  content: "\f19d"; }

.la-gratipay:before {
  content: "\f184"; }

.la-grav:before {
  content: "\f2d6"; }

.la-greater-than:before {
  content: "\f531"; }

.la-greater-than-equal:before {
  content: "\f532"; }

.la-grimace:before {
  content: "\f57f"; }

.la-grin:before {
  content: "\f580"; }

.la-grin-alt:before {
  content: "\f581"; }

.la-grin-beam:before {
  content: "\f582"; }

.la-grin-beam-sweat:before {
  content: "\f583"; }

.la-grin-hearts:before {
  content: "\f584"; }

.la-grin-squint:before {
  content: "\f585"; }

.la-grin-squint-tears:before {
  content: "\f586"; }

.la-grin-stars:before {
  content: "\f587"; }

.la-grin-tears:before {
  content: "\f588"; }

.la-grin-tongue:before {
  content: "\f589"; }

.la-grin-tongue-squint:before {
  content: "\f58a"; }

.la-grin-tongue-wink:before {
  content: "\f58b"; }

.la-grin-wink:before {
  content: "\f58c"; }

.la-grip-horizontal:before {
  content: "\f58d"; }

.la-grip-lines:before {
  content: "\f7a4"; }

.la-grip-lines-vertical:before {
  content: "\f7a5"; }

.la-grip-vertical:before {
  content: "\f58e"; }

.la-gripfire:before {
  content: "\f3ac"; }

.la-grunt:before {
  content: "\f3ad"; }

.la-guitar:before {
  content: "\f7a6"; }

.la-gulp:before {
  content: "\f3ae"; }

.la-h-square:before {
  content: "\f0fd"; }

.la-hacker-news:before {
  content: "\f1d4"; }

.la-hacker-news-square:before {
  content: "\f3af"; }

.la-hackerrank:before {
  content: "\f5f7"; }

.la-hamburger:before {
  content: "\f805"; }

.la-hammer:before {
  content: "\f6e3"; }

.la-hamsa:before {
  content: "\f665"; }

.la-hand-holding:before {
  content: "\f4bd"; }

.la-hand-holding-heart:before {
  content: "\f4be"; }

.la-hand-holding-usd:before {
  content: "\f4c0"; }

.la-hand-lizard:before {
  content: "\f258"; }

.la-hand-middle-finger:before {
  content: "\f806"; }

.la-hand-paper:before {
  content: "\f256"; }

.la-hand-peace:before {
  content: "\f25b"; }

.la-hand-point-down:before {
  content: "\f0a7"; }

.la-hand-point-left:before {
  content: "\f0a5"; }

.la-hand-point-right:before {
  content: "\f0a4"; }

.la-hand-point-up:before {
  content: "\f0a6"; }

.la-hand-pointer:before {
  content: "\f25a"; }

.la-hand-rock:before {
  content: "\f255"; }

.la-hand-scissors:before {
  content: "\f257"; }

.la-hand-spock:before {
  content: "\f259"; }

.la-hands:before {
  content: "\f4c2"; }

.la-hands-helping:before {
  content: "\f4c4"; }

.la-handshake:before {
  content: "\f2b5"; }

.la-hanukiah:before {
  content: "\f6e6"; }

.la-hard-hat:before {
  content: "\f807"; }

.la-hashtag:before {
  content: "\f292"; }

.la-hat-cowboy:before {
  content: "\f8c0"; }

.la-hat-cowboy-side:before {
  content: "\f8c1"; }

.la-hat-wizard:before {
  content: "\f6e8"; }

.la-haykal:before {
  content: "\f666"; }

.la-hdd:before {
  content: "\f0a0"; }

.la-heading:before {
  content: "\f1dc"; }

.la-headphones:before {
  content: "\f025"; }

.la-headphones-alt:before {
  content: "\f58f"; }

.la-headset:before {
  content: "\f590"; }

.la-heart:before {
  content: "\f004"; }

.la-heart-broken:before {
  content: "\f7a9"; }

.la-heartbeat:before {
  content: "\f21e"; }

.la-helicopter:before {
  content: "\f533"; }

.la-highlighter:before {
  content: "\f591"; }

.la-hiking:before {
  content: "\f6ec"; }

.la-hippo:before {
  content: "\f6ed"; }

.la-hips:before {
  content: "\f452"; }

.la-hire-a-helper:before {
  content: "\f3b0"; }

.la-history:before {
  content: "\f1da"; }

.la-hockey-puck:before {
  content: "\f453"; }

.la-holly-berry:before {
  content: "\f7aa"; }

.la-home:before {
  content: "\f015"; }

.la-hooli:before {
  content: "\f427"; }

.la-hornbill:before {
  content: "\f592"; }

.la-horse:before {
  content: "\f6f0"; }

.la-horse-head:before {
  content: "\f7ab"; }

.la-hospital:before {
  content: "\f0f8"; }

.la-hospital-alt:before {
  content: "\f47d"; }

.la-hospital-symbol:before {
  content: "\f47e"; }

.la-hot-tub:before {
  content: "\f593"; }

.la-hotdog:before {
  content: "\f80f"; }

.la-hotel:before {
  content: "\f594"; }

.la-hotjar:before {
  content: "\f3b1"; }

.la-hourglass:before {
  content: "\f254"; }

.la-hourglass-end:before {
  content: "\f253"; }

.la-hourglass-half:before {
  content: "\f252"; }

.la-hourglass-start:before {
  content: "\f251"; }

.la-house-damage:before {
  content: "\f6f1"; }

.la-houzz:before {
  content: "\f27c"; }

.la-hryvnia:before {
  content: "\f6f2"; }

.la-html5:before {
  content: "\f13b"; }

.la-hubspot:before {
  content: "\f3b2"; }

.la-i-cursor:before {
  content: "\f246"; }

.la-ice-cream:before {
  content: "\f810"; }

.la-icicles:before {
  content: "\f7ad"; }

.la-icons:before {
  content: "\f86d"; }

.la-id-badge:before {
  content: "\f2c1"; }

.la-id-card:before {
  content: "\f2c2"; }

.la-id-card-alt:before {
  content: "\f47f"; }

.la-igloo:before {
  content: "\f7ae"; }

.la-image:before {
  content: "\f03e"; }

.la-images:before {
  content: "\f302"; }

.la-imdb:before {
  content: "\f2d8"; }

.la-inbox:before {
  content: "\f01c"; }

.la-indent:before {
  content: "\f03c"; }

.la-industry:before {
  content: "\f275"; }

.la-infinity:before {
  content: "\f534"; }

.la-info:before {
  content: "\f129"; }

.la-info-circle:before {
  content: "\f05a"; }

.la-instagram:before {
  content: "\f16d"; }

.la-intercom:before {
  content: "\f7af"; }

.la-internet-explorer:before {
  content: "\f26b"; }

.la-invision:before {
  content: "\f7b0"; }

.la-ioxhost:before {
  content: "\f208"; }

.la-italic:before {
  content: "\f033"; }

.la-itch-io:before {
  content: "\f83a"; }

.la-itunes:before {
  content: "\f3b4"; }

.la-itunes-note:before {
  content: "\f3b5"; }

.la-java:before {
  content: "\f4e4"; }

.la-jedi:before {
  content: "\f669"; }

.la-jedi-order:before {
  content: "\f50e"; }

.la-jenkins:before {
  content: "\f3b6"; }

.la-jira:before {
  content: "\f7b1"; }

.la-joget:before {
  content: "\f3b7"; }

.la-joint:before {
  content: "\f595"; }

.la-joomla:before {
  content: "\f1aa"; }

.la-journal-whills:before {
  content: "\f66a"; }

.la-js:before {
  content: "\f3b8"; }

.la-js-square:before {
  content: "\f3b9"; }

.la-jsfiddle:before {
  content: "\f1cc"; }

.la-kaaba:before {
  content: "\f66b"; }

.la-kaggle:before {
  content: "\f5fa"; }

.la-key:before {
  content: "\f084"; }

.la-keybase:before {
  content: "\f4f5"; }

.la-keyboard:before {
  content: "\f11c"; }

.la-keycdn:before {
  content: "\f3ba"; }

.la-khanda:before {
  content: "\f66d"; }

.la-kickstarter:before {
  content: "\f3bb"; }

.la-kickstarter-k:before {
  content: "\f3bc"; }

.la-kiss:before {
  content: "\f596"; }

.la-kiss-beam:before {
  content: "\f597"; }

.la-kiss-wink-heart:before {
  content: "\f598"; }

.la-kiwi-bird:before {
  content: "\f535"; }

.la-korvue:before {
  content: "\f42f"; }

.la-landmark:before {
  content: "\f66f"; }

.la-language:before {
  content: "\f1ab"; }

.la-laptop:before {
  content: "\f109"; }

.la-laptop-code:before {
  content: "\f5fc"; }

.la-laptop-medical:before {
  content: "\f812"; }

.la-laravel:before {
  content: "\f3bd"; }

.la-lastfm:before {
  content: "\f202"; }

.la-lastfm-square:before {
  content: "\f203"; }

.la-laugh:before {
  content: "\f599"; }

.la-laugh-beam:before {
  content: "\f59a"; }

.la-laugh-squint:before {
  content: "\f59b"; }

.la-laugh-wink:before {
  content: "\f59c"; }

.la-layer-group:before {
  content: "\f5fd"; }

.la-leaf:before {
  content: "\f06c"; }

.la-leanpub:before {
  content: "\f212"; }

.la-lemon:before {
  content: "\f094"; }

.la-less:before {
  content: "\f41d"; }

.la-less-than:before {
  content: "\f536"; }

.la-less-than-equal:before {
  content: "\f537"; }

.la-level-down-alt:before {
  content: "\f3be"; }

.la-level-up-alt:before {
  content: "\f3bf"; }

.la-life-ring:before {
  content: "\f1cd"; }

.la-lightbulb:before {
  content: "\f0eb"; }

.la-line:before {
  content: "\f3c0"; }

.la-link:before {
  content: "\f0c1"; }

.la-linkedin:before {
  content: "\f08c"; }

.la-linkedin-in:before {
  content: "\f0e1"; }

.la-linode:before {
  content: "\f2b8"; }

.la-linux:before {
  content: "\f17c"; }

.la-lira-sign:before {
  content: "\f195"; }

.la-list:before {
  content: "\f03a"; }

.la-list-alt:before {
  content: "\f022"; }

.la-list-ol:before {
  content: "\f0cb"; }

.la-list-ul:before {
  content: "\f0ca"; }

.la-location-arrow:before {
  content: "\f124"; }

.la-lock:before {
  content: "\f023"; }

.la-lock-open:before {
  content: "\f3c1"; }

.la-long-arrow-alt-down:before {
  content: "\f309"; }

.la-long-arrow-alt-left:before {
  content: "\f30a"; }

.la-long-arrow-alt-right:before {
  content: "\f30b"; }

.la-long-arrow-alt-up:before {
  content: "\f30c"; }

.la-low-vision:before {
  content: "\f2a8"; }

.la-luggage-cart:before {
  content: "\f59d"; }

.la-lyft:before {
  content: "\f3c3"; }

.la-magento:before {
  content: "\f3c4"; }

.la-magic:before {
  content: "\f0d0"; }

.la-magnet:before {
  content: "\f076"; }

.la-mail-bulk:before {
  content: "\f674"; }

.la-mailchimp:before {
  content: "\f59e"; }

.la-male:before {
  content: "\f183"; }

.la-mandalorian:before {
  content: "\f50f"; }

.la-map:before {
  content: "\f279"; }

.la-map-marked:before {
  content: "\f59f"; }

.la-map-marked-alt:before {
  content: "\f5a0"; }

.la-map-marker:before {
  content: "\f041"; }

.la-map-marker-alt:before {
  content: "\f3c5"; }

.la-map-pin:before {
  content: "\f276"; }

.la-map-signs:before {
  content: "\f277"; }

.la-markdown:before {
  content: "\f60f"; }

.la-marker:before {
  content: "\f5a1"; }

.la-mars:before {
  content: "\f222"; }

.la-mars-double:before {
  content: "\f227"; }

.la-mars-stroke:before {
  content: "\f229"; }

.la-mars-stroke-h:before {
  content: "\f22b"; }

.la-mars-stroke-v:before {
  content: "\f22a"; }

.la-mask:before {
  content: "\f6fa"; }

.la-mastodon:before {
  content: "\f4f6"; }

.la-maxcdn:before {
  content: "\f136"; }

.la-mdb:before {
  content: "\f8ca"; }

.la-medal:before {
  content: "\f5a2"; }

.la-medapps:before {
  content: "\f3c6"; }

.la-medium:before {
  content: "\f23a"; }

.la-medium-m:before {
  content: "\f3c7"; }

.la-medkit:before {
  content: "\f0fa"; }

.la-medrt:before {
  content: "\f3c8"; }

.la-meetup:before {
  content: "\f2e0"; }

.la-megaport:before {
  content: "\f5a3"; }

.la-meh:before {
  content: "\f11a"; }

.la-meh-blank:before {
  content: "\f5a4"; }

.la-meh-rolling-eyes:before {
  content: "\f5a5"; }

.la-memory:before {
  content: "\f538"; }

.la-mendeley:before {
  content: "\f7b3"; }

.la-menorah:before {
  content: "\f676"; }

.la-mercury:before {
  content: "\f223"; }

.la-meteor:before {
  content: "\f753"; }

.la-microchip:before {
  content: "\f2db"; }

.la-microphone:before {
  content: "\f130"; }

.la-microphone-alt:before {
  content: "\f3c9"; }

.la-microphone-alt-slash:before {
  content: "\f539"; }

.la-microphone-slash:before {
  content: "\f131"; }

.la-microscope:before {
  content: "\f610"; }

.la-microsoft:before {
  content: "\f3ca"; }

.la-minus:before {
  content: "\f068"; }

.la-minus-circle:before {
  content: "\f056"; }

.la-minus-square:before {
  content: "\f146"; }

.la-mitten:before {
  content: "\f7b5"; }

.la-mix:before {
  content: "\f3cb"; }

.la-mixcloud:before {
  content: "\f289"; }

.la-mizuni:before {
  content: "\f3cc"; }

.la-mobile:before {
  content: "\f10b"; }

.la-mobile-alt:before {
  content: "\f3cd"; }

.la-modx:before {
  content: "\f285"; }

.la-monero:before {
  content: "\f3d0"; }

.la-money-bill:before {
  content: "\f0d6"; }

.la-money-bill-alt:before {
  content: "\f3d1"; }

.la-money-bill-wave:before {
  content: "\f53a"; }

.la-money-bill-wave-alt:before {
  content: "\f53b"; }

.la-money-check:before {
  content: "\f53c"; }

.la-money-check-alt:before {
  content: "\f53d"; }

.la-monument:before {
  content: "\f5a6"; }

.la-moon:before {
  content: "\f186"; }

.la-mortar-pestle:before {
  content: "\f5a7"; }

.la-mosque:before {
  content: "\f678"; }

.la-motorcycle:before {
  content: "\f21c"; }

.la-mountain:before {
  content: "\f6fc"; }

.la-mouse:before {
  content: "\f8cc"; }

.la-mouse-pointer:before {
  content: "\f245"; }

.la-mug-hot:before {
  content: "\f7b6"; }

.la-music:before {
  content: "\f001"; }

.la-napster:before {
  content: "\f3d2"; }

.la-neos:before {
  content: "\f612"; }

.la-network-wired:before {
  content: "\f6ff"; }

.la-neuter:before {
  content: "\f22c"; }

.la-newspaper:before {
  content: "\f1ea"; }

.la-nimblr:before {
  content: "\f5a8"; }

.la-node:before {
  content: "\f419"; }

.la-node-js:before {
  content: "\f3d3"; }

.la-not-equal:before {
  content: "\f53e"; }

.la-notes-medical:before {
  content: "\f481"; }

.la-npm:before {
  content: "\f3d4"; }

.la-ns8:before {
  content: "\f3d5"; }

.la-nutritionix:before {
  content: "\f3d6"; }

.la-object-group:before {
  content: "\f247"; }

.la-object-ungroup:before {
  content: "\f248"; }

.la-odnoklassniki:before {
  content: "\f263"; }

.la-odnoklassniki-square:before {
  content: "\f264"; }

.la-oil-can:before {
  content: "\f613"; }

.la-old-republic:before {
  content: "\f510"; }

.la-om:before {
  content: "\f679"; }

.la-opencart:before {
  content: "\f23d"; }

.la-openid:before {
  content: "\f19b"; }

.la-opera:before {
  content: "\f26a"; }

.la-optin-monster:before {
  content: "\f23c"; }

.la-orcid:before {
  content: "\f8d2"; }

.la-osi:before {
  content: "\f41a"; }

.la-otter:before {
  content: "\f700"; }

.la-outdent:before {
  content: "\f03b"; }

.la-page4:before {
  content: "\f3d7"; }

.la-pagelines:before {
  content: "\f18c"; }

.la-pager:before {
  content: "\f815"; }

.la-paint-brush:before {
  content: "\f1fc"; }

.la-paint-roller:before {
  content: "\f5aa"; }

.la-palette:before {
  content: "\f53f"; }

.la-palfed:before {
  content: "\f3d8"; }

.la-pallet:before {
  content: "\f482"; }

.la-paper-plane:before {
  content: "\f1d8"; }

.la-paperclip:before {
  content: "\f0c6"; }

.la-parachute-box:before {
  content: "\f4cd"; }

.la-paragraph:before {
  content: "\f1dd"; }

.la-parking:before {
  content: "\f540"; }

.la-passport:before {
  content: "\f5ab"; }

.la-pastafarianism:before {
  content: "\f67b"; }

.la-paste:before {
  content: "\f0ea"; }

.la-patreon:before {
  content: "\f3d9"; }

.la-pause:before {
  content: "\f04c"; }

.la-pause-circle:before {
  content: "\f28b"; }

.la-paw:before {
  content: "\f1b0"; }

.la-paypal:before {
  content: "\f1ed"; }

.la-peace:before {
  content: "\f67c"; }

.la-pen:before {
  content: "\f304"; }

.la-pen-alt:before {
  content: "\f305"; }

.la-pen-fancy:before {
  content: "\f5ac"; }

.la-pen-nib:before {
  content: "\f5ad"; }

.la-pen-square:before {
  content: "\f14b"; }

.la-pencil-alt:before {
  content: "\f303"; }

.la-pencil-ruler:before {
  content: "\f5ae"; }

.la-penny-arcade:before {
  content: "\f704"; }

.la-people-carry:before {
  content: "\f4ce"; }

.la-pepper-hot:before {
  content: "\f816"; }

.la-percent:before {
  content: "\f295"; }

.la-percentage:before {
  content: "\f541"; }

.la-periscope:before {
  content: "\f3da"; }

.la-person-booth:before {
  content: "\f756"; }

.la-phabricator:before {
  content: "\f3db"; }

.la-phoenix-framework:before {
  content: "\f3dc"; }

.la-phoenix-squadron:before {
  content: "\f511"; }

.la-phone:before {
  content: "\f095"; }

.la-phone-alt:before {
  content: "\f879"; }

.la-phone-slash:before {
  content: "\f3dd"; }

.la-phone-square:before {
  content: "\f098"; }

.la-phone-square-alt:before {
  content: "\f87b"; }

.la-phone-volume:before {
  content: "\f2a0"; }

.la-photo-video:before {
  content: "\f87c"; }

.la-php:before {
  content: "\f457"; }

.la-pied-piper:before {
  content: "\f2ae"; }

.la-pied-piper-alt:before {
  content: "\f1a8"; }

.la-pied-piper-hat:before {
  content: "\f4e5"; }

.la-pied-piper-pp:before {
  content: "\f1a7"; }

.la-piggy-bank:before {
  content: "\f4d3"; }

.la-pills:before {
  content: "\f484"; }

.la-pinterest:before {
  content: "\f0d2"; }

.la-pinterest-p:before {
  content: "\f231"; }

.la-pinterest-square:before {
  content: "\f0d3"; }

.la-pizza-slice:before {
  content: "\f818"; }

.la-place-of-worship:before {
  content: "\f67f"; }

.la-plane:before {
  content: "\f072"; }

.la-plane-arrival:before {
  content: "\f5af"; }

.la-plane-departure:before {
  content: "\f5b0"; }

.la-play:before {
  content: "\f04b"; }

.la-play-circle:before {
  content: "\f144"; }

.la-playstation:before {
  content: "\f3df"; }

.la-plug:before {
  content: "\f1e6"; }

.la-plus:before {
  content: "\f067"; }

.la-plus-circle:before {
  content: "\f055"; }

.la-plus-square:before {
  content: "\f0fe"; }

.la-podcast:before {
  content: "\f2ce"; }

.la-poll:before {
  content: "\f681"; }

.la-poll-h:before {
  content: "\f682"; }

.la-poo:before {
  content: "\f2fe"; }

.la-poo-storm:before {
  content: "\f75a"; }

.la-poop:before {
  content: "\f619"; }

.la-portrait:before {
  content: "\f3e0"; }

.la-pound-sign:before {
  content: "\f154"; }

.la-power-off:before {
  content: "\f011"; }

.la-pray:before {
  content: "\f683"; }

.la-praying-hands:before {
  content: "\f684"; }

.la-prescription:before {
  content: "\f5b1"; }

.la-prescription-bottle:before {
  content: "\f485"; }

.la-prescription-bottle-alt:before {
  content: "\f486"; }

.la-print:before {
  content: "\f02f"; }

.la-procedures:before {
  content: "\f487"; }

.la-product-hunt:before {
  content: "\f288"; }

.la-project-diagram:before {
  content: "\f542"; }

.la-pushed:before {
  content: "\f3e1"; }

.la-puzzle-piece:before {
  content: "\f12e"; }

.la-python:before {
  content: "\f3e2"; }

.la-qq:before {
  content: "\f1d6"; }

.la-qrcode:before {
  content: "\f029"; }

.la-question:before {
  content: "\f128"; }

.la-question-circle:before {
  content: "\f059"; }

.la-quidditch:before {
  content: "\f458"; }

.la-quinscape:before {
  content: "\f459"; }

.la-quora:before {
  content: "\f2c4"; }

.la-quote-left:before {
  content: "\f10d"; }

.la-quote-right:before {
  content: "\f10e"; }

.la-quran:before {
  content: "\f687"; }

.la-r-project:before {
  content: "\f4f7"; }

.la-radiation:before {
  content: "\f7b9"; }

.la-radiation-alt:before {
  content: "\f7ba"; }

.la-rainbow:before {
  content: "\f75b"; }

.la-random:before {
  content: "\f074"; }

.la-raspberry-pi:before {
  content: "\f7bb"; }

.la-ravelry:before {
  content: "\f2d9"; }

.la-react:before {
  content: "\f41b"; }

.la-reacteurope:before {
  content: "\f75d"; }

.la-readme:before {
  content: "\f4d5"; }

.la-rebel:before {
  content: "\f1d0"; }

.la-receipt:before {
  content: "\f543"; }

.la-record-vinyl:before {
  content: "\f8d9"; }

.la-recycle:before {
  content: "\f1b8"; }

.la-red-river:before {
  content: "\f3e3"; }

.la-reddit:before {
  content: "\f1a1"; }

.la-reddit-alien:before {
  content: "\f281"; }

.la-reddit-square:before {
  content: "\f1a2"; }

.la-redhat:before {
  content: "\f7bc"; }

.la-redo:before {
  content: "\f01e"; }

.la-redo-alt:before {
  content: "\f2f9"; }

.la-registered:before {
  content: "\f25d"; }

.la-remove-format:before {
  content: "\f87d"; }

.la-renren:before {
  content: "\f18b"; }

.la-reply:before {
  content: "\f3e5"; }

.la-reply-all:before {
  content: "\f122"; }

.la-replyd:before {
  content: "\f3e6"; }

.la-republican:before {
  content: "\f75e"; }

.la-researchgate:before {
  content: "\f4f8"; }

.la-resolving:before {
  content: "\f3e7"; }

.la-restroom:before {
  content: "\f7bd"; }

.la-retweet:before {
  content: "\f079"; }

.la-rev:before {
  content: "\f5b2"; }

.la-ribbon:before {
  content: "\f4d6"; }

.la-ring:before {
  content: "\f70b"; }

.la-road:before {
  content: "\f018"; }

.la-robot:before {
  content: "\f544"; }

.la-rocket:before {
  content: "\f135"; }

.la-rocketchat:before {
  content: "\f3e8"; }

.la-rockrms:before {
  content: "\f3e9"; }

.la-route:before {
  content: "\f4d7"; }

.la-rss:before {
  content: "\f09e"; }

.la-rss-square:before {
  content: "\f143"; }

.la-ruble-sign:before {
  content: "\f158"; }

.la-ruler:before {
  content: "\f545"; }

.la-ruler-combined:before {
  content: "\f546"; }

.la-ruler-horizontal:before {
  content: "\f547"; }

.la-ruler-vertical:before {
  content: "\f548"; }

.la-running:before {
  content: "\f70c"; }

.la-rupee-sign:before {
  content: "\f156"; }

.la-sad-cry:before {
  content: "\f5b3"; }

.la-sad-tear:before {
  content: "\f5b4"; }

.la-safari:before {
  content: "\f267"; }

.la-salesforce:before {
  content: "\f83b"; }

.la-sass:before {
  content: "\f41e"; }

.la-satellite:before {
  content: "\f7bf"; }

.la-satellite-dish:before {
  content: "\f7c0"; }

.la-save:before {
  content: "\f0c7"; }

.la-schlix:before {
  content: "\f3ea"; }

.la-school:before {
  content: "\f549"; }

.la-screwdriver:before {
  content: "\f54a"; }

.la-scribd:before {
  content: "\f28a"; }

.la-scroll:before {
  content: "\f70e"; }

.la-sd-card:before {
  content: "\f7c2"; }

.la-search:before {
  content: "\f002"; }

.la-search-dollar:before {
  content: "\f688"; }

.la-search-location:before {
  content: "\f689"; }

.la-search-minus:before {
  content: "\f010"; }

.la-search-plus:before {
  content: "\f00e"; }

.la-searchengin:before {
  content: "\f3eb"; }

.la-seedling:before {
  content: "\f4d8"; }

.la-sellcast:before {
  content: "\f2da"; }

.la-sellsy:before {
  content: "\f213"; }

.la-server:before {
  content: "\f233"; }

.la-servicestack:before {
  content: "\f3ec"; }

.la-shapes:before {
  content: "\f61f"; }

.la-share:before {
  content: "\f064"; }

.la-share-alt:before {
  content: "\f1e0"; }

.la-share-alt-square:before {
  content: "\f1e1"; }

.la-share-square:before {
  content: "\f14d"; }

.la-shekel-sign:before {
  content: "\f20b"; }

.la-shield-alt:before {
  content: "\f3ed"; }

.la-ship:before {
  content: "\f21a"; }

.la-shipping-fast:before {
  content: "\f48b"; }

.la-shirtsinbulk:before {
  content: "\f214"; }

.la-shoe-prints:before {
  content: "\f54b"; }

.la-shopping-bag:before {
  content: "\f290"; }

.la-shopping-basket:before {
  content: "\f291"; }

.la-shopping-cart:before {
  content: "\f07a"; }

.la-shopware:before {
  content: "\f5b5"; }

.la-shower:before {
  content: "\f2cc"; }

.la-shuttle-van:before {
  content: "\f5b6"; }

.la-sign:before {
  content: "\f4d9"; }

.la-sign-in-alt:before {
  content: "\f2f6"; }

.la-sign-language:before {
  content: "\f2a7"; }

.la-sign-out-alt:before {
  content: "\f2f5"; }

.la-signal:before {
  content: "\f012"; }

.la-signature:before {
  content: "\f5b7"; }

.la-sim-card:before {
  content: "\f7c4"; }

.la-simplybuilt:before {
  content: "\f215"; }

.la-sistrix:before {
  content: "\f3ee"; }

.la-sitemap:before {
  content: "\f0e8"; }

.la-sith:before {
  content: "\f512"; }

.la-skating:before {
  content: "\f7c5"; }

.la-sketch:before {
  content: "\f7c6"; }

.la-skiing:before {
  content: "\f7c9"; }

.la-skiing-nordic:before {
  content: "\f7ca"; }

.la-skull:before {
  content: "\f54c"; }

.la-skull-crossbones:before {
  content: "\f714"; }

.la-skyatlas:before {
  content: "\f216"; }

.la-skype:before {
  content: "\f17e"; }

.la-slack:before {
  content: "\f198"; }

.la-slack-hash:before {
  content: "\f3ef"; }

.la-slash:before {
  content: "\f715"; }

.la-sleigh:before {
  content: "\f7cc"; }

.la-sliders-h:before {
  content: "\f1de"; }

.la-slideshare:before {
  content: "\f1e7"; }

.la-smile:before {
  content: "\f118"; }

.la-smile-beam:before {
  content: "\f5b8"; }

.la-smile-wink:before {
  content: "\f4da"; }

.la-smog:before {
  content: "\f75f"; }

.la-smoking:before {
  content: "\f48d"; }

.la-smoking-ban:before {
  content: "\f54d"; }

.la-sms:before {
  content: "\f7cd"; }

.la-snapchat:before {
  content: "\f2ab"; }

.la-snapchat-ghost:before {
  content: "\f2ac"; }

.la-snapchat-square:before {
  content: "\f2ad"; }

.la-snowboarding:before {
  content: "\f7ce"; }

.la-snowflake:before {
  content: "\f2dc"; }

.la-snowman:before {
  content: "\f7d0"; }

.la-snowplow:before {
  content: "\f7d2"; }

.la-socks:before {
  content: "\f696"; }

.la-solar-panel:before {
  content: "\f5ba"; }

.la-sort:before {
  content: "\f0dc"; }

.la-sort-alpha-down:before {
  content: "\f15d"; }

.la-sort-alpha-down-alt:before {
  content: "\f881"; }

.la-sort-alpha-up:before {
  content: "\f15e"; }

.la-sort-alpha-up-alt:before {
  content: "\f882"; }

.la-sort-amount-down:before {
  content: "\f160"; }

.la-sort-amount-down-alt:before {
  content: "\f884"; }

.la-sort-amount-up:before {
  content: "\f161"; }

.la-sort-amount-up-alt:before {
  content: "\f885"; }

.la-sort-down:before {
  content: "\f0dd"; }

.la-sort-numeric-down:before {
  content: "\f162"; }

.la-sort-numeric-down-alt:before {
  content: "\f886"; }

.la-sort-numeric-up:before {
  content: "\f163"; }

.la-sort-numeric-up-alt:before {
  content: "\f887"; }

.la-sort-up:before {
  content: "\f0de"; }

.la-soundcloud:before {
  content: "\f1be"; }

.la-sourcetree:before {
  content: "\f7d3"; }

.la-spa:before {
  content: "\f5bb"; }

.la-space-shuttle:before {
  content: "\f197"; }

.la-speakap:before {
  content: "\f3f3"; }

.la-speaker-deck:before {
  content: "\f83c"; }

.la-spell-check:before {
  content: "\f891"; }

.la-spider:before {
  content: "\f717"; }

.la-spinner:before {
  content: "\f110"; }

.la-splotch:before {
  content: "\f5bc"; }

.la-spotify:before {
  content: "\f1bc"; }

.la-spray-can:before {
  content: "\f5bd"; }

.la-square:before {
  content: "\f0c8"; }

.la-square-full:before {
  content: "\f45c"; }

.la-square-root-alt:before {
  content: "\f698"; }

.la-squarespace:before {
  content: "\f5be"; }

.la-stack-exchange:before {
  content: "\f18d"; }

.la-stack-overflow:before {
  content: "\f16c"; }

.la-stackpath:before {
  content: "\f842"; }

.la-stamp:before {
  content: "\f5bf"; }

.la-star:before {
  content: "\f005"; }

.la-star-and-crescent:before {
  content: "\f699"; }

.la-star-half:before {
  content: "\f089"; }

.la-star-half-alt:before {
  content: "\f5c0"; }

.la-star-of-david:before {
  content: "\f69a"; }

.la-star-of-life:before {
  content: "\f621"; }

.la-staylinked:before {
  content: "\f3f5"; }

.la-steam:before {
  content: "\f1b6"; }

.la-steam-square:before {
  content: "\f1b7"; }

.la-steam-symbol:before {
  content: "\f3f6"; }

.la-step-backward:before {
  content: "\f048"; }

.la-step-forward:before {
  content: "\f051"; }

.la-stethoscope:before {
  content: "\f0f1"; }

.la-sticker-mule:before {
  content: "\f3f7"; }

.la-sticky-note:before {
  content: "\f249"; }

.la-stop:before {
  content: "\f04d"; }

.la-stop-circle:before {
  content: "\f28d"; }

.la-stopwatch:before {
  content: "\f2f2"; }

.la-store:before {
  content: "\f54e"; }

.la-store-alt:before {
  content: "\f54f"; }

.la-strava:before {
  content: "\f428"; }

.la-stream:before {
  content: "\f550"; }

.la-street-view:before {
  content: "\f21d"; }

.la-strikethrough:before {
  content: "\f0cc"; }

.la-stripe:before {
  content: "\f429"; }

.la-stripe-s:before {
  content: "\f42a"; }

.la-stroopwafel:before {
  content: "\f551"; }

.la-studiovinari:before {
  content: "\f3f8"; }

.la-stumbleupon:before {
  content: "\f1a4"; }

.la-stumbleupon-circle:before {
  content: "\f1a3"; }

.la-subscript:before {
  content: "\f12c"; }

.la-subway:before {
  content: "\f239"; }

.la-suitcase:before {
  content: "\f0f2"; }

.la-suitcase-rolling:before {
  content: "\f5c1"; }

.la-sun:before {
  content: "\f185"; }

.la-superpowers:before {
  content: "\f2dd"; }

.la-superscript:before {
  content: "\f12b"; }

.la-supple:before {
  content: "\f3f9"; }

.la-surprise:before {
  content: "\f5c2"; }

.la-suse:before {
  content: "\f7d6"; }

.la-swatchbook:before {
  content: "\f5c3"; }

.la-swift:before {
  content: "\f8e1"; }

.la-swimmer:before {
  content: "\f5c4"; }

.la-swimming-pool:before {
  content: "\f5c5"; }

.la-symfony:before {
  content: "\f83d"; }

.la-synagogue:before {
  content: "\f69b"; }

.la-sync:before {
  content: "\f021"; }

.la-sync-alt:before {
  content: "\f2f1"; }

.la-syringe:before {
  content: "\f48e"; }

.la-table:before {
  content: "\f0ce"; }

.la-table-tennis:before {
  content: "\f45d"; }

.la-tablet:before {
  content: "\f10a"; }

.la-tablet-alt:before {
  content: "\f3fa"; }

.la-tablets:before {
  content: "\f490"; }

.la-tachometer-alt:before {
  content: "\f3fd"; }

.la-tag:before {
  content: "\f02b"; }

.la-tags:before {
  content: "\f02c"; }

.la-tape:before {
  content: "\f4db"; }

.la-tasks:before {
  content: "\f0ae"; }

.la-taxi:before {
  content: "\f1ba"; }

.la-teamspeak:before {
  content: "\f4f9"; }

.la-teeth:before {
  content: "\f62e"; }

.la-teeth-open:before {
  content: "\f62f"; }

.la-telegram:before {
  content: "\f2c6"; }

.la-telegram-plane:before {
  content: "\f3fe"; }

.la-temperature-high:before {
  content: "\f769"; }

.la-temperature-low:before {
  content: "\f76b"; }

.la-tencent-weibo:before {
  content: "\f1d5"; }

.la-tenge:before {
  content: "\f7d7"; }

.la-terminal:before {
  content: "\f120"; }

.la-text-height:before {
  content: "\f034"; }

.la-text-width:before {
  content: "\f035"; }

.la-th:before {
  content: "\f00a"; }

.la-th-large:before {
  content: "\f009"; }

.la-th-list:before {
  content: "\f00b"; }

.la-the-red-yeti:before {
  content: "\f69d"; }

.la-theater-masks:before {
  content: "\f630"; }

.la-themeco:before {
  content: "\f5c6"; }

.la-themeisle:before {
  content: "\f2b2"; }

.la-thermometer:before {
  content: "\f491"; }

.la-thermometer-empty:before {
  content: "\f2cb"; }

.la-thermometer-full:before {
  content: "\f2c7"; }

.la-thermometer-half:before {
  content: "\f2c9"; }

.la-thermometer-quarter:before {
  content: "\f2ca"; }

.la-thermometer-three-quarters:before {
  content: "\f2c8"; }

.la-think-peaks:before {
  content: "\f731"; }

.la-thumbs-down:before {
  content: "\f165"; }

.la-thumbs-up:before {
  content: "\f164"; }

.la-thumbtack:before {
  content: "\f08d"; }

.la-ticket-alt:before {
  content: "\f3ff"; }

.la-times:before {
  content: "\f00d"; }

.la-times-circle:before {
  content: "\f057"; }

.la-tint:before {
  content: "\f043"; }

.la-tint-slash:before {
  content: "\f5c7"; }

.la-tired:before {
  content: "\f5c8"; }

.la-toggle-off:before {
  content: "\f204"; }

.la-toggle-on:before {
  content: "\f205"; }

.la-toilet:before {
  content: "\f7d8"; }

.la-toilet-paper:before {
  content: "\f71e"; }

.la-toolbox:before {
  content: "\f552"; }

.la-tools:before {
  content: "\f7d9"; }

.la-tooth:before {
  content: "\f5c9"; }

.la-torah:before {
  content: "\f6a0"; }

.la-torii-gate:before {
  content: "\f6a1"; }

.la-tractor:before {
  content: "\f722"; }

.la-trade-federation:before {
  content: "\f513"; }

.la-trademark:before {
  content: "\f25c"; }

.la-traffic-light:before {
  content: "\f637"; }

.la-train:before {
  content: "\f238"; }

.la-tram:before {
  content: "\f7da"; }

.la-transgender:before {
  content: "\f224"; }

.la-transgender-alt:before {
  content: "\f225"; }

.la-trash:before {
  content: "\f1f8"; }

.la-trash-alt:before {
  content: "\f2ed"; }

.la-trash-restore:before {
  content: "\f829"; }

.la-trash-restore-alt:before {
  content: "\f82a"; }

.la-tree:before {
  content: "\f1bb"; }

.la-trello:before {
  content: "\f181"; }

.la-tripadvisor:before {
  content: "\f262"; }

.la-trophy:before {
  content: "\f091"; }

.la-truck:before {
  content: "\f0d1"; }

.la-truck-loading:before {
  content: "\f4de"; }

.la-truck-monster:before {
  content: "\f63b"; }

.la-truck-moving:before {
  content: "\f4df"; }

.la-truck-pickup:before {
  content: "\f63c"; }

.la-tshirt:before {
  content: "\f553"; }

.la-tty:before {
  content: "\f1e4"; }

.la-tumblr:before {
  content: "\f173"; }

.la-tumblr-square:before {
  content: "\f174"; }

.la-tv:before {
  content: "\f26c"; }

.la-twitch:before {
  content: "\f1e8"; }

.la-twitter:before {
  content: "\f099"; }

.la-twitter-square:before {
  content: "\f081"; }

.la-typo3:before {
  content: "\f42b"; }

.la-uber:before {
  content: "\f402"; }

.la-ubuntu:before {
  content: "\f7df"; }

.la-uikit:before {
  content: "\f403"; }

.la-umbraco:before {
  content: "\f8e8"; }

.la-umbrella:before {
  content: "\f0e9"; }

.la-umbrella-beach:before {
  content: "\f5ca"; }

.la-underline:before {
  content: "\f0cd"; }

.la-undo:before {
  content: "\f0e2"; }

.la-undo-alt:before {
  content: "\f2ea"; }

.la-uniregistry:before {
  content: "\f404"; }

.la-universal-access:before {
  content: "\f29a"; }

.la-university:before {
  content: "\f19c"; }

.la-unlink:before {
  content: "\f127"; }

.la-unlock:before {
  content: "\f09c"; }

.la-unlock-alt:before {
  content: "\f13e"; }

.la-untappd:before {
  content: "\f405"; }

.la-upload:before {
  content: "\f093"; }

.la-ups:before {
  content: "\f7e0"; }

.la-usb:before {
  content: "\f287"; }

.la-user:before {
  content: "\f007"; }

.la-user-alt:before {
  content: "\f406"; }

.la-user-alt-slash:before {
  content: "\f4fa"; }

.la-user-astronaut:before {
  content: "\f4fb"; }

.la-user-check:before {
  content: "\f4fc"; }

.la-user-circle:before {
  content: "\f2bd"; }

.la-user-clock:before {
  content: "\f4fd"; }

.la-user-cog:before {
  content: "\f4fe"; }

.la-user-edit:before {
  content: "\f4ff"; }

.la-user-friends:before {
  content: "\f500"; }

.la-user-graduate:before {
  content: "\f501"; }

.la-user-injured:before {
  content: "\f728"; }

.la-user-lock:before {
  content: "\f502"; }

.la-user-md:before {
  content: "\f0f0"; }

.la-user-minus:before {
  content: "\f503"; }

.la-user-ninja:before {
  content: "\f504"; }

.la-user-nurse:before {
  content: "\f82f"; }

.la-user-plus:before {
  content: "\f234"; }

.la-user-secret:before {
  content: "\f21b"; }

.la-user-shield:before {
  content: "\f505"; }

.la-user-slash:before {
  content: "\f506"; }

.la-user-tag:before {
  content: "\f507"; }

.la-user-tie:before {
  content: "\f508"; }

.la-user-times:before {
  content: "\f235"; }

.la-users:before {
  content: "\f0c0"; }

.la-users-cog:before {
  content: "\f509"; }

.la-usps:before {
  content: "\f7e1"; }

.la-ussunnah:before {
  content: "\f407"; }

.la-utensil-spoon:before {
  content: "\f2e5"; }

.la-utensils:before {
  content: "\f2e7"; }

.la-vaadin:before {
  content: "\f408"; }

.la-vector-square:before {
  content: "\f5cb"; }

.la-venus:before {
  content: "\f221"; }

.la-venus-double:before {
  content: "\f226"; }

.la-venus-mars:before {
  content: "\f228"; }

.la-viacoin:before {
  content: "\f237"; }

.la-viadeo:before {
  content: "\f2a9"; }

.la-viadeo-square:before {
  content: "\f2aa"; }

.la-vial:before {
  content: "\f492"; }

.la-vials:before {
  content: "\f493"; }

.la-viber:before {
  content: "\f409"; }

.la-video:before {
  content: "\f03d"; }

.la-video-slash:before {
  content: "\f4e2"; }

.la-vihara:before {
  content: "\f6a7"; }

.la-vimeo:before {
  content: "\f40a"; }

.la-vimeo-square:before {
  content: "\f194"; }

.la-vimeo-v:before {
  content: "\f27d"; }

.la-vine:before {
  content: "\f1ca"; }

.la-vk:before {
  content: "\f189"; }

.la-vnv:before {
  content: "\f40b"; }

.la-voicemail:before {
  content: "\f897"; }

.la-volleyball-ball:before {
  content: "\f45f"; }

.la-volume-down:before {
  content: "\f027"; }

.la-volume-mute:before {
  content: "\f6a9"; }

.la-volume-off:before {
  content: "\f026"; }

.la-volume-up:before {
  content: "\f028"; }

.la-vote-yea:before {
  content: "\f772"; }

.la-vr-cardboard:before {
  content: "\f729"; }

.la-vuejs:before {
  content: "\f41f"; }

.la-walking:before {
  content: "\f554"; }

.la-wallet:before {
  content: "\f555"; }

.la-warehouse:before {
  content: "\f494"; }

.la-water:before {
  content: "\f773"; }

.la-wave-square:before {
  content: "\f83e"; }

.la-waze:before {
  content: "\f83f"; }

.la-weebly:before {
  content: "\f5cc"; }

.la-weibo:before {
  content: "\f18a"; }

.la-weight:before {
  content: "\f496"; }

.la-weight-hanging:before {
  content: "\f5cd"; }

.la-weixin:before {
  content: "\f1d7"; }

.la-whatsapp:before {
  content: "\f232"; }

.la-whatsapp-square:before {
  content: "\f40c"; }

.la-wheelchair:before {
  content: "\f193"; }

.la-whmcs:before {
  content: "\f40d"; }

.la-wifi:before {
  content: "\f1eb"; }

.la-wikipedia-w:before {
  content: "\f266"; }

.la-wind:before {
  content: "\f72e"; }

.la-window-close:before {
  content: "\f410"; }

.la-window-maximize:before {
  content: "\f2d0"; }

.la-window-minimize:before {
  content: "\f2d1"; }

.la-window-restore:before {
  content: "\f2d2"; }

.la-windows:before {
  content: "\f17a"; }

.la-wine-bottle:before {
  content: "\f72f"; }

.la-wine-glass:before {
  content: "\f4e3"; }

.la-wine-glass-alt:before {
  content: "\f5ce"; }

.la-wix:before {
  content: "\f5cf"; }

.la-wizards-of-the-coast:before {
  content: "\f730"; }

.la-wolf-pack-battalion:before {
  content: "\f514"; }

.la-won-sign:before {
  content: "\f159"; }

.la-wordpress:before {
  content: "\f19a"; }

.la-wordpress-simple:before {
  content: "\f411"; }

.la-wpbeginner:before {
  content: "\f297"; }

.la-wpexplorer:before {
  content: "\f2de"; }

.la-wpforms:before {
  content: "\f298"; }

.la-wpressr:before {
  content: "\f3e4"; }

.la-wrench:before {
  content: "\f0ad"; }

.la-x-ray:before {
  content: "\f497"; }

.la-xbox:before {
  content: "\f412"; }

.la-xing:before {
  content: "\f168"; }

.la-xing-square:before {
  content: "\f169"; }

.la-y-combinator:before {
  content: "\f23b"; }

.la-yahoo:before {
  content: "\f19e"; }

.la-yammer:before {
  content: "\f840"; }

.la-yandex:before {
  content: "\f413"; }

.la-yandex-international:before {
  content: "\f414"; }

.la-yarn:before {
  content: "\f7e3"; }

.la-yelp:before {
  content: "\f1e9"; }

.la-yen-sign:before {
  content: "\f157"; }

.la-yin-yang:before {
  content: "\f6ad"; }

.la-yoast:before {
  content: "\f2b1"; }

.la-youtube:before {
  content: "\f167"; }

.la-youtube-square:before {
  content: "\f431"; }

.la-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@font-face {
  font-family: 'Line Awesome Brands';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../fonts/la-brands-400.eot);
  src: url(../fonts/la-brands-400.eot?#iefix) format("embedded-opentype"), url(../fonts/la-brands-400.woff2) format("woff2"), url(../fonts/la-brands-400.woff) format("woff"), url(../fonts/la-brands-400.ttf) format("truetype"), url(../fonts/la-brands-400.svg#lineawesome) format("svg"); }

.lab {
  font-family: 'Line Awesome Brands'; }

@font-face {
  font-family: 'Line Awesome Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../fonts/la-regular-400.eot);
  src: url(../fonts/la-regular-400.eot?#iefix) format("embedded-opentype"), url(../fonts/la-regular-400.woff2) format("woff2"), url(../fonts/la-regular-400.woff) format("woff"), url(../fonts/la-regular-400.ttf) format("truetype"), url(../fonts/la-regular-400.svg#lineawesome) format("svg"); }

.lar {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

@font-face {
  font-family: 'Line Awesome Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../fonts/la-solid-900.eot);
  src: url(../fonts/la-solid-900.eot?#iefix) format("embedded-opentype"), url(../fonts/la-solid-900.woff2) format("woff2"), url(../fonts/la-solid-900.woff) format("woff"), url(../fonts/la-solid-900.ttf) format("truetype"), url(../fonts/la-solid-900.svg#lineawesome) format("svg"); }

.la, .las {
  font-family: 'Line Awesome Free';
  font-weight: 900; }

.la.la-glass:before {
  content: "\f000"; }

.la.la-meetup {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-star-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-star-o:before {
  content: "\f005"; }

.la.la-remove:before {
  content: "\f00d"; }

.la.la-close:before {
  content: "\f00d"; }

.la.la-gear:before {
  content: "\f013"; }

.la.la-trash-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-trash-o:before {
  content: "\f2ed"; }

.la.la-file-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-o:before {
  content: "\f15b"; }

.la.la-clock-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-clock-o:before {
  content: "\f017"; }

.la.la-arrow-circle-o-down {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-arrow-circle-o-down:before {
  content: "\f358"; }

.la.la-arrow-circle-o-up {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-arrow-circle-o-up:before {
  content: "\f35b"; }

.la.la-play-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-play-circle-o:before {
  content: "\f144"; }

.la.la-repeat:before {
  content: "\f01e"; }

.la.la-rotate-right:before {
  content: "\f01e"; }

.la.la-refresh:before {
  content: "\f021"; }

.la.la-list-alt {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-dedent:before {
  content: "\f03b"; }

.la.la-video-camera:before {
  content: "\f03d"; }

.la.la-picture-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-picture-o:before {
  content: "\f03e"; }

.la.la-photo {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-photo:before {
  content: "\f03e"; }

.la.la-image {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-image:before {
  content: "\f03e"; }

.la.la-pencil:before {
  content: "\f303"; }

.la.la-map-marker:before {
  content: "\f3c5"; }

.la.la-pencil-square-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-pencil-square-o:before {
  content: "\f044"; }

.la.la-share-square-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-share-square-o:before {
  content: "\f14d"; }

.la.la-check-square-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-check-square-o:before {
  content: "\f14a"; }

.la.la-arrows:before {
  content: "\f0b2"; }

.la.la-times-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-times-circle-o:before {
  content: "\f057"; }

.la.la-check-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-check-circle-o:before {
  content: "\f058"; }

.la.la-mail-forward:before {
  content: "\f064"; }

.la.la-eye {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-eye-slash {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-warning:before {
  content: "\f071"; }

.la.la-calendar:before {
  content: "\f073"; }

.la.la-arrows-v:before {
  content: "\f338"; }

.la.la-arrows-h:before {
  content: "\f337"; }

.la.la-bar-chart {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-bar-chart:before {
  content: "\f080"; }

.la.la-bar-chart-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-bar-chart-o:before {
  content: "\f080"; }

.la.la-twitter-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-facebook-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gears:before {
  content: "\f085"; }

.la.la-thumbs-o-up {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-thumbs-o-up:before {
  content: "\f164"; }

.la.la-thumbs-o-down {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-thumbs-o-down:before {
  content: "\f165"; }

.la.la-heart-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-heart-o:before {
  content: "\f004"; }

.la.la-sign-out:before {
  content: "\f2f5"; }

.la.la-linkedin-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-linkedin-square:before {
  content: "\f08c"; }

.la.la-thumb-tack:before {
  content: "\f08d"; }

.la.la-external-link:before {
  content: "\f35d"; }

.la.la-sign-in:before {
  content: "\f2f6"; }

.la.la-github-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-lemon-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-lemon-o:before {
  content: "\f094"; }

.la.la-square-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-square-o:before {
  content: "\f0c8"; }

.la.la-bookmark-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-bookmark-o:before {
  content: "\f02e"; }

.la.la-twitter {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-facebook {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-facebook:before {
  content: "\f39e"; }

.la.la-facebook-f {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-facebook-f:before {
  content: "\f39e"; }

.la.la-github {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-credit-card {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-feed:before {
  content: "\f09e"; }

.la.la-hdd-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hdd-o:before {
  content: "\f0a0"; }

.la.la-hand-o-right {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-o-right:before {
  content: "\f0a4"; }

.la.la-hand-o-left {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-o-left:before {
  content: "\f0a5"; }

.la.la-hand-o-up {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-o-up:before {
  content: "\f0a6"; }

.la.la-hand-o-down {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-o-down:before {
  content: "\f0a7"; }

.la.la-arrows-alt:before {
  content: "\f31e"; }

.la.la-group:before {
  content: "\f0c0"; }

.la.la-chain:before {
  content: "\f0c1"; }

.la.la-scissors:before {
  content: "\f0c4"; }

.la.la-files-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-files-o:before {
  content: "\f0c5"; }

.la.la-floppy-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-floppy-o:before {
  content: "\f0c7"; }

.la.la-navicon:before {
  content: "\f0c9"; }

.la.la-reorder:before {
  content: "\f0c9"; }

.la.la-pinterest {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-pinterest-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-plus-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-plus {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-plus:before {
  content: "\f0d5"; }

.la.la-money {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-money:before {
  content: "\f3d1"; }

.la.la-unsorted:before {
  content: "\f0dc"; }

.la.la-sort-desc:before {
  content: "\f0dd"; }

.la.la-sort-asc:before {
  content: "\f0de"; }

.la.la-linkedin {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-linkedin:before {
  content: "\f0e1"; }

.la.la-rotate-left:before {
  content: "\f0e2"; }

.la.la-legal:before {
  content: "\f0e3"; }

.la.la-tachometer:before {
  content: "\f3fd"; }

.la.la-dashboard:before {
  content: "\f3fd"; }

.la.la-comment-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-comment-o:before {
  content: "\f075"; }

.la.la-comments-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-comments-o:before {
  content: "\f086"; }

.la.la-flash:before {
  content: "\f0e7"; }

.la.la-clipboard {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-paste {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-paste:before {
  content: "\f328"; }

.la.la-lightbulb-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-lightbulb-o:before {
  content: "\f0eb"; }

.la.la-exchange:before {
  content: "\f362"; }

.la.la-cloud-download:before {
  content: "\f381"; }

.la.la-cloud-upload:before {
  content: "\f382"; }

.la.la-bell-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-bell-o:before {
  content: "\f0f3"; }

.la.la-cutlery:before {
  content: "\f2e7"; }

.la.la-file-text-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-text-o:before {
  content: "\f15c"; }

.la.la-building-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-building-o:before {
  content: "\f1ad"; }

.la.la-hospital-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hospital-o:before {
  content: "\f0f8"; }

.la.la-tablet:before {
  content: "\f3fa"; }

.la.la-mobile:before {
  content: "\f3cd"; }

.la.la-mobile-phone:before {
  content: "\f3cd"; }

.la.la-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-circle-o:before {
  content: "\f111"; }

.la.la-mail-reply:before {
  content: "\f3e5"; }

.la.la-github-alt {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-folder-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-folder-o:before {
  content: "\f07b"; }

.la.la-folder-open-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-folder-open-o:before {
  content: "\f07c"; }

.la.la-smile-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-smile-o:before {
  content: "\f118"; }

.la.la-frown-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-frown-o:before {
  content: "\f119"; }

.la.la-meh-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-meh-o:before {
  content: "\f11a"; }

.la.la-keyboard-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-keyboard-o:before {
  content: "\f11c"; }

.la.la-flag-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-flag-o:before {
  content: "\f024"; }

.la.la-mail-reply-all:before {
  content: "\f122"; }

.la.la-star-half-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-star-half-o:before {
  content: "\f089"; }

.la.la-star-half-empty {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-star-half-empty:before {
  content: "\f089"; }

.la.la-star-half-full {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-star-half-full:before {
  content: "\f089"; }

.la.la-code-fork:before {
  content: "\f126"; }

.la.la-chain-broken:before {
  content: "\f127"; }

.la.la-shield:before {
  content: "\f3ed"; }

.la.la-calendar-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-calendar-o:before {
  content: "\f133"; }

.la.la-maxcdn {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-html5 {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-css3 {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ticket:before {
  content: "\f3ff"; }

.la.la-minus-square-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-minus-square-o:before {
  content: "\f146"; }

.la.la-level-up:before {
  content: "\f3bf"; }

.la.la-level-down:before {
  content: "\f3be"; }

.la.la-pencil-square:before {
  content: "\f14b"; }

.la.la-external-link-square:before {
  content: "\f360"; }

.la.la-compass {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-caret-square-o-down {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-caret-square-o-down:before {
  content: "\f150"; }

.la.la-toggle-down {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-toggle-down:before {
  content: "\f150"; }

.la.la-caret-square-o-up {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-caret-square-o-up:before {
  content: "\f151"; }

.la.la-toggle-up {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-toggle-up:before {
  content: "\f151"; }

.la.la-caret-square-o-right {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-caret-square-o-right:before {
  content: "\f152"; }

.la.la-toggle-right {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-toggle-right:before {
  content: "\f152"; }

.la.la-eur:before {
  content: "\f153"; }

.la.la-euro:before {
  content: "\f153"; }

.la.la-gbp:before {
  content: "\f154"; }

.la.la-usd:before {
  content: "\f155"; }

.la.la-dollar:before {
  content: "\f155"; }

.la.la-inr:before {
  content: "\f156"; }

.la.la-rupee:before {
  content: "\f156"; }

.la.la-jpy:before {
  content: "\f157"; }

.la.la-cny:before {
  content: "\f157"; }

.la.la-rmb:before {
  content: "\f157"; }

.la.la-yen:before {
  content: "\f157"; }

.la.la-rub:before {
  content: "\f158"; }

.la.la-ruble:before {
  content: "\f158"; }

.la.la-rouble:before {
  content: "\f158"; }

.la.la-krw:before {
  content: "\f159"; }

.la.la-won:before {
  content: "\f159"; }

.la.la-btc {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bitcoin {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bitcoin:before {
  content: "\f15a"; }

.la.la-file-text:before {
  content: "\f15c"; }

.la.la-sort-alpha-asc:before {
  content: "\f15d"; }

.la.la-sort-alpha-desc:before {
  content: "\f881"; }

.la.la-sort-amount-asc:before {
  content: "\f160"; }

.la.la-sort-amount-desc:before {
  content: "\f884"; }

.la.la-sort-numeric-asc:before {
  content: "\f162"; }

.la.la-sort-numeric-desc:before {
  content: "\f886"; }

.la.la-youtube-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-youtube {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-xing {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-xing-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-youtube-play {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-youtube-play:before {
  content: "\f167"; }

.la.la-dropbox {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-stack-overflow {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-instagram {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-flickr {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-adn {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bitbucket {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bitbucket-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bitbucket-square:before {
  content: "\f171"; }

.la.la-tumblr {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-tumblr-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-long-arrow-down:before {
  content: "\f309"; }

.la.la-long-arrow-up:before {
  content: "\f30c"; }

.la.la-long-arrow-left:before {
  content: "\f30a"; }

.la.la-long-arrow-right:before {
  content: "\f30b"; }

.la.la-apple {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-windows {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-android {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-linux {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-dribbble {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-skype {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-foursquare {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-trello {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gratipay {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gittip {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gittip:before {
  content: "\f184"; }

.la.la-sun-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-sun-o:before {
  content: "\f185"; }

.la.la-moon-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-moon-o:before {
  content: "\f186"; }

.la.la-vk {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-weibo {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-renren {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-pagelines {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-stack-exchange {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-arrow-circle-o-right {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-arrow-circle-o-right:before {
  content: "\f35a"; }

.la.la-arrow-circle-o-left {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-arrow-circle-o-left:before {
  content: "\f359"; }

.la.la-caret-square-o-left {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-caret-square-o-left:before {
  content: "\f191"; }

.la.la-toggle-left {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-toggle-left:before {
  content: "\f191"; }

.la.la-dot-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-dot-circle-o:before {
  content: "\f192"; }

.la.la-vimeo-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-try:before {
  content: "\f195"; }

.la.la-turkish-lira:before {
  content: "\f195"; }

.la.la-plus-square-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-plus-square-o:before {
  content: "\f0fe"; }

.la.la-slack {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wordpress {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-openid {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-institution:before {
  content: "\f19c"; }

.la.la-bank:before {
  content: "\f19c"; }

.la.la-mortar-board:before {
  content: "\f19d"; }

.la.la-yahoo {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-reddit {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-reddit-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-stumbleupon-circle {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-stumbleupon {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-delicious {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-digg {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-pied-piper-pp {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-pied-piper-alt {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-drupal {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-joomla {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-spoon:before {
  content: "\f2e5"; }

.la.la-behance {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-behance-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-steam {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-steam-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-automobile:before {
  content: "\f1b9"; }

.la.la-cab:before {
  content: "\f1ba"; }

.la.la-envelope-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-envelope-o:before {
  content: "\f0e0"; }

.la.la-deviantart {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-soundcloud {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-file-pdf-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-pdf-o:before {
  content: "\f1c1"; }

.la.la-file-word-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-word-o:before {
  content: "\f1c2"; }

.la.la-file-excel-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-excel-o:before {
  content: "\f1c3"; }

.la.la-file-powerpoint-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-powerpoint-o:before {
  content: "\f1c4"; }

.la.la-file-image-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-image-o:before {
  content: "\f1c5"; }

.la.la-file-photo-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-photo-o:before {
  content: "\f1c5"; }

.la.la-file-picture-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-picture-o:before {
  content: "\f1c5"; }

.la.la-file-archive-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-archive-o:before {
  content: "\f1c6"; }

.la.la-file-zip-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-zip-o:before {
  content: "\f1c6"; }

.la.la-file-audio-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-audio-o:before {
  content: "\f1c7"; }

.la.la-file-sound-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-sound-o:before {
  content: "\f1c7"; }

.la.la-file-video-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-video-o:before {
  content: "\f1c8"; }

.la.la-file-movie-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-movie-o:before {
  content: "\f1c8"; }

.la.la-file-code-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-file-code-o:before {
  content: "\f1c9"; }

.la.la-vine {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-codepen {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-jsfiddle {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-life-ring {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-life-bouy {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-life-bouy:before {
  content: "\f1cd"; }

.la.la-life-buoy {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-life-buoy:before {
  content: "\f1cd"; }

.la.la-life-saver {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-life-saver:before {
  content: "\f1cd"; }

.la.la-support {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-support:before {
  content: "\f1cd"; }

.la.la-circle-o-notch:before {
  content: "\f1ce"; }

.la.la-rebel {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ra {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ra:before {
  content: "\f1d0"; }

.la.la-resistance {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-resistance:before {
  content: "\f1d0"; }

.la.la-empire {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ge {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ge:before {
  content: "\f1d1"; }

.la.la-git-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-git {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-hacker-news {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-y-combinator-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-y-combinator-square:before {
  content: "\f1d4"; }

.la.la-yc-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-yc-square:before {
  content: "\f1d4"; }

.la.la-tencent-weibo {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-qq {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-weixin {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wechat {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wechat:before {
  content: "\f1d7"; }

.la.la-send:before {
  content: "\f1d8"; }

.la.la-paper-plane-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-paper-plane-o:before {
  content: "\f1d8"; }

.la.la-send-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-send-o:before {
  content: "\f1d8"; }

.la.la-circle-thin {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-circle-thin:before {
  content: "\f111"; }

.la.la-header:before {
  content: "\f1dc"; }

.la.la-sliders:before {
  content: "\f1de"; }

.la.la-futbol-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-futbol-o:before {
  content: "\f1e3"; }

.la.la-soccer-ball-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-soccer-ball-o:before {
  content: "\f1e3"; }

.la.la-slideshare {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-twitch {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-yelp {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-newspaper-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-newspaper-o:before {
  content: "\f1ea"; }

.la.la-paypal {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-wallet {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-visa {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-mastercard {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-discover {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-amex {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-paypal {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-stripe {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bell-slash-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-bell-slash-o:before {
  content: "\f1f6"; }

.la.la-trash:before {
  content: "\f2ed"; }

.la.la-copyright {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-eyedropper:before {
  content: "\f1fb"; }

.la.la-area-chart:before {
  content: "\f1fe"; }

.la.la-pie-chart:before {
  content: "\f200"; }

.la.la-line-chart:before {
  content: "\f201"; }

.la.la-lastfm {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-lastfm-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ioxhost {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-angellist {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-cc:before {
  content: "\f20a"; }

.la.la-ils:before {
  content: "\f20b"; }

.la.la-shekel:before {
  content: "\f20b"; }

.la.la-sheqel:before {
  content: "\f20b"; }

.la.la-meanpath {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-meanpath:before {
  content: "\f2b4"; }

.la.la-buysellads {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-connectdevelop {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-dashcube {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-forumbee {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-leanpub {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-sellsy {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-shirtsinbulk {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-simplybuilt {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-skyatlas {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-diamond {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-diamond:before {
  content: "\f3a5"; }

.la.la-intersex:before {
  content: "\f224"; }

.la.la-facebook-official {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-facebook-official:before {
  content: "\f09a"; }

.la.la-pinterest-p {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-whatsapp {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-hotel:before {
  content: "\f236"; }

.la.la-viacoin {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-medium {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-y-combinator {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-yc {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-yc:before {
  content: "\f23b"; }

.la.la-optin-monster {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-opencart {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-expeditedssl {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-battery-4:before {
  content: "\f240"; }

.la.la-battery:before {
  content: "\f240"; }

.la.la-battery-3:before {
  content: "\f241"; }

.la.la-battery-2:before {
  content: "\f242"; }

.la.la-battery-1:before {
  content: "\f243"; }

.la.la-battery-0:before {
  content: "\f244"; }

.la.la-object-group {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-object-ungroup {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-sticky-note-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-sticky-note-o:before {
  content: "\f249"; }

.la.la-cc-jcb {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-cc-diners-club {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-clone {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hourglass-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hourglass-o:before {
  content: "\f254"; }

.la.la-hourglass-1:before {
  content: "\f251"; }

.la.la-hourglass-2:before {
  content: "\f252"; }

.la.la-hourglass-3:before {
  content: "\f253"; }

.la.la-hand-rock-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-rock-o:before {
  content: "\f255"; }

.la.la-hand-grab-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-grab-o:before {
  content: "\f255"; }

.la.la-hand-paper-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-paper-o:before {
  content: "\f256"; }

.la.la-hand-stop-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-stop-o:before {
  content: "\f256"; }

.la.la-hand-scissors-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-scissors-o:before {
  content: "\f257"; }

.la.la-hand-lizard-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-lizard-o:before {
  content: "\f258"; }

.la.la-hand-spock-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-spock-o:before {
  content: "\f259"; }

.la.la-hand-pointer-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-pointer-o:before {
  content: "\f25a"; }

.la.la-hand-peace-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-hand-peace-o:before {
  content: "\f25b"; }

.la.la-registered {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-creative-commons {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gg {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gg-circle {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-tripadvisor {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-odnoklassniki {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-odnoklassniki-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-get-pocket {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wikipedia-w {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-safari {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-chrome {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-firefox {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-opera {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-internet-explorer {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-television:before {
  content: "\f26c"; }

.la.la-contao {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-500px {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-amazon {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-calendar-plus-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-calendar-plus-o:before {
  content: "\f271"; }

.la.la-calendar-minus-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-calendar-minus-o:before {
  content: "\f272"; }

.la.la-calendar-times-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-calendar-times-o:before {
  content: "\f273"; }

.la.la-calendar-check-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-calendar-check-o:before {
  content: "\f274"; }

.la.la-map-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-map-o:before {
  content: "\f279"; }

.la.la-commenting:before {
  content: "\f4ad"; }

.la.la-commenting-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-commenting-o:before {
  content: "\f4ad"; }

.la.la-houzz {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-vimeo {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-vimeo:before {
  content: "\f27d"; }

.la.la-black-tie {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-fonticons {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-reddit-alien {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-edge {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-credit-card-alt:before {
  content: "\f09d"; }

.la.la-codiepie {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-modx {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-fort-awesome {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-usb {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-product-hunt {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-mixcloud {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-scribd {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-pause-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-pause-circle-o:before {
  content: "\f28b"; }

.la.la-stop-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-stop-circle-o:before {
  content: "\f28d"; }

.la.la-bluetooth {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-bluetooth-b {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-gitlab {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wpbeginner {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wpforms {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-envira {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wheelchair-alt {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wheelchair-alt:before {
  content: "\f368"; }

.la.la-question-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-question-circle-o:before {
  content: "\f059"; }

.la.la-volume-control-phone:before {
  content: "\f2a0"; }

.la.la-asl-interpreting:before {
  content: "\f2a3"; }

.la.la-deafness:before {
  content: "\f2a4"; }

.la.la-hard-of-hearing:before {
  content: "\f2a4"; }

.la.la-glide {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-glide-g {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-signing:before {
  content: "\f2a7"; }

.la.la-viadeo {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-viadeo-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-snapchat {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-snapchat-ghost {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-snapchat-square {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-pied-piper {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-first-order {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-yoast {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-themeisle {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-plus-official {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-plus-official:before {
  content: "\f2b3"; }

.la.la-google-plus-circle {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-google-plus-circle:before {
  content: "\f2b3"; }

.la.la-font-awesome {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-fa {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-fa:before {
  content: "\f2b4"; }

.la.la-handshake-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-handshake-o:before {
  content: "\f2b5"; }

.la.la-envelope-open-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-envelope-open-o:before {
  content: "\f2b6"; }

.la.la-linode {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-address-book-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-address-book-o:before {
  content: "\f2b9"; }

.la.la-vcard:before {
  content: "\f2bb"; }

.la.la-address-card-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-address-card-o:before {
  content: "\f2bb"; }

.la.la-vcard-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-vcard-o:before {
  content: "\f2bb"; }

.la.la-user-circle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-user-circle-o:before {
  content: "\f2bd"; }

.la.la-user-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-user-o:before {
  content: "\f007"; }

.la.la-id-badge {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-drivers-license:before {
  content: "\f2c2"; }

.la.la-id-card-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-id-card-o:before {
  content: "\f2c2"; }

.la.la-drivers-license-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-drivers-license-o:before {
  content: "\f2c2"; }

.la.la-quora {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-free-code-camp {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-telegram {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-thermometer-4:before {
  content: "\f2c7"; }

.la.la-thermometer:before {
  content: "\f2c7"; }

.la.la-thermometer-3:before {
  content: "\f2c8"; }

.la.la-thermometer-2:before {
  content: "\f2c9"; }

.la.la-thermometer-1:before {
  content: "\f2ca"; }

.la.la-thermometer-0:before {
  content: "\f2cb"; }

.la.la-bathtub:before {
  content: "\f2cd"; }

.la.la-s15:before {
  content: "\f2cd"; }

.la.la-window-maximize {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-window-restore {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-times-rectangle:before {
  content: "\f410"; }

.la.la-window-close-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-window-close-o:before {
  content: "\f410"; }

.la.la-times-rectangle-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-times-rectangle-o:before {
  content: "\f410"; }

.la.la-bandcamp {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-grav {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-etsy {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-imdb {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-ravelry {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-eercast {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-eercast:before {
  content: "\f2da"; }

.la.la-snowflake-o {
  font-family: 'Line Awesome Free';
  font-weight: 400; }

.la.la-snowflake-o:before {
  content: "\f2dc"; }

.la.la-superpowers {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-wpexplorer {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

.la.la-spotify {
  font-family: 'Line Awesome Brands';
  font-weight: 400; }

/*!
	Modaal - accessible modals - v0.3.1
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden; }

.modaal-accessible-hide, .modaal-close span, .modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.modaal-overlay, .modaal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0; }

.modaal-wrapper {
  display: block;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all .3s ease-in-out; }

.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden; }

.modaal-wrapper .modaal-close {
  border: none;
  background: 0 0;
  padding: 0;
  -webkit-appearance: none; }

.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1; }

.modaal-wrapper.modaal-start_fade {
  opacity: 0; }

.modaal-wrapper [tabindex="0"] {
  outline: none !important; }

.modaal-wrapper.modaal-fullscreen {
  overflow: hidden; }

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }

.modaal-fullscreen .modaal-outer-wrapper, .modaal-gallery-item img {
  display: block; }

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px; }

.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top; }

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto; }

.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden; }

.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto; }

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: 0 0;
  border-radius: 100%;
  transition: all .2s ease-in-out; }

.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff; }

.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
  background: #b93d0c; }

.modaal-close:after, .modaal-close:before {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background .2s ease-in-out; }

.modaal-close:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.modaal-close:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px; }

.modaal-content-container {
  padding: 30px; }

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0; }

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: 0 0; }

.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background .2s ease-in-out; }

.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f; }

.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline; }

.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f; }

@keyframes instaReveal {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

.modaal-instagram .modaal-container {
  width: auto;
  background: 0 0;
  box-shadow: none !important; }

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: 0 0; }

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important; }

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  -webkit-animation: instaReveal 1s linear forwards;
  animation: instaReveal 1s linear forwards; }

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px; }

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%; }

.modaal-gallery-wrap {
  position: relative;
  color: #fff; }

.modaal-gallery-item {
  display: none; }

.modaal-gallery-item.is_active {
  display: block; }

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff; }

.modaal-gallery-label:focus {
  outline: none; }

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: 0 0;
  border: none;
  border-radius: 100%;
  transition: all .2s ease-in-out; }

.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default; }

.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff; }

.modaal-gallery-control:focus:after, .modaal-gallery-control:focus:before, .modaal-gallery-control:hover:after, .modaal-gallery-control:hover:before {
  background: #afb7bc; }

.modaal-gallery-control:after, .modaal-gallery-control:before {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background .2s ease-in-out; }

.modaal-gallery-control:before {
  margin: -5px 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.modaal-gallery-control:after {
  margin: 5px 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.modaal-gallery-next {
  left: 100%;
  margin-left: 40px; }

.modaal-gallery-prev {
  right: 100%;
  margin-right: 40px; }

.modaal-gallery-prev:after, .modaal-gallery-prev:before {
  left: 22px; }

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.modaal-video-wrap {
  margin: auto 50px;
  position: relative; }

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }

.modaal-video-container embed, .modaal-video-container iframe, .modaal-video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.modaal-iframe .modaal-content, .modaal-iframe-elem {
  width: 100%;
  height: 100%; }

.modaal-iframe-elem {
  display: block; }

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px; } }

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px; }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    background: rgba(0, 0, 0, 0.7); }
  .modaal-gallery-control:after, .modaal-gallery-control:before {
    background: #fff; }
  .modaal-gallery-next {
    left: auto;
    right: 20px; }
  .modaal-gallery-prev {
    left: 20px;
    right: auto; } }

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; } }

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important; } }

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px; }
  .modaal-instagram iframe {
    width: 600px !important; } }

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px; } }

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important; } }

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none; } }

.modaal-loading-spinner {
  background: 0 0;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  -webkit-transform: scale(0.25);
  -ms-transform: scale(0.25);
  transform: scale(0.25); }

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  to {
    opacity: .1;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  to {
    opacity: .1;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute; }

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff; }

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(2) > div {
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation-delay: .12s;
  animation-delay: .12s; }

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(3) > div, .modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation-delay: .25s;
  animation-delay: .25s; }

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation-delay: .37s;
  animation-delay: .37s; }

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(5) > div {
  -webkit-animation-delay: .5s;
  animation-delay: .5s; }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div, .modaal-loading-spinner > div:nth-of-type(8) > div {
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation-delay: .62s;
  animation-delay: .62s; }

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(7) > div, .modaal-loading-spinner > div:nth-of-type(8) > div {
  -webkit-animation-delay: .75s;
  animation-delay: .75s; }

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -webkit-animation-delay: .87s;
  animation-delay: .87s; }

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0); }

.modaal-overlay {
  z-index: 9000; }

.l-globalheader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0 15px 30px;
  z-index: 2000; }
  @media only screen and (max-width: 1024px) {
    .l-globalheader {
      padding: 15px 30px; } }
  @media only screen and (max-width: 768px) {
    .l-globalheader {
      padding: 15px; } }
  .l-globalheader-logo {
    width: 160px;
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
    @media only screen and (max-width: 768px) {
      .l-globalheader-logo {
        width: 100px; } }
  .l-globalheader-main {
    flex: 1;
    display: flex;
    align-items: center; }
    .l-globalheader-main-navi {
      flex: 1;
      padding-left: 20px;
      line-height: 1.4; }
      @media only screen and (max-width: 1024px) {
        .l-globalheader-main-navi {
          display: none; } }
      .l-globalheader-main-navi a:hover {
        text-decoration: none; }
      .l-globalheader-main-navi-primary {
        display: flex;
        font-size: 18px;
        font-weight: 700;
        transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
        .l-globalheader-main-navi-primary li {
          margin-right: 30px; }
          .l-globalheader-main-navi-primary li:last-of-type {
            margin-right: 0; }
          .l-globalheader-main-navi-primary li a {
            position: relative;
            display: block; }
            .l-globalheader-main-navi-primary li a:before {
              position: absolute;
              bottom: -7px;
              left: 0;
              display: block;
              content: "";
              width: 0;
              height: 4px;
              background: #6fb99b;
              border-radius: 5px;
              transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99);
              transform-origin: center; }
            .l-globalheader-main-navi-primary li a:hover:before {
              width: 100%; }
          .l-globalheader-main-navi-primary li.is-active a:before {
            width: 100%; }
      .l-globalheader-main-navi-secondary {
        display: flex;
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 700;
        transition: all 0.3s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
        .l-globalheader-main-navi-secondary li {
          margin-right: 20px; }
          .l-globalheader-main-navi-secondary li:last-of-type {
            margin-right: 0; }
  .l-globalheader-information {
    padding: 10px 15px 10px 30px;
    background: #6fb99b;
    color: #fff;
    text-align: right;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-weight: 700; }
    @media only screen and (max-width: 1024px) {
      .l-globalheader-information {
        display: none; } }
    .l-globalheader-information-businesshours {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px; }
      .l-globalheader-information-businesshours-time {
        margin-right: 10px; }
      .l-globalheader-information-businesshours-closed {
        display: flex;
        border: solid 2px #fff;
        border-radius: 6px;
        line-height: 1; }
        .l-globalheader-information-businesshours-closed span {
          padding: 4px 6px;
          background: #fff;
          color: #6fb99b; }
        .l-globalheader-information-businesshours-closed strong {
          padding: 4px 6px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          color: #fff; }
    .l-globalheader-information-tel {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1; }
      .l-globalheader-information-tel i {
        position: relative;
        top: 2px;
        margin-right: 3px;
        font-size: 25px; }
    .l-globalheader-information-reserve {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      padding: 5px 10px;
      background: #ea5656;
      border-radius: 20px;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
      .l-globalheader-information-reserve:hover {
        text-decoration: none;
        background: #f18282; }
      .l-globalheader-information-reserve img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border-radius: 3px; }
  .l-globalheader.is-sticky {
    padding-top: 5px;
    padding-bottom: 5px; }
    @media only screen and (max-width: 768px) {
      .l-globalheader.is-sticky {
        padding-top: 15px;
        padding-bottom: 15px; } }
    .l-globalheader.is-sticky .l-globalheader-logo {
      width: 120px; }
      @media only screen and (max-width: 768px) {
        .l-globalheader.is-sticky .l-globalheader-logo {
          width: 100px; } }
    .l-globalheader.is-sticky .l-globalheader-main-navi-primary {
      transform: translateY(-20px); }
    .l-globalheader.is-sticky .l-globalheader-main-navi-secondary {
      transform: translateY(-40px);
      opacity: 0; }

.l-globalfooter {
  padding: 0 30px 30px;
  margin-top: 60px; }
  @media only screen and (max-width: 1024px) {
    .l-globalfooter {
      margin-top: 15px; } }
  @media only screen and (max-width: 768px) {
    .l-globalfooter {
      padding: 0 15px 15px; } }
  .l-globalfooter-navi {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px; }
    @media only screen and (max-width: 768px) {
      .l-globalfooter-navi {
        flex-direction: column-reverse; } }
    .l-globalfooter-navi-menu ul {
      display: flex;
      margin-bottom: 10px; }
      @media only screen and (max-width: 768px) {
        .l-globalfooter-navi-menu ul {
          display: block;
          text-align: center; } }
      .l-globalfooter-navi-menu ul li {
        margin-right: 20px; }
        @media only screen and (max-width: 768px) {
          .l-globalfooter-navi-menu ul li {
            display: inline-block;
            margin-right: 0;
            padding: 0 10px; } }
        .l-globalfooter-navi-menu ul li a {
          font-size: 13px;
          font-weight: 700; }
    .l-globalfooter-navi-menu-copyright {
      font-size: 13px; }
      @media only screen and (max-width: 768px) {
        .l-globalfooter-navi-menu-copyright {
          font-size: 13px;
          text-align: center; } }
    @media only screen and (max-width: 768px) {
      .l-globalfooter-navi-social {
        width: 100%;
        margin-bottom: 15px; } }
    .l-globalfooter-navi-social ul {
      display: flex;
      justify-content: flex-end; }
      @media only screen and (max-width: 768px) {
        .l-globalfooter-navi-social ul {
          justify-content: center; } }
      .l-globalfooter-navi-social ul li {
        vertical-align: baseline;
        line-height: 1;
        height: 20px; }
  .l-globalfooter-copyright {
    text-align: center;
    font-size: 13px; }

.wrapper {
  position: relative;
  width: 100%; }

.l-content {
  margin-top: 71px; }
  @media only screen and (max-width: 768px) {
    .l-content {
      margin-top: 50px; } }

.l-section {
  position: relative;
  padding: 120px 60px; }
  @media only screen and (max-width: 768px) {
    .l-section {
      padding: 60px 30px; } }
  .l-section-voice {
    padding: 60px; }
    @media only screen and (max-width: 768px) {
      .l-section-voice {
        padding: 30px; } }
  .l-section-inner {
    max-width: 1400px;
    margin: 0 auto; }
    .l-section-inner-small {
      max-width: 1180px; }
    @media only screen and (min-width: 1025px) {
      .l-section-inner-fullright {
        margin-left: 30px;
        margin-right: -60px; } }
  .l-section-bg-left:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: calc(100% - 30px);
    height: 100%;
    background: #eef7f3;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .l-section-bg-left:before {
        width: calc(100% - 15px); } }
  .l-section-bg-right:before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    content: "";
    width: calc(100% - 30px);
    height: 100%;
    background: #eef7f3;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .l-section-bg-right:before {
        width: calc(100% - 15px); } }
  .l-section-padding-small {
    padding-top: 60px;
    padding-bottom: 60px; }
    @media only screen and (max-width: 768px) {
      .l-section-padding-small {
        padding: 30px; } }

.l-spmenu-button {
  position: relative;
  display: none;
  width: 45px;
  height: 30px;
  background: none;
  border: none;
  transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
  @media only screen and (max-width: 1024px) {
    .l-spmenu-button {
      display: block; } }
  .l-spmenu-button:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: #6fb99b;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
  .l-spmenu-button:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: #6fb99b;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
  .l-spmenu-button-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: #6fb99b;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }

.l-spmenu-button.is-close {
  transform: translateX(20px); }
  .l-spmenu-button.is-close .l-spmenu-button-line {
    opacity: 0; }
  .l-spmenu-button.is-close:before {
    transform: rotate(45deg) translateY(20px);
    transform-origin: center; }
  .l-spmenu-button.is-close:after {
    transform: rotate(-45deg) translateY(-20px);
    transform-origin: center; }

.l-spmenu-overray {
  display: none; }
  @media only screen and (max-width: 1024px) {
    .l-spmenu-overray {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(255, 255, 255, 0.95);
      z-index: 1000;
      transform-origin: center;
      overflow-y: auto; } }

.l-spmenu-body {
  display: none;
  padding: 142px 30px 30px; }
  @media only screen and (max-width: 1024px) {
    .l-spmenu-body {
      display: block; } }
  .l-spmenu-body-information {
    margin-bottom: 15px;
    text-align: right;
    color: #6fb99b;
    font-weight: 700; }
    @media only screen and (max-width: 768px) {
      .l-spmenu-body-information {
        line-height: 1.6; } }
    .l-spmenu-body-information-tel i {
      position: relative;
      top: 3px;
      margin-right: 2px;
      font-size: 23px; }
  .l-spmenu-body-navi-main {
    border-top: solid 1px #6fb99b; }
    .l-spmenu-body-navi-main li {
      border-bottom: solid 1px #6fb99b; }
      .l-spmenu-body-navi-main li a {
        position: relative;
        display: block;
        padding: 15px 30px 15px 15px;
        font-weight: 700; }
        .l-spmenu-body-navi-main li a:before {
          position: absolute;
          top: 50%;
          right: 15px;
          display: block;
          content: "";
          width: 10px;
          height: 10px;
          margin-top: -5px;
          border-top: solid 1px #6fb99b;
          border-right: solid 1px #6fb99b;
          transform: rotate(45deg); }
  .l-spmenu-body-navi-top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px; }
    .l-spmenu-body-navi-top li {
      width: 50%;
      border-bottom: solid 1px #6fb99b; }
      .l-spmenu-body-navi-top li:nth-child(odd) {
        border-right: solid 1px #6fb99b; }
      .l-spmenu-body-navi-top li a {
        position: relative;
        display: block;
        padding: 10px 30px 10px 15px;
        font-size: 13px;
        font-weight: 700; }
        .l-spmenu-body-navi-top li a:before {
          position: absolute;
          top: 50%;
          right: 15px;
          display: block;
          content: "";
          width: 6px;
          height: 6px;
          margin-top: -3px;
          border-top: solid 1px #6fb99b;
          border-right: solid 1px #6fb99b;
          transform: rotate(45deg); }

.l-entry {
  display: flex; }
  @media only screen and (max-width: 768px) {
    .l-entry {
      flex-direction: column; } }
  .l-entry-primary {
    flex: 1;
    margin-right: 60px; }
    @media only screen and (max-width: 768px) {
      .l-entry-primary {
        margin-right: 0;
        margin-bottom: 30px; } }
  .l-entry-secondary {
    width: 280px; }
    @media only screen and (max-width: 768px) {
      .l-entry-secondary {
        width: 100%; } }
    .l-entry-secondary-section {
      margin-bottom: 30px; }
      .l-entry-secondary-section:last-of-type {
        margin-bottom: 0; }
      .l-entry-secondary-section-title {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: solid 2px #515151;
        font-size: 17px;
        font-weight: bold; }
    .l-entry-secondary .fb-page {
      margin: 0 auto; }

@media only screen and (max-width: 1024px) {
  .spmenu-open body {
    overflow: hidden; } }

.spmenu-open .l-spmenu-body {
  padding-top: 100px; }

.p-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0 0 0 30px; }
  @media only screen and (max-width: 1024px) {
    .p-hero {
      height: 720px; } }
  @media only screen and (max-width: 768px) {
    .p-hero {
      padding: 0 0 0 15px; } }
  .p-hero-body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    background: url(/assets/img/hero01.jpg) no-repeat center center;
    background-size: cover;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    text-align: center; }
    @media only screen and (max-width: 1024px) {
      .p-hero-body {
        justify-content: center; } }
    @media only screen and (max-width: 768px) {
      .p-hero-body {
        padding: 0 15px;
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px; } }
    .p-hero-body-text {
      width: 48%;
      max-width: 680px;
      min-width: 600px;
      color: #6fb99b;
      text-align: center; }
      @media only screen and (max-width: 1024px) {
        .p-hero-body-text {
          width: 80%;
          max-width: inherit;
          min-width: inherit; } }
      @media only screen and (max-width: 768px) {
        .p-hero-body-text {
          width: 100%; } }
      .p-hero-body-text-title {
        font-weight: 700;
        font-size: 42px;
        letter-spacing: 0.05em;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8); }
        @media only screen and (max-width: 1024px) {
          .p-hero-body-text-title {
            font-size: 4vw; } }
        @media only screen and (max-width: 768px) {
          .p-hero-body-text-title {
            font-size: 6.55555555vw; } }
      .p-hero-body-text-lead {
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.1em;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8); }
        @media only screen and (max-width: 1024px) {
          .p-hero-body-text-lead {
            font-size: 3.33333333vw; } }
        @media only screen and (max-width: 768px) {
          .p-hero-body-text-lead {
            font-size: 4.55555555vw; } }
      .p-hero-body-text-excerpt {
        padding: 20px 30px;
        background: rgba(111, 185, 155, 0.9);
        color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.12); }
        .p-hero-body-text-excerpt-title {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: dotted 1px #fff;
          font-size: 18px;
          font-weight: 700; }
        .p-hero-body-text-excerpt-body {
          font-size: 13px;
          text-align: left; }
  .p-hero-interview {
    height: 460px; }
    @media only screen and (max-width: 768px) {
      .p-hero-interview {
        height: auto; } }
    .p-hero-interview-body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 60px;
      background: url(/assets/img/hero02.jpg) no-repeat center center;
      background-size: cover;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      text-align: center; }
      @media only screen and (max-width: 768px) {
        .p-hero-interview-body {
          padding: 60px 30px 90px;
          border-top-left-radius: 60px;
          border-bottom-left-radius: 60px; } }
      .p-hero-interview-body-title {
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 0.05em;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8);
        color: #6fb99b; }
        @media only screen and (max-width: 1024px) {
          .p-hero-interview-body-title {
            font-size: 4vw; } }
        @media only screen and (max-width: 768px) {
          .p-hero-interview-body-title {
            font-size: 5.55555555vw;
            text-align: left; } }
        @media only screen and (max-width: 768px) {
          .p-hero-interview-body-title br {
            display: none; } }

.p-hero-small {
  width: 100%;
  height: 400px;
  display: flex;
  padding: 0 0 0 30px; }
  @media only screen and (max-width: 768px) {
    .p-hero-small {
      height: 200px; } }
  .p-hero-small-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    background: url(/assets/img/hero02.jpg) no-repeat center center;
    background-size: cover;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .p-hero-small-body {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px; } }
    .p-hero-small-body-text {
      color: #6fb99b; }
      .p-hero-small-body-text-title {
        font-weight: 700;
        font-size: 42px;
        letter-spacing: 0.05em;
        line-height: 1.4;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8); }
        @media only screen and (max-width: 1024px) {
          .p-hero-small-body-text-title {
            font-size: 4vw; } }
        @media only screen and (max-width: 768px) {
          .p-hero-small-body-text-title {
            font-size: 6.55555555vw; } }
        .p-hero-small-body-text-title-en {
          font-weight: 700;
          font-size: 15px;
          letter-spacing: 0.05em; }
          @media only screen and (max-width: 1024px) {
            .p-hero-small-body-text-title-en {
              font-size: 2vw; } }
          @media only screen and (max-width: 768px) {
            .p-hero-small-body-text-title-en {
              font-size: 3.55555555vw; } }

.p-breadcrumb {
  width: 100%;
  padding: 0 0 0 30px; }
  .p-breadcrumb-body {
    padding: 60px 60px 30px 60px;
    background: #eef7f3;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .p-breadcrumb-body {
        padding: 45px 0 15px 30px;
        background: #eef7f3;
        border-top-left-radius: 45px;
        border-bottom-left-radius: 45px; } }
    .p-breadcrumb-body ul {
      display: flex;
      justify-content: flex-end; }
      @media only screen and (max-width: 768px) {
        .p-breadcrumb-body ul {
          width: 100%;
          justify-content: flex-start;
          overflow-x: scroll;
          white-space: nowrap; } }
      .p-breadcrumb-body ul li {
        margin-right: 30px;
        font-size: 12px; }
        .p-breadcrumb-body ul li:last-of-type {
          margin-right: 0; }
        .p-breadcrumb-body ul li a {
          position: relative; }
          .p-breadcrumb-body ul li a:before {
            position: absolute;
            top: 50%;
            right: -18px;
            display: block;
            content: "";
            width: 5px;
            height: 5px;
            margin-top: -2.5px;
            border-top: solid 1px #515151;
            border-right: solid 1px #515151;
            transform: rotate(45deg); }
        .p-breadcrumb-body ul li span {
          display: inline-block;
          margin-left: 5px; }
          .p-breadcrumb-body ul li span:before {
            display: inline-block;
            margin-right: 5px;
            content: "/"; }
          .p-breadcrumb-body ul li span:first-of-type {
            margin-left: 0; }
            .p-breadcrumb-body ul li span:first-of-type:before {
              display: none; }

.p-profile {
  position: relative;
  padding: 0 60px;
  margin-top: -60px;
  z-index: 10; }
  @media only screen and (max-width: 768px) {
    .p-profile {
      padding: 0 30px; } }
  .p-profile-inner {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
    overflow: hidden; }
    @media only screen and (max-width: 768px) {
      .p-profile-inner {
        flex-direction: column; } }
  .p-profile-image {
    position: relative;
    width: 35%; }
    @media only screen and (max-width: 768px) {
      .p-profile-image {
        width: 100%; } }
    .p-profile-image-name {
      position: absolute;
      bottom: 10px;
      left: 10%;
      width: 80%;
      text-align: center; }
      .p-profile-image-name span {
        display: inline-block;
        padding: 5px 30px 5px;
        background: #fff;
        border-radius: 50px;
        font-size: 13px;
        line-height: 1.4; }
        .p-profile-image-name span strong {
          font-size: 16px; }
        .p-profile-image-name span ruby {
          display: table-cell; }
          .p-profile-image-name span ruby rt {
            margin-top: 0;
            display: block; }
        .p-profile-image-name span em {
          display: block;
          font-size: 10px;
          font-weight: 700;
          font-style: normal;
          line-height: 1.4;
          text-align: right; }
  .p-profile-detail {
    width: 60%;
    padding: 30px;
    font-size: 18px;
    font-weight: 700; }
    @media only screen and (max-width: 768px) {
      .p-profile-detail {
        width: 100%;
        padding: 15px;
        font-size: 15px; } }
    .p-profile-detail ul li {
      position: relative;
      padding-left: 15px; }
      .p-profile-detail ul li:before {
        position: absolute;
        top: 0.8em;
        left: 0;
        display: block;
        content: "";
        width: 5px;
        height: 5px;
        background: #515151;
        border-radius: 50%; }

.p-interview-box {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; }
  .p-interview-box img {
    border-radius: 20px; }
  .p-interview-box-title {
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    color: #6fb99b;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5; }
    @media only screen and (max-width: 768px) {
      .p-interview-box-title {
        font-size: 17px; } }
    .p-interview-box-title:before {
      position: absolute;
      top: 0.9em;
      left: 0;
      display: block;
      content: "";
      width: 20px;
      height: 2px;
      background: #6fb99b; }
  .p-interview-box-image01 {
    max-width: 700px;
    padding: 20px;
    margin: 0 auto;
    background: #fff; }
  @media only screen and (max-width: 768px) {
    .p-interview-box .u-headline-lv1 {
      text-align: left; } }
  @media only screen and (max-width: 768px) {
    .p-interview-box .u-headline-lv1 br {
      display: none; } }
  @media only screen and (max-width: 768px) {
    .p-interview-box .u-image-width200 {
      display: none; } }

.p-about-saloncard {
  padding: 60px 30px 0 30px;
  border-top: solid 5px #f1f1f1; }
  @media only screen and (max-width: 768px) {
    .p-about-saloncard {
      padding: 30px 0 0 0; } }
  .p-about-saloncard-top {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      .p-about-saloncard-top {
        flex-direction: column; } }
    .p-about-saloncard-top-logo {
      width: 150px; }
      @media only screen and (max-width: 768px) {
        .p-about-saloncard-top-logo {
          margin: 0 auto 15px auto; } }
    .p-about-saloncard-top-text {
      flex: 1;
      padding-left: 15px; }
      @media only screen and (max-width: 768px) {
        .p-about-saloncard-top-text {
          padding-left: 0; } }
  .p-about-saloncard-images ul {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      .p-about-saloncard-images ul {
        flex-direction: column; } }
    .p-about-saloncard-images ul li {
      width: 31%; }
      @media only screen and (max-width: 768px) {
        .p-about-saloncard-images ul li {
          width: 100%;
          margin-bottom: 15px; } }
      .p-about-saloncard-images ul li p {
        margin-bottom: 5px;
        color: #6fb99b;
        font-weight: bold;
        text-align: center; }

.p-about-reserve {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .p-about-reserve {
      flex-direction: column; } }
  .p-about-reserve-image {
    width: 52%; }
    @media only screen and (max-width: 768px) {
      .p-about-reserve-image {
        width: 100%;
        margin-bottom: 15px; } }
  .p-about-reserve-detail {
    width: 44%; }
    @media only screen and (max-width: 768px) {
      .p-about-reserve-detail {
        width: 100%; } }

.p-use-reservia {
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 768px) {
    .p-use-reservia {
      flex-direction: column; } }
  .p-use-reservia-item {
    width: 30%;
    padding: 15px;
    margin: 0 15px;
    background: #eef7f3;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
    @media only screen and (max-width: 768px) {
      .p-use-reservia-item {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 15px auto; } }
    .p-use-reservia-item:hover {
      background: #faf8da;
      box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.2);
      text-decoration: none; }
  .p-use-reservia-title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold; }
  .p-use-reservia-image {
    margin-bottom: 15px; }
  .p-use-reservia-button {
    display: block;
    padding: 10px;
    margin: 0 -15px -15px -15px;
    background: #6fb99b;
    color: #fff;
    font-weight: bold; }
    .p-use-reservia-button i {
      position: relative;
      top: 2px;
      margin-right: 5px;
      font-size: 20px; }

.p-modal-reservia-inner {
  width: 100%;
  display: flex;
  overflow: auto; }

.p-modal-reservia-item {
  position: relative;
  min-width: 240px;
  margin-right: 50px;
  font-size: 13px; }
  @media only screen and (max-width: 768px) {
    .p-modal-reservia-item {
      min-width: 160px; } }
  .p-modal-reservia-item:before {
    content: "";
    position: absolute;
    top: 40%;
    left: calc( 100% + 15px);
    margin-top: -20px;
    border: 20px solid transparent;
    border-left: 20px solid #6fb99b; }
  .p-modal-reservia-item:last-of-type {
    margin-right: 0; }
  .p-modal-reservia-item:last-of-type:before {
    display: none; }
  .p-modal-reservia-item-image {
    margin-bottom: 15px; }

.is-ready {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }

.is-active {
  opacity: 1;
  transform: translateY(0); }

.u-headline-lv1 {
  position: relative;
  margin-bottom: 80px;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .u-headline-lv1 {
      font-size: 23px;
      margin-bottom: 60px; } }
  .u-headline-lv1:before {
    position: absolute;
    bottom: -35px;
    left: 50%;
    display: block;
    content: "";
    width: 100px;
    height: 5px;
    margin-left: -50px;
    border-radius: 10px;
    background: #6fb99b; }
    @media only screen and (max-width: 768px) {
      .u-headline-lv1:before {
        width: 60px;
        margin-left: -30px;
        bottom: -30px; } }
  .u-headline-lv1-small {
    font-size: 30px; }
    @media only screen and (max-width: 768px) {
      .u-headline-lv1-small {
        font-size: 20px; } }

.u-headline-voice {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .u-headline-voice {
      flex-direction: column;
      margin-bottom: 30px;
      font-size: 23px; } }
  .u-headline-voice-image {
    width: 150px;
    line-height: 0; }
    @media only screen and (max-width: 768px) {
      .u-headline-voice-image {
        margin-bottom: 10px; } }
    .u-headline-voice-image img {
      position: relative;
      border-radius: 50%; }
    .u-headline-voice-image span {
      position: relative;
      display: inline-block;
      padding: 8px 20px;
      margin-top: -10px;
      font-size: 13px;
      background: #6fb99b;
      color: #fff;
      border-radius: 20px;
      line-height: 1;
      z-index: 1; }
      .u-headline-voice-image span strong {
        font-size: 17px; }
  .u-headline-voice-text {
    padding-left: 20px;
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .u-headline-voice-text {
        padding-left: 0; } }

.u-headline-lv2 {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4; }
  @media only screen and (max-width: 768px) {
    .u-headline-lv2 {
      margin-bottom: 15px;
      font-size: 20px; } }

.u-headline-lv3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4; }

.u-list-icon ul {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-list-icon ul {
      flex-wrap: wrap;
      margin-bottom: -30px; } }
  .u-list-icon ul li {
    position: relative;
    width: 16%; }
    @media only screen and (max-width: 768px) {
      .u-list-icon ul li {
        width: 46%;
        margin-bottom: 30px; } }
    .u-list-icon ul li:nth-child(1) {
      margin-top: -60px; }
      @media only screen and (max-width: 768px) {
        .u-list-icon ul li:nth-child(1) {
          margin-top: 0; } }
    .u-list-icon ul li:nth-child(3) {
      margin-top: 25px; }
      @media only screen and (max-width: 768px) {
        .u-list-icon ul li:nth-child(3) {
          margin-top: 0; } }
    .u-list-icon ul li:nth-child(5) {
      margin-top: -60px; }
      @media only screen and (max-width: 768px) {
        .u-list-icon ul li:nth-child(5) {
          margin-top: 0; } }
    .u-list-icon ul li span {
      position: relative;
      display: block;
      width: 120px;
      padding: 8px 20px;
      margin: -20px auto 0;
      text-align: center;
      background: #f1eb9b;
      border-radius: 50px;
      line-height: 1.4;
      font-weight: 700;
      z-index: 10; }
      .u-list-icon ul li span.large {
        width: 100%;
        font-size: 13px; }

.u-list-icon-image img {
  border-radius: 50%; }

.u-list-icon-text {
  margin-top: 10px;
  font-size: 13px; }

.u-box-greenlight {
  max-width: 1000px;
  padding: 45px;
  margin: 0 auto;
  border: solid 5px #f1eb9b;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  z-index: -1; }
  @media only screen and (max-width: 768px) {
    .u-box-greenlight {
      padding: 30px; } }

.u-box-white {
  position: relative;
  max-width: 1000px;
  padding: 45px;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: -1; }
  @media only screen and (max-width: 768px) {
    .u-box-white {
      padding: 30px; } }

.u-figure-01 {
  position: absolute;
  top: -10vw;
  right: 0;
  display: block;
  width: 50Vw;
  height: 50vw;
  z-index: -10;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .u-figure-01 {
      width: 90Vw;
      height: 90vw; } }
  .u-figure-01:before {
    position: absolute;
    top: 0;
    right: -10vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: #fcfbec;
    border-radius: 50%;
    filter: blur(15px); }

.u-figure-02 {
  position: absolute;
  bottom: -10vw;
  left: 0;
  display: block;
  width: 55Vw;
  height: 55vw;
  z-index: -10;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .u-figure-02 {
      width: 95Vw;
      height: 95vw;
      bottom: -20vw; } }
  .u-figure-02:before {
    position: absolute;
    bottom: 0;
    left: -25vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: #faf8da;
    border-radius: 50%;
    filter: blur(5px); }

.u-figure-03 {
  position: absolute;
  top: -10vw;
  left: 0;
  display: block;
  width: 50Vw;
  height: 50vw;
  z-index: -10;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .u-figure-03 {
      width: 90Vw;
      height: 90vw;
      left: -10vw; } }
  .u-figure-03:before {
    position: absolute;
    top: 0;
    left: -10vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: #fcfbec;
    border-radius: 50%;
    filter: blur(15px); }

.u-figure-04 {
  position: absolute;
  bottom: -15vw;
  right: 0;
  display: block;
  width: 55Vw;
  height: 55vw;
  z-index: -10;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .u-figure-04 {
      width: 95Vw;
      height: 95vw;
      bottom: -20vw; } }
  .u-figure-04:before {
    position: absolute;
    bottom: 0;
    right: -10vw;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: #faf8da;
    border-radius: 50%;
    filter: blur(5px); }

.u-figure-05 {
  position: absolute;
  top: -45vw;
  left: 40%;
  display: block;
  width: 80vw;
  height: 80vw;
  background: #fdeeee;
  border-radius: 50%;
  filter: blur(12px);
  z-index: -10; }
  @media only screen and (max-width: 768px) {
    .u-figure-05 {
      width: 95Vw;
      height: 95vw;
      top: -25vw; } }

.u-box-image-left {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-box-image-left {
      flex-direction: column; } }
  .u-box-image-left-detail {
    width: 30%; }
    @media only screen and (max-width: 768px) {
      .u-box-image-left-detail {
        width: 100%;
        margin-bottom: 15px; } }
    .u-box-image-left-detail-icons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px; }
      .u-box-image-left-detail-icons-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%; }
        .u-box-image-left-detail-icons-item i {
          font-size: 120px;
          color: #6fb99b;
          line-height: 1; }
        .u-box-image-left-detail-icons-item span {
          font-size: 20px;
          font-weight: bold;
          color: #6fb99b; }
          @media only screen and (max-width: 768px) {
            .u-box-image-left-detail-icons-item span {
              font-size: 17px; } }
    .u-box-image-left-detail-note {
      position: relative;
      margin-top: 20px;
      padding: 20px;
      background: #6fb99b;
      color: #fff;
      border-radius: 15px;
      font-weight: 700; }
      .u-box-image-left-detail-note:before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        margin-left: -10px;
        border: 10px solid transparent;
        border-bottom: 10px solid #6fb99b; }
  .u-box-image-left-text {
    width: 67%; }
    @media only screen and (max-width: 768px) {
      .u-box-image-left-text {
        width: 100%; } }

.u-box-image-right {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-box-image-right {
      flex-direction: column; } }
  .u-box-image-right-detail {
    width: 30%; }
    @media only screen and (max-width: 768px) {
      .u-box-image-right-detail {
        width: 100%;
        margin-bottom: 15px; } }
  .u-box-image-right-text {
    width: 67%; }
    @media only screen and (max-width: 768px) {
      .u-box-image-right-text {
        width: 100%; } }
  .u-box-image-right-small .u-box-image-right-text {
    width: 80%; }
    @media only screen and (max-width: 768px) {
      .u-box-image-right-small .u-box-image-right-text {
        width: 100%;
        margin-bottom: 15px; } }
  .u-box-image-right-small .u-box-image-right-detail {
    width: 14%; }
    @media only screen and (max-width: 768px) {
      .u-box-image-right-small .u-box-image-right-detail {
        width: 100%;
        text-align: center; } }
    @media only screen and (max-width: 768px) {
      .u-box-image-right-small .u-box-image-right-detail img {
        max-width: 140px;
        margin: 0 auto; } }

.u-box-col2 {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 768px) {
    .u-box-col2 {
      flex-direction: column; } }
  .u-box-col2-item {
    width: 47%; }
    @media only screen and (max-width: 768px) {
      .u-box-col2-item {
        width: 100%; } }
    @media only screen and (max-width: 768px) {
      .u-box-col2-item:first-of-type {
        margin-bottom: 15px; } }
  .u-box-col2-reverse {
    flex-direction: row-reverse; }
    @media only screen and (max-width: 768px) {
      .u-box-col2-reverse {
        flex-direction: column; } }

.u-image-around {
  margin: 0; }
  .u-image-around img {
    border-radius: 20px; }

.u-image-caption {
  display: block;
  margin-top: 10px;
  text-align: right;
  font-size: 13px; }

.u-align-center {
  text-align: center; }

.u-flow-list ul {
  position: relative; }
  .u-flow-list ul:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: "";
    display: block;
    width: 10px;
    height: 100%;
    margin-left: -5px;
    background: #f1eb9b; }
  .u-flow-list ul li {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    z-index: 10; }
    .u-flow-list ul li:last-of-type {
      margin-bottom: 0; }

.u-flow-list-item {
  display: inline-block;
  padding: 10px 30px;
  background: #6fb99b;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  border-radius: 30px; }
  @media only screen and (max-width: 768px) {
    .u-flow-list-item {
      font-size: 15px;
      border-radius: 50px; } }
  .u-flow-list-item span {
    position: relative;
    top: -2px;
    display: inline-block;
    text-align: center;
    width: 17px;
    height: 17px;
    margin-right: 5px;
    font-size: 13px;
    color: #6fb99b;
    background: #fff;
    border-radius: 50%;
    line-height: 17px; }
  .u-flow-list-item-red {
    background: #ea5656; }
    .u-flow-list-item-red span {
      color: #ea5656; }

.u-box-origin {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-box-origin {
      flex-direction: column;
      align-items: center; } }
  .u-box-origin-image {
    width: 30%;
    justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .u-box-origin-image {
        width: 60%;
        margin-bottom: 15px; } }
    .u-box-origin-image-photo {
      position: relative;
      border-radius: 20px;
      overflow: hidden; }
      .u-box-origin-image-photo-name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background: #6fb99b;
        text-align: center;
        font-weight: 700;
        color: #fff; }
        .u-box-origin-image-photo-name small {
          display: inline-block;
          margin-left: 10px;
          font-weight: 400; }
  .u-box-origin-text {
    width: 66%; }
    @media only screen and (max-width: 768px) {
      .u-box-origin-text {
        width: 100%; } }

.u-button {
  position: relative;
  display: inline-block;
  min-width: 240px;
  padding: 15px 40px;
  background: #ea5656;
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99);
  cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .u-button {
      border-radius: 60px;
      font-size: 13px; } }
  .u-button:hover {
    text-decoration: none;
    background: #f18282; }
  .u-button:before {
    position: absolute;
    top: 50%;
    right: 20px;
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    margin-top: -3.5px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform: rotate(45deg); }
  .u-button-small {
    padding: 10px 20px;
    min-width: 140px; }
  .u-button-disable {
    background: #aaa;
    pointer-events: none; }

.u-list-voice {
  max-width: 1080px;
  margin: 0 auto; }
  .u-list-voice-item {
    display: flex;
    align-items: flex-start;
    width: 83%;
    margin-top: 30px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 768px) {
      .u-list-voice-item {
        width: 100%; } }
    .u-list-voice-item-photo {
      width: 14%;
      line-height: 1;
      text-align: center; }
      @media only screen and (max-width: 768px) {
        .u-list-voice-item-photo {
          width: 25%; } }
      .u-list-voice-item-photo img {
        border-radius: 50%; }
      .u-list-voice-item-photo-name {
        position: relative;
        display: block;
        width: 100%;
        padding: 8px 15px;
        margin: -20px auto 0;
        text-align: center;
        background: #f1eb9b;
        border-radius: 50px;
        font-size: 12px;
        line-height: 1.4;
        font-weight: 700;
        z-index: 10; }
    .u-list-voice-item-text {
      position: relative;
      flex: 1;
      padding: 20px 30px;
      margin-left: 30px;
      background: #6fb99b;
      color: #fff;
      border-radius: 20px;
      font-weight: 700;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.15);
      font-size: 17px; }
      @media only screen and (max-width: 768px) {
        .u-list-voice-item-text {
          padding: 15px;
          font-size: 15px; } }
      .u-list-voice-item-text:before {
        content: "";
        position: absolute;
        top: 30px;
        left: -20px;
        margin-top: -10px;
        border: 10px solid transparent;
        border-right: 10px solid #6fb99b; }
        @media only screen and (max-width: 768px) {
          .u-list-voice-item-text:before {
            top: 35px;
            margin-top: -15px; } }
    .u-list-voice-item-reverse {
      flex-direction: row-reverse;
      margin-left: 17%; }
      @media only screen and (max-width: 768px) {
        .u-list-voice-item-reverse {
          margin-left: 0; } }
      .u-list-voice-item-reverse .u-list-voice-item-text {
        margin-left: 0;
        margin-right: 20px;
        background: #fff;
        color: #515151;
        font-weight: 400; }
        .u-list-voice-item-reverse .u-list-voice-item-text:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -10px;
          border: 10px solid transparent;
          border-left: 10px solid #fff; }
          @media only screen and (max-width: 768px) {
            .u-list-voice-item-reverse .u-list-voice-item-text:before {
              top: 35px; } }

.u-box-point ul {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-box-point ul {
      flex-wrap: wrap;
      justify-content: space-between; } }
  .u-box-point ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23%;
    padding: 20px;
    background: #f1eb9b;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px; }
    @media only screen and (max-width: 768px) {
      .u-box-point ul li {
        width: 48%;
        padding: 15px;
        margin-bottom: 15px;
        font-size: 15px; } }

.u-box-treatment-item {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 60px; }
  @media only screen and (max-width: 768px) {
    .u-box-treatment-item {
      margin-bottom: 30px;
      padding-bottom: 30px; } }
  .u-box-treatment-item:before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    background: #f1f1f1; }
  .u-box-treatment-item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0; }
    .u-box-treatment-item:last-of-type:before {
      display: none; }
  .u-box-treatment-item-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px; }
    @media only screen and (max-width: 768px) {
      .u-box-treatment-item-head {
        flex-direction: column;
        margin-bottom: 15px; } }
    .u-box-treatment-item-head-image {
      position: relative;
      width: 32%;
      line-height: 1;
      border-radius: 20px;
      overflow: hidden; }
      @media only screen and (max-width: 768px) {
        .u-box-treatment-item-head-image {
          width: 100%;
          margin-bottom: 15px; } }
      .u-box-treatment-item-head-image img {
        border-radius: 20px; }
      .u-box-treatment-item-head-image-label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 15px 30px;
        background: #6fb99b;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        border-bottom-right-radius: 10px; }
    .u-box-treatment-item-head-detail {
      width: 64%; }
      @media only screen and (max-width: 768px) {
        .u-box-treatment-item-head-detail {
          width: 100%; } }
  .u-box-treatment-item-answer {
    position: relative;
    max-width: 1000px;
    margin: 0 auto; }
    .u-box-treatment-item-answer-image {
      margin-bottom: -50px;
      text-align: center; }
      .u-box-treatment-item-answer-image img {
        display: inline;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: solid 10px #6fb99b; }
        @media only screen and (max-width: 768px) {
          .u-box-treatment-item-answer-image img {
            width: 100px;
            height: 100px;
            border: solid 7px #6fb99b; } }
    .u-box-treatment-item-answer-detail {
      padding: 80px 40px 40px 40px;
      background: #6fb99b;
      color: #fff;
      border-radius: 20px;
      font-size: 17px;
      font-weight: 700; }
      @media only screen and (max-width: 768px) {
        .u-box-treatment-item-answer-detail {
          padding: 50px 20px 20px 20px;
          font-size: 15px; } }

.u-table {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  line-height: 1.4; }
  @media only screen and (max-width: 768px) {
    .u-table {
      font-size: 13px; } }
  .u-table th {
    padding: 15px 15px;
    background: #f1eb9b;
    border-top: none !important;
    border-left: none !important;
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff; }
    @media only screen and (max-width: 768px) {
      .u-table th {
        padding: 10px; } }
    .u-table th.u-table-th-width140 {
      width: 140px; }
    .u-table th.u-table-th-width160 {
      width: 160px; }
    .u-table th.u-table-th-width-per30 {
      width: 30%; }
    .u-table th.u-table-th-green {
      background: #6fb99b;
      color: #fff; }
  .u-table td {
    padding: 10px 15px;
    border-top: none !important;
    border-left: none !important;
    background: #f1f1f1;
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff; }
    @media only screen and (max-width: 768px) {
      .u-table td {
        padding: 10px; } }
    .u-table td.u-table-td-greenlight {
      background: #f1eb9b;
      font-weight: bold; }
    .u-table td.u-table-td-gray {
      background: #aaa;
      font-weight: bold;
      color: #fff; }
    .u-table td.u-table-td-noborder-bottom {
      border-bottom: none; }
    .u-table td.u-table-td-border-right {
      border-right: 2px solid #fff !important; }
  .u-table tr th:last-of-type {
    border-right: none; }
  .u-table tr td:last-of-type {
    border-right: none; }
  .u-table tr:last-of-type th {
    border-bottom: none; }
  .u-table tr:last-of-type td {
    border-bottom: none; }
  .u-table .u-button-small {
    min-width: 160px; }

.u-flow-head {
  position: sticky;
  top: 96px;
  z-index: 20; }
  .u-flow-head ul {
    position: relative;
    display: flex;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }
    .u-flow-head ul:after {
      position: absolute;
      top: 0;
      left: 50%;
      content: "";
      display: block;
      width: 10px;
      height: 100%;
      margin-left: -5px;
      background: #f1eb9b; }
    .u-flow-head ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 20px;
      font-weight: 700; }
      @media only screen and (max-width: 768px) {
        .u-flow-head ul li {
          padding: 10px; } }
  .u-flow-head-image {
    width: 15%; }
    @media only screen and (max-width: 768px) {
      .u-flow-head-image {
        width: 35%;
        text-align: center; }
        .u-flow-head-image img {
          margin: 0 auto; } }
  .u-flow-head-detail {
    padding-left: 20px; }
    @media only screen and (max-width: 768px) {
      .u-flow-head-detail {
        padding-left: 5px; } }
    .u-flow-head-detail span {
      padding: 7px 20px;
      background: #6fb99b;
      color: #fff;
      font-size: 17px;
      font-weight: 700;
      border-radius: 10px; }
      @media only screen and (max-width: 768px) {
        .u-flow-head-detail span {
          padding: 3px 10px;
          font-size: 12px; } }
    .u-flow-head-detail h3 {
      margin-top: 5px;
      font-size: 25px;
      font-weight: 700; }
      @media only screen and (max-width: 768px) {
        .u-flow-head-detail h3 {
          margin-top: 0;
          font-size: 12px;
          line-height: 1.4; } }
      .u-flow-head-detail h3 br {
        display: none; }
        @media only screen and (max-width: 768px) {
          .u-flow-head-detail h3 br {
            display: inline; } }

.u-flow-body {
  position: relative;
  padding-top: 40px; }
  .u-flow-body:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: "";
    display: block;
    width: 10px;
    height: 100%;
    margin-left: -5px;
    background: #f1eb9b; }
  .u-flow-body-item {
    position: relative;
    max-width: 960px;
    margin: 0 auto 40px;
    z-index: 10; }
    .u-flow-body-item:last-of-type {
      margin-bottom: 0; }
    .u-flow-body-item-split:after {
      position: absolute;
      top: 0;
      left: 50%;
      content: "";
      display: block;
      width: 10px;
      height: 100%;
      margin-left: -5px;
      background: #f1eb9b; }
    .u-flow-body-item-time {
      position: absolute;
      top: 0;
      right: 20px;
      font-size: 60px;
      font-weight: 700;
      z-index: 10;
      color: #f1eb9b;
      line-height: 1; }
      @media only screen and (max-width: 768px) {
        .u-flow-body-item-time {
          top: 5px;
          right: 0;
          font-size: 30px; } }
      .u-flow-body-item-time small {
        font-size: 20px; }
      .u-flow-body-item-time-small {
        top: 18px;
        font-size: 23px; }
        @media only screen and (max-width: 768px) {
          .u-flow-body-item-time-small {
            width: 100%;
            top: 55px;
            font-size: 17px;
            text-align: center; } }
        .u-flow-body-item-time-small br {
          display: none; }
          @media only screen and (max-width: 768px) {
            .u-flow-body-item-time-small br {
              display: inline; } }
    .u-flow-body-item-time-left {
      position: absolute;
      top: 0;
      left: 20px;
      font-size: 60px;
      font-weight: 700;
      z-index: 10;
      color: #f1eb9b;
      line-height: 1; }
      @media only screen and (max-width: 768px) {
        .u-flow-body-item-time-left {
          top: 5px;
          left: 0;
          font-size: 30px; } }
      .u-flow-body-item-time-left small {
        font-size: 20px; }
    .u-flow-body-item-head {
      position: relative;
      margin-bottom: -24px;
      text-align: center;
      z-index: 10; }
      .u-flow-body-item-head span {
        display: inline-block;
        padding: 10px 20px;
        background: #6fb99b;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 30px; }
        @media only screen and (max-width: 768px) {
          .u-flow-body-item-head span {
            padding: 7px 15px;
            font-size: 15px; } }
    .u-flow-body-item-body {
      position: relative;
      padding: 40px;
      background: #fff;
      border-radius: 20px; }
      @media only screen and (max-width: 768px) {
        .u-flow-body-item-body {
          padding: 40px 15px 15px; } }
      @media only screen and (max-width: 768px) {
        .u-flow-body-item-body .u-mb-xsmall {
          font-size: 13px; } }
      @media only screen and (max-width: 768px) {
        .u-flow-body-item-body .u-font-middle {
          font-size: 17px;
          line-height: 1.4; } }
      .u-flow-body-item-body-col2 {
        display: flex;
        justify-content: space-between; }
        .u-flow-body-item-body-col2-item {
          width: 44%; }
    .u-flow-body-item-option {
      text-align: center; }
      .u-flow-body-item-option span {
        display: inline-block;
        width: 100px;
        height: 100px;
        padding-top: 15px;
        border-radius: 50%;
        background: #6fb99b;
        color: #fff;
        line-height: 1.2;
        text-align: center;
        font-size: 12px;
        font-weight: 700; }
        .u-flow-body-item-option span strong {
          display: block;
          font-size: 40px; }

.u-flow-body-item-time-small + .u-flow-body-item-body {
  padding-top: 65px; }

.u-image-col2 {
  display: flex;
  justify-content: space-between; }
  .u-image-col2-item {
    width: 48%; }

.u-list-video ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -40px; }
  .u-list-video ul li {
    width: 31%;
    margin: 0 3.5% 40px 0; }
    .u-list-video ul li:nth-child(3n) {
      margin-right: 0; }
    .u-list-video ul li iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
      border-radius: 15px;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }

.u-list-video-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%; }

.u-voice {
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 768px) {
    .u-voice {
      flex-direction: column; } }
  .u-voice-item {
    position: relative;
    width: 48%;
    margin: 0 4% 30px 0;
    color: #515151; }
    @media only screen and (max-width: 768px) {
      .u-voice-item {
        width: 100%;
        margin-bottom: 30px; } }
    .u-voice-item:before {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      content: "";
      width: 80px;
      height: 80px;
      background: url(/assets/img/voice-detail.png) no-repeat;
      background-size: cover;
      z-index: 10; }
    @media only screen and (max-width: 768px) {
      .u-voice-item:last-of-type {
        margin-bottom: 0; } }
    .u-voice-item:nth-child(2n) {
      margin-right: 0; }
    .u-voice-item:hover {
      text-decoration: none; }
    .u-voice-item-detail {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background: #fff;
      border-radius: 20px;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12);
      z-index: 0; }
      @media only screen and (max-width: 768px) {
        .u-voice-item-detail {
          flex-direction: column; } }
      .u-voice-item-detail:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -15px;
        border: 15px solid transparent;
        border-top: 15px solid #fff; }
      .u-voice-item-detail-image {
        position: relative;
        width: 48%;
        padding: 0;
        border-radius: 10px;
        line-height: 1;
        overflow: hidden;
        border: none; }
        @media only screen and (max-width: 768px) {
          .u-voice-item-detail-image {
            width: 100%;
            margin-bottom: 15px; } }
        .u-voice-item-detail-image span {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          padding: 10px;
          background: rgba(111, 185, 155, 0.85);
          color: #fff;
          text-align: center;
          font-weight: 700;
          transition: all 0.3s cubic-bezier(0.23, 0.76, 0.37, 0.99);
          text-decoration: none;
          cursor: pointer; }
          .u-voice-item-detail-image span i {
            position: relative;
            top: 2px;
            margin-right: 5px;
            font-size: 20px; }
          .u-voice-item-detail-image span:hover {
            background: #6fb99b; }
      .u-voice-item-detail-body {
        width: 48%; }
        @media only screen and (max-width: 768px) {
          .u-voice-item-detail-body {
            width: 100%; } }
        .u-voice-item-detail-body-name {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px #f1f1f1; }
          .u-voice-item-detail-body-name-photo {
            width: 50px;
            height: 50px; }
          .u-voice-item-detail-body-name span {
            flex: 1;
            padding-left: 10px; }
        .u-voice-item-detail-body-pregnancy {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px #f1f1f1; }
        .u-voice-item-detail-body-list-box {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px #f1f1f1; }
          .u-voice-item-detail-body-list-box:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none; }
          .u-voice-item-detail-body-list-box dt {
            width: 80px;
            padding: 3px 5px;
            background: #6fb99b;
            color: #fff;
            border-radius: 5px;
            text-align: center;
            font-size: 13px;
            font-weight: 700; }
          .u-voice-item-detail-body-list-box dd {
            flex: 1;
            padding-left: 10px;
            line-height: 1.4; }
          .u-voice-item-detail-body-list-box-category span:after {
            content: "、"; }
          .u-voice-item-detail-body-list-box-category span:last-of-type:after {
            display: none; }
    .u-voice-item .u-list-voice-item {
      width: 100%; }
      .u-voice-item .u-list-voice-item-photo {
        width: 20%; }
        @media only screen and (max-width: 768px) {
          .u-voice-item .u-list-voice-item-photo {
            width: 25%; } }
  .u-voice-lower .u-voice-item-detail:before {
    display: none; }
  .u-voice-single {
    display: block; }
    .u-voice-single .u-voice-item {
      width: 100%;
      max-width: 900px;
      margin: 0 auto; }
      .u-voice-single .u-voice-item:before {
        display: none; }
      .u-voice-single .u-voice-item-detail {
        flex-direction: column; }
        .u-voice-single .u-voice-item-detail-image {
          width: 100%;
          margin-bottom: 30px;
          padding-bottom: 30px; }
        .u-voice-single .u-voice-item-detail-body {
          width: 100%; }
          .u-voice-single .u-voice-item-detail-body-list {
            display: flex;
            flex-wrap: wrap; }
            @media only screen and (max-width: 768px) {
              .u-voice-single .u-voice-item-detail-body-list {
                flex-direction: column; } }
            .u-voice-single .u-voice-item-detail-body-list-box {
              width: 50%;
              padding-right: 15px; }
              @media only screen and (max-width: 768px) {
                .u-voice-single .u-voice-item-detail-body-list-box {
                  width: 100%;
                  padding-right: 0; } }
              .u-voice-single .u-voice-item-detail-body-list-box:last-of-type {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #f1f1f1; }
    @media only screen and (max-width: 768px) {
      .u-voice-single .u-list-voice-item {
        flex-direction: column; } }
    @media only screen and (max-width: 768px) {
      .u-voice-single .u-list-voice-item-photo {
        width: 50%;
        margin: 0 auto 20px; } }
    @media only screen and (max-width: 768px) {
      .u-voice-single .u-list-voice-item-text {
        margin-left: 0; } }
    @media only screen and (max-width: 768px) {
      .u-voice-single .u-list-voice-item-text:before {
        top: -5px;
        left: 50%;
        margin-left: -10px;
        transform: rotate(90deg); } }
  @media only screen and (min-width: 1025px) {
    .u-voice-scroll {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1025px) and (max-width: 1024px) {
    .u-voice-scroll {
      flex-direction: column; } }
  @media only screen and (min-width: 1025px) {
    .u-voice-scroll .u-voice-item {
      display: inline-block;
      width: 580px;
      vertical-align: top;
      margin: 0 30px 0 0; } }
  @media only screen and (max-width: 1024px) {
    .u-voice-scroll .u-voice-item {
      flex-direction: column; } }
  @media only screen and (min-width: 1025px) {
    .u-voice-scroll .u-voice-item-detail {
      margin-bottom: 0; } }
  @media only screen and (max-width: 1024px) {
    .u-voice-scroll .u-voice-item-detail {
      margin-bottom: 0;
      align-items: flex-start; } }
  .u-voice-scroll .u-voice-item-detail:before {
    display: none; }

.u-box-col3-border {
  display: flex; }
  @media only screen and (max-width: 768px) {
    .u-box-col3-border {
      flex-direction: column; } }
  .u-box-col3-border-item {
    width: 33.33333333%;
    padding: 0 30px;
    border-right: solid 2px #f1f1f1; }
    @media only screen and (max-width: 768px) {
      .u-box-col3-border-item {
        width: 100%;
        padding: 0;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-right: none;
        border-bottom: solid 2px #f1f1f1; } }
    .u-box-col3-border-item:last-of-type {
      border-right: none; }
      @media only screen and (max-width: 768px) {
        .u-box-col3-border-item:last-of-type {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0; } }

.u-list-ranking ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.u-list-ranking-number {
  width: 24px;
  height: 24px;
  background: #6fb99b;
  color: #fff;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  font-size: 13px;
  font-weight: 700; }

.u-list-ranking-text {
  flex: 1;
  padding-left: 5px;
  line-height: 1.4; }

.u-box-price {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-box-price {
      flex-direction: column; } }
  .u-box-price-detail {
    width: 65%; }
    @media only screen and (max-width: 768px) {
      .u-box-price-detail {
        width: 100%;
        margin-bottom: 15px; } }
  .u-box-price-image {
    width: 31%; }
    @media only screen and (max-width: 768px) {
      .u-box-price-image {
        width: 100%; } }

.u-list-plan-item {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: solid 3px #f1f1f1; }
  .u-list-plan-item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }
  @media only screen and (max-width: 768px) {
    .u-list-plan-item-name {
      margin-bottom: 15px;
      text-align: center; } }
  .u-list-plan-item-name span {
    display: inline-block;
    padding: 7px 30px;
    font-size: 20px;
    font-weight: bold;
    background: #6fb99b;
    color: #fff;
    border-radius: 30px; }
    @media only screen and (max-width: 768px) {
      .u-list-plan-item-name span {
        padding: 5px 20px;
        font-size: 17px; } }

.u-list-faq ul li {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: solid 3px #fff; }
  @media only screen and (max-width: 768px) {
    .u-list-faq ul li {
      margin-bottom: 15px;
      padding-bottom: 15px; } }
  .u-list-faq ul li:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }

.u-list-faq-question {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .u-list-faq-question-icon {
    position: relative;
    top: -2px;
    width: 40px;
    font-size: 35px;
    font-weight: 700;
    color: #6fb99b;
    line-height: 1; }
  .u-list-faq-question-text {
    flex: 1;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4; }

.u-list-faq-answer {
  padding-left: 40px; }

.u-list-faq-archive ul li {
  border-bottom: solid 3px #f1f1f1; }

.u-email {
  font-size: 17px; }
  @media only screen and (max-width: 768px) {
    .u-email {
      font-size: 13px; } }
  .u-email i {
    position: relative;
    top: 5px;
    font-size: 30px; }
    @media only screen and (max-width: 768px) {
      .u-email i {
        font-size: 25px; } }

.u-box-caution {
  padding: 20px;
  border-radius: 10px;
  background: #fdeeee; }

.u-list-circle li {
  position: relative;
  padding-left: 15px; }
  .u-list-circle li:before {
    position: absolute;
    top: 0.7em;
    left: 3px;
    display: block;
    content: "";
    width: 4px;
    height: 4px;
    background: #515151;
    border-radius: 50%; }

.u-map {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }

.u-video-map {
  position: relative;
  width: 100%;
  padding-top: 56.25%; }
  .u-video-map iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }

.u-list-image ul {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .u-list-image ul {
      flex-direction: column; } }
  .u-list-image ul li {
    width: 31%; }
    @media only screen and (max-width: 768px) {
      .u-list-image ul li {
        width: 100%;
        margin-bottom: 15px; } }
    @media only screen and (max-width: 768px) {
      .u-list-image ul li:last-of-type {
        margin-bottom: 0; } }
    .u-list-image ul li img {
      border-radius: 20px;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }

.u-list-news ul li {
  border-bottom: solid 2px #fff; }
  .u-list-news ul li a {
    display: flex;
    padding: 20px;
    line-height: 1.6;
    transition: all 0.3s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
    @media only screen and (max-width: 768px) {
      .u-list-news ul li a {
        padding: 15px 0;
        flex-direction: column; } }
    .u-list-news ul li a:hover {
      background: #faf8da;
      text-decoration: none; }

.u-list-news-date {
  width: 150px;
  color: #515151; }

.u-list-news-title {
  flex: 1;
  font-weight: 700; }

.u-list-news-lower ul li {
  border-bottom: solid 2px #f1f1f1; }

.u-box-image {
  display: inline-block;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }

.u-table-scroll {
  width: 100%;
  overflow-x: auto; }

.u-banner-fixed {
  position: sticky;
  bottom: 0;
  z-index: 500; }
  @media only screen and (max-width: 1024px) {
    .u-banner-fixed {
      display: none; } }
  .u-banner-fixed-box {
    display: flex;
    justify-content: center;
    font-size: 13px; }
    .u-banner-fixed-box-left {
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }
      .u-banner-fixed-box-left ul {
        display: flex;
        height: 100%;
        background: rgba(255, 255, 255, 0.9); }
        .u-banner-fixed-box-left ul li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 10px 20px;
          border-right: dotted 2px #fff;
          background: #6fb99b;
          color: #fff;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.4;
          text-align: center; }
          .u-banner-fixed-box-left ul li strong {
            font-size: 17px; }
    .u-banner-fixed-box-right {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      background: rgba(255, 255, 255, 0.9);
      font-weight: bold;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.12); }
      .u-banner-fixed-box-right p {
        line-height: 1.6; }
      .u-banner-fixed-box-right .u-font-small {
        font-size: 11px; }
      .u-banner-fixed-box-right .u-font-large {
        font-size: 15px; }
      .u-banner-fixed-box-right-button {
        margin-left: 10px;
        padding-left: 10px;
        border-left: solid 2px #f1f1f1; }
        .u-banner-fixed-box-right-button a {
          display: inline-flex;
          align-items: center;
          padding: 8px;
          background: #ea5656;
          border-radius: 5px;
          line-height: 1;
          color: #fff;
          transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
          .u-banner-fixed-box-right-button a:hover {
            text-decoration: none;
            background: #f18282; }
          .u-banner-fixed-box-right-button a img {
            width: 30px; }
        .u-banner-fixed-box-right-button-image {
          padding: 5px;
          border-radius: 5px;
          background: #fff; }
        .u-banner-fixed-box-right-button-text {
          padding: 0 0 0 5px; }
  .u-banner-fixed-ranking {
    position: absolute;
    bottom: 10px;
    left: 15px;
    z-index: 500; }
    .u-banner-fixed-ranking a {
      display: block;
      width: 90px;
      height: 60px;
      padding-top: 7px;
      background: #6fb99b;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
      .u-banner-fixed-ranking a:hover {
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.12);
        background: #91cab3;
        text-decoration: none; }
    .u-banner-fixed-ranking-icon {
      display: block;
      margin-bottom: 10px;
      text-align: center;
      font-size: 25px;
      line-height: 0; }
    .u-banner-fixed-ranking-text {
      display: block;
      font-size: 10px;
      line-height: 0; }

.u-pagetop {
  position: absolute;
  bottom: 10px;
  right: 15px;
  z-index: 500; }
  .u-pagetop a {
    display: block;
    width: 60px;
    height: 60px;
    background: #6fb99b;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
    .u-pagetop a:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      content: "";
      width: 15px;
      height: 15px;
      border-top: solid 2px #fff;
      border-right: solid 2px #fff;
      transform: rotate(-45deg) translateX(-3px) translateY(-10px); }
    .u-pagetop a:hover {
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.12);
      background: #91cab3; }

.u-banner-fixed-sp {
  display: none;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 500; }
  @media only screen and (max-width: 1024px) {
    .u-banner-fixed-sp {
      display: block; } }
  .u-banner-fixed-sp-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 170px;
    background: #ea5656;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px; }
    .u-banner-fixed-sp-button-image {
      width: 45px;
      padding: 5px;
      background: #fff;
      border-radius: 5px; }
    .u-banner-fixed-sp-button-text {
      flex: 1;
      padding-left: 5px; }
  .u-banner-fixed-sp .u-pagetop-sp {
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 500; }
    .u-banner-fixed-sp .u-pagetop-sp a {
      display: block;
      width: 50px;
      height: 50px;
      background: #6fb99b;
      color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
      .u-banner-fixed-sp .u-pagetop-sp a:before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        transform: rotate(-45deg) translateX(-2px) translateY(-7px); }
      .u-banner-fixed-sp .u-pagetop-sp a:hover {
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.12);
        background: #91cab3; }

.u-list-entries ul {
  display: flex;
  flex-wrap: wrap; }
  .u-list-entries ul li {
    width: 31%;
    margin: 0 3.5% 30px 0; }
    @media only screen and (max-width: 768px) {
      .u-list-entries ul li {
        width: 100%;
        margin: 0 0 20px 0; } }
    .u-list-entries ul li:nth-child(3n) {
      margin-right: 0; }
    .u-list-entries ul li a:hover {
      text-decoration: none; }
      .u-list-entries ul li a:hover img {
        transform: scale(1.07); }

.u-list-entries-image {
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden; }
  .u-list-entries-image img {
    transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }

.u-list-entries-title {
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.6; }

.u-list-entries-category {
  font-size: 12px;
  text-align: right; }
  .u-list-entries-category span {
    margin-left: 15px; }

.u-list-entries-ranking ul {
  counter-reset: number 0; }
  .u-list-entries-ranking ul li a .u-list-entries-image {
    position: relative; }
    .u-list-entries-ranking ul li a .u-list-entries-image:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      counter-increment: number 1;
      content: counter(number) " ";
      width: 40px;
      height: 40px;
      background: #ea5656;
      color: #fff;
      text-align: center;
      line-height: 40px;
      border-bottom-right-radius: 15px;
      z-index: 10; }

.u-pager ul {
  display: flex;
  align-items: center;
  justify-content: center; }
  .u-pager ul li {
    margin: 0 5px; }
    .u-pager ul li a {
      display: inline-block;
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      line-height: 40px;
      color: #fff;
      background: #6fb99b;
      border-radius: 50%;
      transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
      .u-pager ul li a:hover {
        background: #91cab3;
        text-decoration: none; }
    .u-pager ul li span {
      display: inline-block;
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      line-height: 40px;
      background: #eef7f3;
      border-radius: 50%; }

.u-tab {
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden; }
  .u-tab li {
    flex: 1;
    padding: 5px 10px;
    cursor: pointer;
    background: #91cab3;
    text-align: center;
    color: #fff;
    border-right: solid 1px #eef7f3; }
    .u-tab li:last-of-type {
      border-right: none; }
    .u-tab li.is-active {
      background: #eef7f3;
      color: #515151;
      font-weight: 700; }
  .u-tab-body-item {
    display: none;
    padding: 15px;
    background: #eef7f3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
    .u-tab-body-item.is-show {
      display: block; }

.u-list-ranking-side ul {
  counter-reset: number 0; }
  .u-list-ranking-side ul li {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #91cab3; }
    .u-list-ranking-side ul li:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      display: block;
      font-size: 10px;
      background: #ea5656;
      color: #fff;
      text-align: center;
      line-height: 20px;
      counter-increment: number 1;
      content: counter(number) " "; }
    .u-list-ranking-side ul li:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
    .u-list-ranking-side ul li a {
      display: flex;
      line-height: 1.6; }

.u-list-ranking-side-image {
  width: 80px; }

.u-list-ranking-side-title {
  flex: 1;
  padding-left: 15px;
  font-size: 12px;
  font-weight: bold; }

.u-list-ranking-side-recent ul li:before {
  display: none; }

.u-entry-title {
  position: relative;
  margin-bottom: 60px;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4; }
  @media only screen and (max-width: 768px) {
    .u-entry-title {
      margin-bottom: 40px;
      font-size: 23px; } }
  .u-entry-title:before {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 5px;
    display: block;
    content: "";
    border-radius: 5px;
    background: #eef7f3; }
    @media only screen and (max-width: 768px) {
      .u-entry-title:before {
        bottom: -20px; } }

.u-entry-social {
  margin-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .u-entry-social {
      margin-bottom: 20px; } }
  .u-entry-social ul {
    display: flex;
    justify-content: flex-end;
    align-items: center; }

.u-entry-body {
  margin-bottom: 60px; }
  .u-entry-body h2 {
    padding-left: 20px;
    margin-bottom: 30px;
    font-size: 23px;
    font-weight: 700;
    line-height: 1.4;
    border-left: solid 5px #6fb99b; }
    @media only screen and (max-width: 768px) {
      .u-entry-body h2 {
        padding-left: 15px;
        margin-bottom: 20px;
        font-size: 20px;
        border-left: solid 3px #6fb99b; } }
  .u-entry-body h3 {
    padding: 10px 20px;
    margin-bottom: 30px;
    font-size: 20px;
    background: #eef7f3;
    font-weight: 700;
    line-height: 1.4; }
    @media only screen and (max-width: 768px) {
      .u-entry-body h3 {
        padding: 10px 15px;
        margin-bottom: 20px;
        font-size: 17px; } }
  .u-entry-body h4 {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4; }
    @media only screen and (max-width: 768px) {
      .u-entry-body h4 {
        margin-bottom: 20px;
        font-size: 17px; } }
  .u-entry-body p {
    margin-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .u-entry-body p {
        margin-bottom: 20px; } }
  .u-entry-body ul {
    margin-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .u-entry-body ul {
        margin-bottom: 20px; } }
    .u-entry-body ul li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 15px;
      line-height: 1.6; }
      .u-entry-body ul li:last-of-type {
        margin-bottom: 0; }
      .u-entry-body ul li:before {
        position: absolute;
        top: 11px;
        left: 0;
        display: block;
        content: "";
        width: 7px;
        height: 7px;
        background: #6fb99b;
        border-radius: 50%; }
  .u-entry-body ol {
    margin-bottom: 30px;
    padding-left: 1.2em;
    list-style: decimal;
    list-style-position: outside; }
    @media only screen and (max-width: 768px) {
      .u-entry-body ol {
        margin-bottom: 20px; } }
    .u-entry-body ol li {
      margin-bottom: 10px;
      line-height: 1.6; }
      .u-entry-body ol li:last-of-type {
        margin-bottom: 0; }
  .u-entry-body blockquote {
    padding: 30px;
    margin: 0 0 30px 0;
    background: #eef7f3; }
    @media only screen and (max-width: 768px) {
      .u-entry-body blockquote {
        padding: 20px;
        margin: 0 0 20px 0; } }
  .u-entry-body .wp-block-gallery ul li {
    padding: 0; }
    .u-entry-body .wp-block-gallery ul li:before {
      display: none; }

.u-entry-author {
  display: flex;
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 20px;
  background: #eef7f3; }
  @media only screen and (max-width: 1024px) {
    .u-entry-author {
      display: block;
      padding: 0 15px 15px;
      margin-bottom: 30px; } }
  .u-entry-author-image {
    width: 150px; }
    @media only screen and (max-width: 1024px) {
      .u-entry-author-image {
        width: 100%;
        margin-bottom: -15px;
        text-align: center; } }
    .u-entry-author-image img {
      border-radius: 50%; }
      @media only screen and (max-width: 1024px) {
        .u-entry-author-image img {
          display: inline-block;
          width: 100px;
          border: solid 5px #fff;
          margin-top: -30px; } }
  .u-entry-author-detail {
    flex: 1;
    padding-left: 30px; }
    @media only screen and (max-width: 1024px) {
      .u-entry-author-detail {
        padding-left: 0; } }
    .u-entry-author-detail-head {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .u-entry-author-detail-head-name {
        font-size: 20px; }
        .u-entry-author-detail-head-name small {
          display: inline-block;
          margin-right: 10px;
          font-size: 13px; }
      .u-entry-author-detail-head-sns ul {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0; }
        .u-entry-author-detail-head-sns ul li {
          padding-left: 0;
          margin-left: 5px;
          font-size: 30px; }
          .u-entry-author-detail-head-sns ul li a {
            color: #6fb99b;
            transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
            .u-entry-author-detail-head-sns ul li a:hover {
              color: #91cab3; }
          .u-entry-author-detail-head-sns ul li:before {
            display: none; }
    .u-entry-author-detail-body p {
      margin-bottom: 15px;
      font-size: 13px; }
      .u-entry-author-detail-body p:last-of-type {
        margin-bottom: 0; }

.u-entry-related-title {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4; }

.u-entry-related-list ul {
  display: flex; }
  @media only screen and (max-width: 1024px) {
    .u-entry-related-list ul {
      flex-wrap: wrap; } }
  .u-entry-related-list ul li {
    width: 18%;
    margin: 0 2.5% 0 0; }
    @media only screen and (max-width: 1024px) {
      .u-entry-related-list ul li {
        width: 48%;
        margin: 0 4% 15px 0; } }
    .u-entry-related-list ul li:nth-child(5n) {
      margin-right: 0; }
      @media only screen and (max-width: 1024px) {
        .u-entry-related-list ul li:nth-child(5n) {
          margin-right: 5%; } }
    @media only screen and (max-width: 1024px) {
      .u-entry-related-list ul li:nth-child(2n) {
        margin-right: 0; } }
    .u-entry-related-list ul li a img {
      transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99); }
    .u-entry-related-list ul li a:hover {
      text-decoration: none; }
      .u-entry-related-list ul li a:hover img {
        transform: scale(1.1); }

.u-entry-related-list-image {
  margin-bottom: 5px;
  border-radius: 10px;
  overflow: hidden; }

.u-entry-related-list-title {
  font-size: 13px;
  line-height: 1.5; }

.u-list-categories ul {
  display: flex;
  flex-wrap: wrap; }
  .u-list-categories ul li {
    margin: 0 10px 10px 0; }
    .u-list-categories ul li .u-button {
      min-width: auto;
      padding: 10px 35px 10px 20px;
      text-align: center;
      background: #f1eb9b;
      color: #515151; }
      @media only screen and (max-width: 1024px) {
        .u-list-categories ul li .u-button {
          font-size: 13px; } }
      .u-list-categories ul li .u-button:before {
        border-top: 2px solid #515151;
        border-right: 2px solid #515151; }
      .u-list-categories ul li .u-button:hover {
        background: #ede364; }
    .u-list-categories ul li.is-current .u-button {
      background: #6fb99b;
      color: #fff; }
      .u-list-categories ul li.is-current .u-button:before {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff; }
      .u-list-categories ul li.is-current .u-button:hover {
        background: #91cab3; }

@media only screen and (max-width: 768px) {
  .u-br-pc {
    display: none; } }

.u-mb {
  margin-bottom: 60px; }
  @media only screen and (max-width: 768px) {
    .u-mb {
      margin-bottom: 30px; } }

.u-mb-small {
  margin-bottom: 40px; }
  @media only screen and (max-width: 768px) {
    .u-mb-small {
      margin-bottom: 15px; } }

.u-mb-xsmall {
  margin-bottom: 20px; }
  @media only screen and (max-width: 768px) {
    .u-mb-xsmall {
      margin-bottom: 10px; } }

.u-align-center {
  text-align: center; }

.u-align-right {
  text-align: right; }

.u-font-small {
  font-size: 13px; }
  @media only screen and (max-width: 768px) {
    .u-font-small {
      font-size: 12px; } }

.u-font-middle {
  font-size: 20px; }
  @media only screen and (max-width: 768px) {
    .u-font-middle {
      font-size: 17px; } }

.u-font-large {
  font-size: 25px; }
  @media only screen and (max-width: 768px) {
    .u-font-large {
      font-size: 23px;
      line-height: 1.6; } }

.u-font-bold {
  font-weight: 700; }

.u-font-red {
  color: #ea5656; }

.u-font-green {
  color: #6fb99b; }

.u-font-linethrough {
  text-decoration: line-through; }

.u-image-width900 {
  display: inline;
  max-width: 900px; }

.u-image-width800 {
  display: inline;
  max-width: 800px; }

.u-image-width600 {
  display: inline;
  max-width: 600px; }

.u-image-width400 {
  display: inline;
  max-width: 400px; }

.u-image-width300 {
  display: inline;
  max-width: 300px; }

.u-image-width240 {
  display: inline;
  max-width: 240px; }

.u-image-width200 {
  display: inline;
  max-width: 200px; }

.u-image-width45p {
  width: 45%; }

.u-group:after {
  content: "";
  display: block;
  clear: both; }

.u-float-left {
  float: left;
  margin: 0 45px 45px 0; }
  @media only screen and (max-width: 768px) {
    .u-float-left {
      float: none;
      width: 100%;
      margin: 0 0 20px 0; } }

.u-float-right {
  float: right;
  margin: 0 0 45px 45px; }
  @media only screen and (max-width: 768px) {
    .u-float-right {
      float: none;
      width: 100%;
      margin: 0 0 20px 0; } }

.u-text-border {
  text-decoration: underline; }

.u-image-padding {
  padding: 20px;
  background: #fff; }

.u-modal-inline {
  display: none; }

.u-form {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px;
  background: #eef7f3;
  border-radius: 30px; }
  @media only screen and (max-width: 768px) {
    .u-form {
      padding: 30px; } }
  .u-form .screen-reader-response {
    margin-bottom: 30px;
    color: #ea5656; }
    @media only screen and (max-width: 768px) {
      .u-form .screen-reader-response {
        line-height: 1.5; } }
    .u-form .screen-reader-response p {
      font-weight: bold; }
      @media only screen and (max-width: 768px) {
        .u-form .screen-reader-response p {
          margin-bottom: 5px;
          line-height: 1.5; } }
    .u-form .screen-reader-response ul li {
      position: relative;
      padding-left: 12px; }
      .u-form .screen-reader-response ul li:before {
        position: absolute;
        top: 0.8em;
        left: 0;
        display: block;
        content: "";
        width: 5px;
        height: 5px;
        background: #ea5656;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .u-form .screen-reader-response ul li:before {
            top: 0.5em; } }
  .u-form-item {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: solid 2px #fff; }
    @media only screen and (max-width: 768px) {
      .u-form-item {
        flex-direction: column;
        margin-bottom: 15px;
        padding-bottom: 15px; } }
    .u-form-item-required {
      color: #ea5656; }
    .u-form-item-label {
      width: 200px;
      padding-top: 3px;
      text-align: right;
      font-size: 17px; }
      @media only screen and (max-width: 768px) {
        .u-form-item-label {
          width: 100%;
          padding-top: 0;
          margin-bottom: 10px;
          text-align: left; } }
    .u-form-item-input {
      flex: 1;
      padding-left: 15px; }
      @media only screen and (max-width: 768px) {
        .u-form-item-input {
          padding-left: 0; } }
      .u-form-item-input select {
        font-size: 17px;
        padding: 5px 15px; }
        @media only screen and (max-width: 768px) {
          .u-form-item-input select {
            width: 100%; } }
      .u-form-item-input input[type="text"], .u-form-item-input input[type="email"] {
        width: 100%;
        border: solid 1px #e1e1e1;
        font-size: 17px;
        padding: 7px 10px;
        border-radius: 5px; }
      .u-form-item-input textarea {
        width: 100%;
        height: 200px;
        border: solid 1px #e1e1e1;
        font-size: 17px;
        padding: 7px 10px;
        border-radius: 5px; }
      .u-form-item-input input + span, .u-form-item-input textarea + span {
        display: block;
        margin-top: 5px;
        color: #ea5656; }
        @media only screen and (max-width: 768px) {
          .u-form-item-input input + span, .u-form-item-input textarea + span {
            line-height: 1.5; } }
  .u-form-submit {
    text-align: center; }
    .u-form-submit input[type="submit"] {
      position: relative;
      display: inline-block;
      min-width: 240px;
      padding: 15px 40px;
      background: #6fb99b;
      color: #fff;
      font-weight: 700;
      border-radius: 30px;
      border: none;
      font-size: 17px;
      transition: all 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99);
      cursor: pointer; }
      @media only screen and (max-width: 768px) {
        .u-form-submit input[type="submit"] {
          border-radius: 60px;
          font-size: 13px; } }
      .u-form-submit input[type="submit"]:hover {
        text-decoration: none;
        background: #91cab3; }
      .u-form-submit input[type="submit"]:before {
        position: absolute;
        top: 50%;
        right: 20px;
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        margin-top: -3.5px;
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        transform: rotate(45deg); }
    .u-form-submit + .wpcf7-response-output {
      margin-top: 10px;
      text-align: center;
      color: #ea5656; }
      @media only screen and (max-width: 768px) {
        .u-form-submit + .wpcf7-response-output {
          line-height: 1.5; } }

.u-movie-single {
  max-width: 800px;
  margin: 0 auto; }
  .u-movie-single-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 20px; }
    .u-movie-single-video iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
