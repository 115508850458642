@keyframes hoverTextA {
  0% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

@keyframes hoverTextB {
  0% {
    left: 0;
    width: 0;
  }
  100% {
    left: 0;
    width: 100%;
  }
}

@keyframes openHeader {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
