
.wrapper {
  position: relative;
  width: 100%;
}

.l-content {
  margin-top: 71px;
  @include onlyMobile {
    margin-top: 50px;
  }
}

.l-section {
  position: relative;
  padding: 120px 60px;
  @include onlyMobile {
    padding: 60px 30px;
  }
  &-voice {
    padding: 60px;
    @include onlyMobile {
      padding: 30px;
    }
  }
  &-inner {
    max-width: 1400px;
    margin: 0 auto;
    &-small {
      max-width: 1180px;
    }
    &-fullright {
      @include onlyDesktop {
        margin-left: 30px;
        margin-right: -60px;
      }
    }
  }
  &-bg-left {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: calc(100% - 30px);
      height: 100%;
      background: $greenlight2;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      z-index: -1;
      @include onlyMobile {
        width: calc(100% - 15px);
      }
    }
  }
  &-bg-right {
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      content: "";
      width: calc(100% - 30px);
      height: 100%;
      background: $greenlight2;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
      z-index: -1;
      @include onlyMobile {
        width: calc(100% - 15px);
      }
    }
  }
  &-padding-small {
    padding-top: 60px;
    padding-bottom: 60px;
    @include onlyMobile {
      padding: 30px;
    }
  }
}

.l-spmenu-button {
  position: relative;
  display: none;
  width: 45px;
  height: 30px;
  background: none;
  border: none;
  transition: all 0.5s $ease;
  @include onlyTablet {
    display: block;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: $green;
    border-radius: 10px;
    transition: all 0.5s $ease;
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: $green;
    border-radius: 10px;
    transition: all 0.5s $ease;
  }
  &-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: $green;
    border-radius: 10px;
    transition: all 0.5s $ease;
  }
}

.l-spmenu-button.is-close {
  transform: translateX(20px);
  .l-spmenu-button-line {
    opacity: 0;
  }
  &:before {
    transform: rotate(45deg) translateY(20px);
    transform-origin: center;
  }
  &:after {
    transform: rotate(-45deg) translateY(-20px);
    transform-origin: center;
  }
}

.l-spmenu-overray {
  display: none;
  @include onlyTablet {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,0.95);
    z-index: 1000;
    transform-origin: center;
    overflow-y: auto;
  }
}

.l-spmenu-body {
  display: none;
  padding: 142px 30px 30px;
  @include onlyTablet {
    display: block;
  }
  &-information {
    margin-bottom: 15px;
    text-align: right;
    color: $green;
    font-weight: 700;
    @include onlyMobile {
      line-height: 1.6;
    }
    &-tel {
      i {
        position: relative;
        top: 3px;
        margin-right: 2px;
        font-size: 23px;
      }
    }
  }
  &-navi {
    &-main {
      border-top: solid 1px $green;
      li {
        border-bottom: solid 1px $green;
        a {
          position: relative;
          display: block;
          padding: 15px 30px 15px 15px;
          font-weight: 700;
          &:before {
            position: absolute;
            top: 50%;
            right: 15px;
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            margin-top: -5px;
            border-top: solid 1px $green;
            border-right: solid 1px $green;
            transform: rotate(45deg);
          }
        }
      }
    }
    &-top {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -1px;
      li {
        width: 50%;
        border-bottom: solid 1px $green;
        &:nth-child(odd) {
          border-right: solid 1px $green;
        }
        a {
          position: relative;
          display: block;
          padding: 10px 30px 10px 15px;
          font-size: 13px;
          font-weight: 700;
          &:before {
            position: absolute;
            top: 50%;
            right: 15px;
            display: block;
            content: "";
            width: 6px;
            height: 6px;
            margin-top: -3px;
            border-top: solid 1px $green;
            border-right: solid 1px $green;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.l-entry {
  display: flex;
  @include onlyMobile {
    flex-direction: column;
  }
  &-primary {
    flex: 1;
    margin-right: 60px;
    @include onlyMobile {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  &-secondary {
    width: 280px;
    @include onlyMobile {
      width: 100%;
    }
    &-section {
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &-title {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: solid 2px $black;
        font-size: 17px;
        font-weight: bold;
      }
    }
    .fb-page {
      margin: 0 auto;
    }
  }
}

.spmenu-open {
  body {
    @include onlyTablet {
      overflow: hidden;
    }
  }
  .l-spmenu-body {
    padding-top: 100px;
  }
}